import useChecklist from "@/hooks/useChecklist"
import {
  isSelfServeSalesProduct,
  SelfServeArticle,
  SelfServeChildItem,
  SelfServeProduct,
  SelfServeSalesProduct,
} from "@/types/product/categorizedProduct"
import AnimateHeight from "react-animate-height"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import ListSection from "../list/ListSection"
import ProductCard, { ChecklistProps } from "../list/product-card/ProductCard"

interface SelfServeSectionProps {
  products: SelfServeProduct[]
}

const SelfServeSection: React.FC<SelfServeSectionProps> = (props) => {
  const { t } = useTranslation()
  const {
    checklist,
    toggleProductChecked,
    isAllChildrenChecked,
    unCheckChildren,
  } = useChecklist()

  return (
    <ListSection
      title={t("Interstitial-pickup-self-serve")}
      subTitle={t(
        props.products.length <= 1 ? "common.item" : "common.item_plural",
        {
          count: props.products.length,
        },
      )}
    >
      {props.products.map((product) => (
        <div key={product.info.no} className="mt-2 first:mt-0">
          {isSelfServeSalesProduct(product) ? (
            <div id={"SELF_SERVE_" + product.info.no}>
              <ProductCard
                title={product.info.name}
                image={product.media.mainImage}
                description={product.info.mediumDescription}
                offers={product.offers}
                price={product.price}
                quantity={product.quantity}
                currency={product.price.currency}
                availability={product.stock.cashCarry}
                partiallyAvailableText={getPartiallyAvailableText(product, t)}
                artNo={product.info.no}
                type={"SPR_PARENT"}
                salesMethod="SELF_SERVE"
                checklist={{
                  isChecked: isAllChildrenChecked(
                    product.info.no,
                    product.info.childItems.length,
                  ),
                  onCheck: () => unCheckChildren(product.info.no),
                }}
                product={product}
              />
              <AnimateHeight
                duration={300}
                height={
                  isAllChildrenChecked(
                    product.info.no,
                    product.info.childItems.length,
                  )
                    ? 0
                    : "auto"
                }
                disableDisplayNone
              >
                {product.info.childItems.map((childItem) => (
                  <SelfServeArticleProductCard
                    key={childItem.info.no}
                    article={childItem}
                    isChild={true}
                    checklist={{
                      isChecked:
                        !!checklist[product.info.no + "." + childItem.info.no],
                      onCheck: () =>
                        toggleProductChecked(
                          product.info.no + "." + childItem.info.no,
                          childItem.quantity,
                          childItem.price,
                          "self_serve",
                        ),
                    }}
                  />
                ))}
              </AnimateHeight>
            </div>
          ) : (
            <SelfServeArticleProductCard
              key={product.info.no}
              article={product}
              showImage
              checklist={{
                isChecked: !!checklist[product.info.no],
                onCheck: () =>
                  toggleProductChecked(
                    product.info.no,
                    product.quantity,
                    product.price,
                    "self_serve",
                  ),
              }}
            />
          )}
        </div>
      ))}
    </ListSection>
  )
}

export default SelfServeSection

function SelfServeArticleProductCard(props: {
  article: SelfServeArticle | SelfServeChildItem
  showImage?: boolean
  checklist?: ChecklistProps
  isChild?: boolean
}) {
  const aisle = props.article.locations[0]?.aisle ?? ""
  const bin = props.article.locations[0]?.bin ?? ""

  return (
    <ProductCard
      key={props.article.info.no}
      title={props.article.info.name}
      artNo={props.article.info.no}
      fee={props.article.price?.fees}
      image={props.article.media.mainImage}
      isChildItem={props.isChild}
      hideImage={!props.showImage}
      description={props.article.info.mediumDescription}
      offers={props.article.offers}
      price={props.article.price}
      quantity={props.article.quantity}
      energyLabel={props.article.media.energyLabelImage}
      packages={props.article.info.packaging.packages}
      currency={props.article.price.currency}
      availability={props.article.stock.cashCarry}
      type="SELF_SERVE"
      salesMethod="SELF_SERVE"
      aisle={aisle}
      bin={bin}
      checklist={props.checklist}
      product={props.article}
    />
  )
}

function getPartiallyAvailableText(
  product: SelfServeSalesProduct,
  t: TFunction<"translation", undefined>,
) {
  return product.info.isSplit
    ? t(
        `Interstitial-ProductListCard-collect-self-serve-of-split-spr${
          product.info.hasFullServePart ? "" : "-generic"
        }`,
        {
          count: product.info.totalChildItems,
        },
      )
    : undefined
}
