import { useAuth } from "@/hooks/useAuth"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { useIsOnline } from "./useIsOnline"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { getInterLocalSco } from "@/api/getInterLocalScoInformation"
import { InterPTagReply } from "@/types/responses/ptag"

export const useInterLocalSco = (productNo: string | undefined) => {
  const { oAuthToken } = useAuth()
  const { language, market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode } = session
  const { isLoading } = useIsOnline()

  const {
    data: sco,
    isLoading: isLocalScoLoading,
    isError: isLocalScoError,
  } = useQueryWithErrorHandling<
    InterPTagReply,
    Error,
    InterPTagReply | undefined
  >(
    ["interSco", productNo],
    () =>
      oAuthToken && language && businessUnitCode && productNo && !isLoading
        ? getInterLocalSco(
            market,
            language,
            businessUnitCode,
            productNo,
            oAuthToken,
          )
        : Promise.reject(new Error("Missing list or token")),
    {
      enabled: !!oAuthToken && !!productNo && !!businessUnitCode && !isLoading,
      gcTime: Infinity,
      staleTime: 60000, // 10 minutes
      retry: 3,
    },
  )

  const scoItem = sco?.items.find((item) => item.itemNo === productNo)

  return {
    interLocalScoInfo: scoItem,
    error: isLocalScoError,
    isLoading: isLocalScoLoading,
  }
}
