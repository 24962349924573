import { useCcOrder } from "@/hooks/useCcOrder"
import { useLocale } from "@/hooks/useLocale"
import useVisit from "@/hooks/useVisit"
import { FullServeProduct } from "@/types/product/categorizedProduct"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FullServeButton } from "../full-serve/FullServeButton"
import FullServeErrorMessage from "../full-serve/FullServeErrorMessage"
import FullServeProducts from "../full-serve/FullServeProducts"
import InfoBox from "../full-serve/InfoBox"
import ListSection from "../list/ListSection"
import { sendOrderCreatedEvent } from "@/analytics/events/sendOrderCreatedEvent"
import { useIsMutating } from "@tanstack/react-query"
import { sendOrderButtonConfirmedEvent } from "@/analytics/events/sendOrderButtonConfirmedEvent"
import { FullServeOnboardingModal } from "../modals/FullServeOnboardingModal"
import { Notification } from "../Notification"
import SSRIcon from "@ingka/ssr-icon"
import questionMark from "@ingka/ssr-icon/paths/question-mark-circle"
import { SurveyPrompt } from "../full-serve/SurveyPrompt"
import {
  HIDE_COWORKER_SURVEY,
  SHOW_COWORKER_SURVEY_STORES,
} from "@/config/constants"
import { sendOrderButtonClickedEvent } from "@/analytics/events/sendOrderButtonClickedEvent"
import { sendOpenFullserveOnboardingEvent } from "@/analytics/events/sendFullServeOnboardingEvent"
import { OrderCard } from "../full-serve/OrderCard"
import { FullServeView } from "../full-serve/FullServeView"
import AnimateHeight, { Height } from "react-animate-height"
import { useListig } from "@/hooks/useListig"
import { getListVpcCode } from "@/utils/listig"
import { OrderConfirmationPrompt } from "../full-serve/OrderConfirmationPrompt"
import { useCaptureEmailSms } from "@/hooks/useCaptureEmailSms"
import { useListWithOrders } from "@/hooks/useListWithOrders"
import { useInstoreOrders } from "@/hooks/useInstoreOrders"
import { sendOrderButtonEditClickedEvent } from "@/analytics/events/sendOrderButtonEditClickedEvent"
import { isIsomFullServe } from "@/utils/fullServeRestrictions"
import { useEditOrders } from "@/hooks/useEditOrders"

interface FullServeSectionProps {
  products: FullServeProduct[]
}

export const FullServeSection: React.FC<FullServeSectionProps> = (props) => {
  const { t } = useTranslation()
  const { market } = useLocale()
  const { list, session } = useListig()
  const { setVisitedPage, clearVisitedPage, visited } = useVisit()
  const [cookieConsentVisible, setCookieConsentVisible] = useState(
    !window.Optanon?.IsAlertBoxClosed(),
  )
  const [showOnboardingModal, setShowOnboardingModal] = useState(
    !visited.fullServeOnboarding,
  )
  const [showTempSurveyModal, setShowTempSurveyModal] = useState(false)
  const [orderClicked, setOrderClicked] = useState(false)
  const {
    createCcOrder,
    cancelCcOrder,
    editCcOrder,
    orderNotification,
    orderCreationError,
    isOrderCreationLoading,
    isOrderLoading,
    order: orderFromAttachment,
    orderRestriction,
  } = useCcOrder()
  const { activeUnpaidOrder } = useInstoreOrders()
  const isEditOrdersEnabled = useEditOrders()

  const isAnythingLoading = useIsMutating({
    predicate: (mutation) => mutation.state.status === "pending",
  })

  const productsInList = props.products
  const { items } = useListWithOrders()
  const nbrOfItems = productsInList.length + items.inOrders.length

  const multipleProducts = productsInList.length > 1
  const showPrompt = orderClicked && !orderFromAttachment

  const isCaptureEmailSMS = useCaptureEmailSms()

  const onOrderConfirmationClick = (notification?: {
    contactMethodType: string
    contactMethodData: string
  }) => {
    const isIsom = isIsomFullServe(
      market,
      session.businessUnitCode ?? "",
      session.source?.type === "kiosk" ? session.source.ukid : "",
    )
    if (isIsom && activeUnpaidOrder && list?.items && isEditOrdersEnabled) {
      sendOrderButtonEditClickedEvent(activeUnpaidOrder.orderNo)
      editCcOrder({ order: activeUnpaidOrder, items: list.items })
      return
    }
    sendOrderButtonConfirmedEvent(productsInList)
    createCcOrder(
      {
        onSuccess: (data) => {
          const vpcCode = list && getListVpcCode(list)
          sendOrderCreatedEvent(
            data.orderNo,
            data.orderNoSource,
            props.products,
            !!vpcCode,
          )
          clearVisitedPage("fullServeSurvey")
        },
      },
      notification,
    )
  }

  useEffect(() => {
    const onOptanonChange = () => {
      setCookieConsentVisible(!window.Optanon?.IsAlertBoxClosed())
    }

    window.Optanon?.OnConsentChanged(onOptanonChange)
  }, [])

  const [height, setHeight] = useState<Height>("auto")
  const contentDiv = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const element = contentDiv.current as HTMLDivElement

    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.clientHeight)
    })

    resizeObserver.observe(element)

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <>
      <Notification
        isShowing={orderNotification.isShowing}
        hide={orderNotification.dismiss}
        message={t("FullServe-order-created")}
        undo={undefined}
      />

      <ListSection
        title={t("mobile.order-collect-today")}
        subTitle={
          nbrOfItems
            ? t(nbrOfItems <= 1 ? "common.item" : "common.item_plural", {
                count: nbrOfItems,
              })
            : ""
        }
      >
        <AnimateHeight
          duration={300}
          height={height}
          contentClassName="auto-content"
          contentRef={contentDiv}
          disableDisplayNone
        >
          <div className="space-y-4">
            {orderFromAttachment ? (
              <FullServeView
                products={productsInList}
                order={orderFromAttachment}
              />
            ) : (
              productsInList.length > 0 && (
                <div className="flex flex-col divide-y-2 divide-gray-100 bg-white rounded-md">
                  <div
                    className="flex justify-between py-8 px-6"
                    data-cy="collect-after-payment"
                  >
                    <p className="text-base">
                      {t("mobile.order-collect-after-payment", {
                        count: multipleProducts ? 1 : 0,
                      })}
                    </p>
                    <div>
                      <SSRIcon
                        paths={questionMark}
                        onClick={() => {
                          sendOpenFullserveOnboardingEvent()
                          setShowOnboardingModal(true)
                        }}
                      />
                    </div>
                  </div>

                  <FullServeProducts
                    products={productsInList}
                    isOrderCreated={false}
                  />

                  {(!!orderCreationError || orderRestriction) && (
                    <div className="bg-white">
                      <div className="py-8">
                        <FullServeErrorMessage
                          errorMessage={orderRestriction ?? "API_ERROR"}
                        />
                      </div>
                    </div>
                  )}

                  <FullServeButton
                    disabled={!!orderRestriction}
                    order={orderFromAttachment}
                    text={t("mobile.order-now")}
                    loading={
                      isOrderCreationLoading ||
                      isOrderLoading ||
                      !!isAnythingLoading
                    }
                    onClick={() => {
                      sendOrderButtonClickedEvent()
                      setOrderClicked(true)
                    }}
                  />
                </div>
              )
            )}

            {list?.orders?.map((order) => (
              <OrderCard
                key={order.orderNo}
                listigOrder={order}
                isOrderCreationLoading={isOrderCreationLoading}
                onCancelOrder={(order) => cancelCcOrder(order)}
              />
            ))}
          </div>
        </AnimateHeight>

        {market === "CN" && (
          <div>
            <InfoBox
              header={t("services.need-services-text")}
              text={t("services.book-today-text")}
            />
          </div>
        )}
      </ListSection>

      {!cookieConsentVisible && (
        <FullServeOnboardingModal
          products={productsInList}
          onClose={() => {
            setVisitedPage("fullServeOnboarding")
            setShowOnboardingModal(false)
          }}
          showing={showOnboardingModal}
        />
      )}
      <OrderConfirmationPrompt
        show={showPrompt}
        onContinue={(notification) => {
          onOrderConfirmationClick(notification)
          setOrderClicked(false)
          setShowTempSurveyModal(true)
        }}
        onCancel={() => setOrderClicked(false)}
        isCaptureEmailSMS={isCaptureEmailSMS}
      />
      {showTempSurveyModal &&
        SHOW_COWORKER_SURVEY_STORES.includes(list?.businessUnit.code ?? "") &&
        !HIDE_COWORKER_SURVEY.includes(market) && (
          <SurveyPrompt show onContinue={() => setShowTempSurveyModal(false)} />
        )}
    </>
  )
}
