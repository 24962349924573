import { isFullServeProduct as isFullServeProductUtils } from "@/utils/isFullServe"
import {
  ArtProductInfo,
  ChildArtProductInfo,
  DepartmentSalesLocation,
  FullServeSalesLocation,
  SelfServeSalesLocation,
  SprProductInfo,
  StockInformation,
} from "../responses/find"
import {
  isSalesProduct,
  ListArticle,
  ListChildItem,
  ListSalesProduct,
} from "./listProduct"

export type InStoreChildItem =
  | DepartmentChildItem
  | SelfServeChildItem
  | FullServeChildItem

export type InStoreArticle =
  | DepartmentArticle
  | SelfServeArticle
  | FullServeArticle

export type InStoreSalesProduct = (
  | DepartmentSalesProduct
  | SelfServeSalesProduct
  | FullServeSalesProduct
) & { stock: StockInformation }

export type SplitSprInfo =
  | {
      isSplit: true
      totalChildItems: number
      totalChildItemsQuantity: number
      hasFullServePart: boolean
    }
  | { isSplit: false }

export type DepartmentProduct =
  | DepartmentSalesProduct
  | DepartmentArticle
  | DepartmentChildItem
export interface DepartmentArticle extends ListArticle {
  info: ArtProductInfo
  locations: DepartmentSalesLocation[]
  stock: StockInformation
}
export interface DepartmentChildItem extends ListChildItem {
  info: ChildArtProductInfo
  locations: DepartmentSalesLocation[]
  stock: StockInformation
}

export interface DepartmentSalesProduct extends ListSalesProduct {
  info: DepartmentSprInfo & SplitSprInfo
  stock: StockInformation
}

interface DepartmentSprInfo extends SprProductInfo {
  childItems: DepartmentChildItem[]
}

export function isDepartmentSalesProduct(
  product: DepartmentProduct,
): product is DepartmentSalesProduct {
  return isSalesProduct(product)
}

export function isDepartmentArticle(
  article: ListArticle | ListChildItem,
): article is DepartmentArticle | DepartmentChildItem {
  return (
    !!article.locations?.[0] &&
    (article.locations[0].salesMethod === "MARKET_HALL" ||
      article.locations[0].salesMethod === "SHOWROOM")
  )
}

export type SelfServeProduct =
  | SelfServeSalesProduct
  | SelfServeArticle
  | SelfServeChildItem

export interface SelfServeChildItem extends ListChildItem {
  info: ChildArtProductInfo
  locations: SelfServeSalesLocation[]
  stock: StockInformation
}
export interface SelfServeArticle extends ListArticle {
  info: ArtProductInfo
  locations: SelfServeSalesLocation[]
  stock: StockInformation
}
export interface SelfServeSalesProduct extends ListSalesProduct {
  info: SelfServeSprInfo & SplitSprInfo
  stock: StockInformation
}

interface SelfServeSprInfo extends SprProductInfo {
  childItems: SelfServeChildItem[]
}

export function isSelfServeArticle(
  article: ListArticle | ListChildItem,
): article is SelfServeArticle | SelfServeChildItem {
  return (
    !!article.locations?.[0] &&
    article.locations[0].salesMethod === "SELF_SERVE"
  )
}

export type FullServeProduct =
  | FullServeSalesProduct
  | FullServeArticle
  | FullServeChildItem

export interface FullServeSalesProduct extends ListSalesProduct {
  info: FullServeSprInfo & SplitSprInfo
  stock: StockInformation
}

interface FullServeSprInfo extends SprProductInfo {
  childItems: FullServeChildItem[]
}

export function isSelfServeSalesProduct(
  product: SelfServeProduct,
): product is SelfServeSalesProduct {
  return isSalesProduct(product)
}
export function isFullServeSalesProduct(
  product: FullServeProduct,
): product is FullServeSalesProduct {
  return isSalesProduct(product)
}

export function isFullServeArticle(
  article: ListArticle | ListChildItem,
): article is FullServeArticle | FullServeChildItem {
  return (
    !!article.locations?.[0] &&
    article.locations[0].salesMethod === "FULL_SERVE"
  )
}

export const isFullServeProduct = (
  product: ListArticle | ListSalesProduct,
): product is FullServeProduct => !!isFullServeProductUtils(product)

export interface FullServeChildItem extends ListChildItem {
  info: ChildArtProductInfo
  locations: FullServeSalesLocation[]
  stock: StockInformation
}
export interface FullServeArticle extends ListArticle {
  info: ArtProductInfo
  locations: FullServeSalesLocation[]
  stock: StockInformation
}

export type OnlineProduct = OnlineSalesProduct | OnlineArticle | OnlineChildItem

interface OnlineSprInfo extends SprProductInfo {
  childItems: OnlineChildItem[]
}

export interface OnlineSalesProduct extends ListSalesProduct {
  info: OnlineSprInfo & SplitSprInfo
  stock: StockInformation
}

export interface OnlineChildItem extends ListChildItem {
  info: ChildArtProductInfo
  stock: StockInformation
}

export interface OnlineArticle extends ListArticle {
  info: ArtProductInfo
  stock: StockInformation
}
