import { InterPTagReply } from "@/types/responses/ptag"
import { fetchWithErrorHandling } from "@/utils/fetch"

export async function getInterLocalSco(
  market: string,
  language: string,
  buCode: string,
  ptagNo: string,
  kongToken: string,
) {
  const ptagResponse = await fetchWithErrorHandling<InterPTagReply>(
    "FIND",
    `${process.env.FIND_API_URL}/interptags/${market}/${language}/${buCode}/${ptagNo}`,
    {
      headers: { Authorization: "Bearer " + kongToken },
    },
  ).catch()

  return ptagResponse
}
