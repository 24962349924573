import { PTagDataReply } from "@/types/responses/find"
import { fetchWithErrorHandling } from "@/utils/fetch"

export async function getLocalScoInformation(
  classUnit: { type: "ru" | "sto"; code: string },
  scoNo: string,
  kongToken: string,
): Promise<PTagDataReply> {
  return fetchWithErrorHandling<PTagDataReply>(
    "FIND",
    `${process.env.FIND_API_URL}/ptags/sto/${classUnit.code}/${scoNo}`,
    {
      headers: { Authorization: "Bearer " + kongToken },
    },
  )
}
