import { useTranslation } from "react-i18next"
import useDemoMode from "@/hooks/useDemoMode"
import { IconButtonWithBadge } from "./IconButtonWithBadge"
import documentListIcon from "@ingka/ssr-icon/paths/document-pencil"
import { useListWithOrders } from "@/hooks/useListWithOrders"

interface AppHeaderProps {
  title: string
  onListClick?: () => void
}

const AppHeader: React.FC<AppHeaderProps> = (props) => {
  const { t } = useTranslation()
  const { isDemoMode } = useDemoMode()
  const { items } = useListWithOrders()

  const nbrOfItemsInList = items.all
    .map((item) => item.quantity)
    .reduce((a, b) => a + b, 0)

  return (
    <div className="sticky top-0 bg-white z-10">
      {isDemoMode && (
        <div className="flex w-full flex-col p-6 bg-black text-white text-sm">
          <b>{t("mobile.demo.modal-title")}</b> {t("mobile.demo.modal-text")}
        </div>
      )}
      <div className="flex w-full justify-between p-6 px-7">
        <div className="flex flex-row gap-2 items-center">
          <img alt="IKEA-logo" className="h-7" src="/assets/ikealogo.svg" />
          <h4 className="text-base leading-normal">{props.title}</h4>
        </div>
        <IconButtonWithBadge
          badge={nbrOfItemsInList}
          iconPath={documentListIcon}
          onIconClick={props.onListClick}
        />
      </div>
      <hr className="border-gray-200 m-0" />
    </div>
  )
}

export default AppHeader
