import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal"
import { useProduct } from "@/hooks/useProducts"
import { ProductDescription } from "../pip/ProductDescription"

type ProductDetailsModalProps = {
  productNo: string
  onClose: () => void
  showing: boolean
  isDiyAccordionOpen: boolean
}

export const ProductDetailsModal: React.FC<ProductDetailsModalProps> = ({
  productNo,
  onClose,
  showing,
  isDiyAccordionOpen,
}) => {
  const { productInfo } = useProduct(productNo)

  return productInfo ? (
    <Modal visible={showing} handleCloseBtn={onClose}>
      <Sheets
        size="large"
        header={<ModalHeader closeBtnClick={onClose} />}
        footer={<div></div>}
      >
        <ModalBody>
          <ProductDescription
            product={productInfo}
            isDiyAccordionOpen={isDiyAccordionOpen}
          />
        </ModalBody>
      </Sheets>
    </Modal>
  ) : (
    <></>
  )
}
