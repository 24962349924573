import ProductIdentifier from "@ingka/product-identifier"
import { useTranslation } from "react-i18next"

interface SelfServeLocationProps {
  aisle: string
  bin: string
}

export const SelfServeLocation: React.FC<SelfServeLocationProps> = (props) => {
  const { t } = useTranslation()
  return (
    <div className={`flex flex-row gap-2 transition-all duration-150`}>
      <>
        <ProductIdentifier
          data-cy="product-aisle"
          value={props.aisle}
          label={t("SelfServeLocation-Aisle")}
        />
        <ProductIdentifier
          data-cy="product-bin"
          value={props.bin}
          label={t("SelfServeLocation-Shelf")}
        />
      </>
    </div>
  )
}

interface DepartmentLocationProps {
  department: string
  label: string
}
export const DepartmentLocation: React.FC<DepartmentLocationProps> = (
  props,
) => {
  return (
    <div className={`flex flex-row gap-2 transition-all duration-150`}>
      <ProductIdentifier value={props.department} label={props.label} />
    </div>
  )
}
