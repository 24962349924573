import { ProductPrice } from "@/components/common/ProductPrice"
import { MAX_QUANTITY, MAX_QUANTITY_FULLSERVE } from "@/config/constants"
import { capitalize } from "@/config/locales"
import { useListig } from "@/hooks/useListig"
import { useLocale } from "@/hooks/useLocale"
import {
  BaseImage,
  Fee,
  CashCarryInformation,
  Offers,
  PackageMeasurements as PackageMeasurementsType,
  Prices,
  SalesMethod,
  UProduct,
} from "@/types/responses/find"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import { getFeeText } from "@/utils/getFeeText"
import InlineMessage from "@ingka/inline-message"
import SSRIcon from "@ingka/ssr-icon"
import strikethroughImg from "@ingka/ssr-icon/paths/image-strikethrough"
import trashCan from "@ingka/ssr-icon/paths/trash-can"
import classNames from "classnames"
import { useMemo } from "react"
import AnimateHeight from "react-animate-height"
import { useTranslation } from "react-i18next"
import CheckboxCollect from "../CheckBoxCollect"
import ProductIdentifier from "@ingka/product-identifier"
import { DepartmentLocation, SelfServeLocation } from "./SalesLocation"
import StockInformation from "./StockInformation"
import { ProductAvailableForDelivery } from "./ProductAvailableForDelivery"
import QuantityStepper from "@ingka/quantity-stepper"
import { useNotification } from "@/contexts/NotificationContext"
import { ListigList } from "@/types/listig"
import { useCanModifyList } from "@/hooks/useCanModifyList"
import { sendItemDeletedUndoEvent } from "@/analytics/events/sendItemDeletedUndoEvent"
import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { useSelectQuantity } from "@/hooks/useSelectQuantity"
import { sendOpenPipEvent } from "@/analytics/events/sendOpenPipEvent"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import { ProductLocation } from "@/components/pip/ProductLocation"
import Button from "@ingka/button"

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const { market, language } = useLocale()
  const { t } = useTranslation()
  const notificationContext = useNotification()
  const canModifyList = useCanModifyList()
  const { navigate } = useLocalNavigation()
  const productsQuery = useListig()
  const originalProducts = productsQuery.list?.items
  const waterLabel = props.product.media.images.find(
    (i) =>
      i.type === "WATER_SENSE_LABEL_IMAGE" ||
      i.type === "UNIFIED_WATER_LABEL_SYMBOL_IMAGE",
  )

  const { selectedQuantity, setSelectedQuantity } = useSelectQuantity(
    props.artNo,
    props.quantity,
    "list",
  )

  const {
    deleteProduct: { delete: deleteProduct },
    addProducts: { add: addProduct },
  } = useListig()

  function handleUndoRemove() {
    const undoProduct: ListigList["items"] = [
      {
        id: props.artNo,
        type: props.type === "SPR_PARENT" ? "SPR" : "ART",
        quantity: props.quantity,
      },
    ]
    addProduct(undoProduct)
    notificationContext?.hide()

    sendItemDeletedUndoEvent(props.artNo)
  }

  function handleRemove(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation()
    deleteProduct(
      { productId: props.artNo },
      {
        onSuccess: () => {
          const notificationMessage = t("ShoppingList-product-removed", {
            product: props.title.toUpperCase(),
          })

          notificationContext?.show(notificationMessage, () => handleUndoRemove)
        },
      },
    )
  }

  function handleProductClick() {
    if (!props.isChildItem) {
      sendOpenPipEvent(props.artNo, "list")
      navigate({ path: "product", productNo: props.artNo })
    }
  }

  const salesLocation = useMemo(() => {
    switch (props.type) {
      case "SHOWROOM":
      case "MARKET_HALL":
        return (
          <div className="mt-0">
            <DepartmentLocation
              department={props.department}
              label={t("common.location")}
            />
          </div>
        )
      case "SELF_SERVE":
        return (
          <div className="mt-0">
            {props.aisle && props.bin ? (
              <SelfServeLocation aisle={props.aisle} bin={props.bin} />
            ) : (
              <DepartmentLocation
                department={t("location.self-serve-area")}
                label={t("common.location")}
              />
            )}
          </div>
        )
      default:
        return <div></div>
    }
  }, [props, t])

  return (
    <div
      onClick={handleProductClick}
      className={classNames("flex flex-col bg-white px-6", {
        "pt-6": !props.isChildItem || props.type === "ONLINE",
        "pb-6": props.isChildItem && props.type === "ONLINE",
        // Rounded corners on cards that don't have children and are not Full Serve or Online
        "rounded-md":
          !props.isChildItem &&
          props.type !== "ONLINE" &&
          props.type !== "FULL_SERVE" &&
          props.type !== "SPR_PARENT",
        // Rounded corners on the parent that is not Full Serve or Online
        "rounded-t-md":
          props.type === "SPR_PARENT" &&
          props.salesMethod !== "FULL_SERVE" &&
          props.salesMethod !== "ONLINE",
        // Rounded corners on the last child but not in Online section
        "last:rounded-b-md": props.isChildItem && props.type !== "ONLINE",
        "rounded-b-md":
          props.checklist?.isChecked && props.type === "SPR_PARENT",
      })}
    >
      <div className="flex flex-col mb-4" data-cy="product-card">
        <div className="flex flex-row gap-5">
          {!props.hideImage && (
            <ProductCardImage image={props.image} size={props.imageSize} />
          )}
          <div className="flex flex-col flex-1">
            {!props.hidePrice && props.price && (
              <ProductPrice
                productName={
                  !props.isChildItem
                    ? props.title
                    : `${selectedQuantity ? selectedQuantity + " x " : ""}${props.title}`
                }
                size="small"
                childItem={props.isChildItem}
                productDescription={capitalize(props.description)}
                className={classNames("font-bold mr-0", {
                  "text-xs text-gray-400": props.isChildItem,
                })}
                prices={props.price}
                offers={props.offers}
                quantity={selectedQuantity}
                list
              />
            )}

            {props.type === "ONLINE" ? (
              <div className={props.energyLabel ? "pt-0" : "pt-3"}>
                {waterLabel && (
                  <img
                    data-cy="water-label"
                    className="water-label my-2 h-6"
                    alt={waterLabel.alt}
                    src={waterLabel.href}
                  />
                )}
                {props.energyLabel && (
                  <img
                    className="energy-label my-2 h-6"
                    alt={props.energyLabel.alt}
                    src={props.energyLabel.href}
                    data-cy="energy-label"
                  />
                )}
                <ProductAvailableForDelivery
                  isAvailableForDelivery={props.isAvailableForDelivery}
                />
              </div>
            ) : (
              "availability" in props &&
              !props.isChildItem && (
                <div className="text-s">
                  {waterLabel && (
                    <img
                      data-cy="water-label"
                      className="water-label my-2 h-6"
                      alt={waterLabel.alt}
                      src={waterLabel.href}
                    />
                  )}
                  {props.energyLabel && (
                    <img
                      className="energy-label my-2 h-6"
                      alt={props.energyLabel.alt}
                      src={props.energyLabel.href}
                      data-cy="energy-label"
                    />
                  )}
                  <StockInformation
                    product={
                      originalProducts?.find(
                        (prod) => prod.info.no === props.product.info.no,
                      ) ?? props.product
                    }
                    quantity={props.availability.quantity ?? 0}
                    probability={
                      props.availability.probability?.thisDay ?? "UNKNOWN"
                    }
                  />
                </div>
              )
            )}
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row w-full h-full">
            <div className="flex-1">
              <div className="flex flex-row justify-between">
                <div className="mb-0 whitespace-pre-wrap text-sm leading-relaxed text-gray-700 antialiased">
                  {props.fee && (
                    <div className="text-xs underline">
                      {props.fee[0] &&
                        market &&
                        language &&
                        getFeeText(
                          props.fee[0],
                          `${market.toLowerCase()}-${language.toUpperCase()}`,
                          true,
                        )}
                      <br />
                    </div>
                  )}
                </div>
              </div>
              <AnimateHeight
                duration={300}
                height={props.checklist?.isChecked ? 0 : "auto"}
                disableDisplayNone
              >
                <div className={classNames(`flex overflow-hidden flex-col`)}>
                  {canModifyList && !props.isOrdered && !props.isChildItem && (
                    <div className="ml-2 mb-3 mt-4">
                      <div className="flex ml-24 gap-5">
                        <QuantityStepper
                          onClick={(e) => e.stopPropagation()}
                          readOnly={true}
                          data-cy="quantity-stepper"
                          small
                          value={selectedQuantity}
                          ariaDescribedById={"quantity-stepper-" + props.artNo}
                          ariaDescribedBy={t("aria.quantity-stepper")}
                          maxVal={
                            props.type === "FULL_SERVE"
                              ? MAX_QUANTITY_FULLSERVE
                              : MAX_QUANTITY
                          }
                          minVal={1}
                          onIncrease={(e: React.MouseEvent<HTMLDivElement>) => {
                            e.stopPropagation()
                            setSelectedQuantity(selectedQuantity + 1)
                          }}
                          onDecrease={(e: React.MouseEvent<HTMLDivElement>) => {
                            e.stopPropagation()
                            setSelectedQuantity(selectedQuantity - 1)
                          }}
                        />
                        <div onClick={handleRemove}>
                          <Button
                            className="px-1 py-1"
                            iconOnly
                            xsmallIconOnly
                            type={"tertiary"}
                            ssrIcon={trashCan}
                            data-cy="product-delete"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {props.type === "SPR_PARENT" &&
                  !!props.partiallyAvailableText && (
                    <>
                      <InlineMessage
                        ssrIcon={informationCircle}
                        body={props.partiallyAvailableText}
                      />
                    </>
                  )}
                {!props.isChildItem &&
                  props.salesMethod !== "FULL_SERVE" &&
                  props.type !== "ONLINE" && (
                    <hr className="divider demo-divider__hr-horizontal mt-3 mb-0" />
                  )}
              </AnimateHeight>
              <div>
                {!props.isChildItem ? (
                  props.type !== "SPR_PARENT" &&
                  props.type !== "FULL_SERVE" &&
                  props.type !== "ONLINE" && (
                    <>
                      <div className="flex flex-row gap-4 my-3 w-full">
                        {!props.checklist?.isChecked && (
                          <>
                            <ProductIdentifier
                              label={t("common.article-number")}
                              data-cy="product-nr"
                              className="mt-0"
                              value={dottedIdentifier(props.artNo)}
                            />
                            {salesLocation}
                          </>
                        )}
                        <div
                          className="flex flex-col justify-end ml-auto"
                          onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                            e.stopPropagation()
                          }
                        >
                          {props.checklist && (
                            <CheckboxCollect
                              isChecked={props.checklist.isChecked}
                              onClick={props.checklist.onCheck}
                            />
                          )}
                        </div>
                      </div>
                      {props.packages && !props.checklist?.isChecked && (
                        <ProductLocation product={props.product} listView />
                      )}
                    </>
                  )
                ) : (
                  <>
                    <div className="flex flex-row gap-4 mb-2">
                      {props.type !== "SPR_PARENT" &&
                        props.type !== "ONLINE" && (
                          <ProductIdentifier
                            label={t("common.article-number")}
                            data-cy="product-nr"
                            className="mt-0"
                            value={dottedIdentifier(props.artNo)}
                          />
                        )}
                      {salesLocation}
                      <div
                        className="flex flex-col justify-end ml-auto"
                        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                          e.stopPropagation()
                        }
                      >
                        {props.checklist &&
                          (props.type !== "SPR_PARENT" ||
                            props.checklist?.isChecked) && (
                            <CheckboxCollect
                              isChecked={props.checklist.isChecked}
                              onClick={props.checklist.onCheck}
                            />
                          )}
                      </div>
                    </div>
                    <ProductLocation product={props.product} listView />
                  </>
                )}
                {!props.isChildItem &&
                  props.checklist?.isChecked &&
                  props.type === "SPR_PARENT" && (
                    <div
                      className="flex flex-row justify-end ml-auto"
                      onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                        e.stopPropagation()
                      }
                    >
                      {props.checklist &&
                        (props.type !== "SPR_PARENT" ||
                          props.checklist?.isChecked) && (
                          <CheckboxCollect
                            isChecked={props.checklist.isChecked}
                            onClick={props.checklist.onCheck}
                          />
                        )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(props.type === "FULL_SERVE" ||
        props.type === "ONLINE" ||
        props.type === "SPR_PARENT") &&
        !!props.partiallyAvailableWarning && (
          <InlineMessage
            className={props.isChildItem ? "mb-0" : "mb-8"}
            variant="informative"
            ssrIcon={informationCircle}
            body={props.partiallyAvailableWarning}
          />
        )}
    </div>
  )
}
export default ProductCard

function ProductCardImage(props: {
  image?: BaseImage
  size?: "normal" | "small"
}) {
  return (
    <div className="product-list-card-image flex-shrink-0">
      {props.image?.href ? (
        <img
          aria-hidden="true"
          style={
            props.size === "small"
              ? { height: "3rem", width: "3rem" }
              : { height: "5rem", width: "5rem" }
          }
          src={props.image?.href}
          alt={props.image?.alt}
        />
      ) : (
        <SSRIcon
          style={
            props.size === "small"
              ? { height: "3rem", width: "3rem" }
              : { height: "5rem", width: "5rem" }
          }
          className="text-gray-700 p-6"
          fill="black"
          paths={strikethroughImg}
        />
      )}
    </div>
  )
}

export type ProductCardProps =
  | DepartmentProductCardProps
  | SelfServeProductCardProps
  | FullServeProductCardProps
  | OnlineProductCardProps
  | SprParentProductCardProps

interface SprParentProductCardProps extends BaseProductCardProps {
  type: "SPR_PARENT"
  availability: CashCarryInformation
  partiallyAvailableText?: string
  partiallyAvailableWarning?: string
}

interface DepartmentProductCardProps extends BaseProductCardProps {
  packages: PackageMeasurementsType[]
  availability: CashCarryInformation
  type: "MARKET_HALL" | "SHOWROOM"
  department: string
}
interface SelfServeProductCardProps extends BaseProductCardProps {
  packages: PackageMeasurementsType[]
  availability: CashCarryInformation
  type: "SELF_SERVE"
  aisle: string
  bin: string
}

interface FullServeProductCardProps extends BaseProductCardProps {
  availability: CashCarryInformation
  partiallyAvailableWarning?: string
  type: "FULL_SERVE"
}

interface OnlineProductCardProps extends BaseProductCardProps {
  isAvailableForDelivery: boolean
  partiallyAvailableWarning?: string
  type: "ONLINE"
}

interface BaseProductCardProps {
  title: string
  artNo: string
  image?: BaseImage
  offers: Offers
  price: Prices
  quantity: number
  packages?: PackageMeasurementsType[]
  energyLabel?: BaseImage
  fee?: Fee[]
  currency: string
  description: string
  type: SalesMethod | "SPR_PARENT"
  salesMethod?: SalesMethod
  imageSize?: "normal" | "small"
  checklist?: ChecklistProps
  hideImage?: boolean
  hidePrice?: boolean
  isOrdered?: boolean
  isChildItem?: boolean
  hasMultipleLocations?: boolean
  product: UProduct
}

export type ChecklistProps = {
  isChecked: boolean
  onCheck: () => void
}
