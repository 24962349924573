import { useAuth } from "@/hooks/useAuth"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { PTagData, PTagDataReply } from "@/types/responses/find"
import { useIsOnline } from "./useIsOnline"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { getLocalScoInformation } from "@/api/getLocalScoInformation"
import { GroupedProductDetails, useGroupedProduct } from "./useGroupedProduct"
import { dottedIdentifier } from "@/utils/dottedIdentifier"

export const useLocalSco = (productNo: string | undefined) => {
  const { oAuthToken } = useAuth()
  const { language, market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode } = session
  const { isOnline, isLoading } = useIsOnline()

  const {
    data: sco,
    isLoading: isLocalScoLoading,
    isError: isLocalScoError,
  } = useQueryWithErrorHandling<PTagDataReply, Error, PTagData | undefined>(
    ["sco", productNo],
    () =>
      oAuthToken && language && businessUnitCode && productNo && !isLoading
        ? getLocalScoInformation(
            isOnline
              ? { code: market, type: "ru" }
              : { code: businessUnitCode, type: "sto" },
            productNo,
            oAuthToken,
          )
        : Promise.reject(new Error("Missing list or token")),
    {
      enabled: !!oAuthToken && !!productNo && !!businessUnitCode && !isLoading,
      gcTime: Infinity,
      staleTime: 60000, // 10 minutes
      retry: 3,
      select: (data) => (data.found === "yes" ? data.data : undefined),
    },
  )

  const items = sco?.retailItemGroupChildList.map((e) => ({
    no: e.itemNo,
    type: e.itemType as "ART" | "SPR",
    quantity: e.quantity,
  }))

  const details: GroupedProductDetails | undefined = sco
    ? {
        no: sco.groupId,
        name: dottedIdentifier(sco.groupId),
        type: "SCO",
        description: sco.retailItemGroupNameList[0]?.groupName || "",
      }
    : undefined

  const scoProduct = useGroupedProduct(
    businessUnitCode,
    items,
    details,
    !isLocalScoLoading && !isLocalScoError && !!details,
  )

  return {
    localScoInfo: scoProduct,
    error: isLocalScoError,
    isLoading: isLocalScoLoading,
  }
}
