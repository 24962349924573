import {
  PREPARING_ORDER_STATUSES,
  READY_FOR_PICKUP_STATUSES,
} from "@/config/constants"
import { CcOrderPickingStatus } from "@/types/responses/buy"

export const getFullServeStatus = (
  isPaid: boolean,
  pickingStatus?: CcOrderPickingStatus,
) => {
  const isWaitingForPayment = !isPaid

  const isPreparingOrder =
    !!pickingStatus && PREPARING_ORDER_STATUSES.includes(pickingStatus)

  const isReadyForPickup =
    !!pickingStatus &&
    !!READY_FOR_PICKUP_STATUSES.includes(pickingStatus) &&
    pickingStatus !== "COMPLETED"

  switch (true) {
    case isWaitingForPayment:
      return "WAITING_FOR_PAYMENT"
    case isPreparingOrder:
      return "PREPARING_ORDER"
    case isReadyForPickup:
      return "READY_FOR_PICKUP"
    default:
      return
  }
}
