import { getProductInformation } from "@/api/getProductInformation"
import { getOrder } from "@/api/retrieveFullServeOrder"
import { calculatePriceTotals } from "@/utils/calculatePriceTotals"
import { createProductList } from "@/utils/createProductList"
import { useQueries } from "@tanstack/react-query"
import { useAuth } from "./useAuth"
import { useCo360 } from "./useCo360"
import { useListig } from "./useListig"
import { useLocale } from "./useLocale"
import { isDefined } from "@/utils/isDefined"

/**
 * @returns all the items in a list, including the items in all orders
 * associated with the list, together with the total price for all items
 */
export function useListWithOrders() {
  const { list } = useListig()
  const itemsInOrders = useListigOrdersItems()
  const allItems = [...(list?.items ?? []), ...itemsInOrders]
  const totalPrice = allItems.length && calculatePriceTotals(allItems)

  return { items: { all: allItems, inOrders: itemsInOrders }, totalPrice }
}

function useListigOrdersItems() {
  const { list } = useListig()
  const { oAuthToken } = useAuth()
  const { market, language } = useLocale()
  const isCo360Enabled = useCo360()

  const result = useQueries({
    queries:
      list?.orders?.map((listigOrder) => ({
        queryKey: ["listigOrder", listigOrder.orderNo],
        queryFn: async () => {
          const order = await getOrder({
            orderNo: listigOrder.orderNo,
            market,
            language,
            orderNoSource: listigOrder.orderNoSource,
            kongToken: oAuthToken ?? "",
            isCo360Enabled,
          })

          const items = await getProductInformation(
            market,
            language,
            { code: list?.businessUnit.code ?? "", type: "sto" },
            order.articles.map((art) => ({
              id: art.itemId,
              type: art.itemType as "ART" | "SPR",
            })),
            oAuthToken ?? "",
          )

          return createProductList(items, order.articles)
        },
        enabled: !!list && !!oAuthToken,
        retry: true,
      })) ?? [],
  })

  return result
    .map((r) => r.data)
    .filter(isDefined)
    .flat()
}
