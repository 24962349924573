import { useCcOrderPolling } from "@/hooks/useCcOrderPolling"
import { useListId } from "@/hooks/useHandoverQuery"
import { useListig } from "@/hooks/useListig"
import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { useAuth } from "@/hooks/useAuth"
import { useStoreInformation } from "@/hooks/useStoreInformation"
import { UpptackaErrorId } from "@/types/errors"
import { ExpiredListError, NoServiceError } from "@/utils/errors"
import { captureException } from "@/utils/sentry"
import { ErrorView } from "@/views/ErrorView"
import { ReactElement, useEffect, useState } from "react"
import { useLocation } from "react-router"

/**
 * Render children only if there's a valid session,
 * otherwise display error message.
 */
export const NoServiceErrorWrapper: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const listId = useListId()
  const { search } = useLocation()
  const { navigate } = useLocalNavigation()
  const { isOrderOutdated } = useCcOrderPolling()
  const { error: listError } = useListig()
  const { error: storeError } = useStoreInformation()
  const { oAuthError } = useAuth()
  const [error, setError] = useState<UpptackaErrorId | undefined>()

  // redirect old search handover format to new path format
  useEffect(() => {
    if (listId?.mode === "search") {
      const searchMin = new URLSearchParams(search)
      searchMin.delete("listId")
      navigate({ path: "list" }, { replace: true }, searchMin)
      const e = new Error("Old handover format")
      e.name = "Old handover"
      captureException(e, "info")
    }
  }, [listId, navigate, search])

  useEffect(() => {
    const error: UpptackaErrorId | undefined = (() => {
      switch (true) {
        case isOrderOutdated:
          return "EXPIRED_HANDOVER"
        case !listId:
          return new URLSearchParams(search).has("expired")
            ? "EXPIRED_HANDOVER"
            : "INCOMPLETE_HANDOVER"
        case !!storeError:
          return "MISSING_STORE_INFORMATION"
        case !!listError:
          return listError instanceof ExpiredListError
            ? "EXPIRED_LIST"
            : "LISTIG_FAILED"
        case !!oAuthError:
          return "OAUTH_TOKEN_FAILED"
        default:
          return undefined
      }
    })()

    setError(error)
  }, [isOrderOutdated, listId, search, listError, storeError, oAuthError])

  if (error) {
    return <ErrorView error={new NoServiceError(error)} />
  } else {
    return children
  }
}
