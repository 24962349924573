import { CashCarryOrderResponse } from "@/types/responses/buy"
import { fetchWithErrorHandling } from "@/utils/fetch"

interface GetOrderParams {
  orderNo: string
  market: string
  language: string
  orderNoSource: string
  kongToken: string
  demoParams?: URLSearchParams
  isCo360Enabled: boolean
}

export function getOrder({
  orderNo,
  market,
  language,
  orderNoSource,
  kongToken,
  demoParams,
  isCo360Enabled,
}: GetOrderParams): Promise<CashCarryOrderResponse> {
  const queryParam = new URLSearchParams()

  queryParam.append("orderNoSource", orderNoSource)
  if (isCo360Enabled) {
    queryParam.append("co360", "true")
  }

  return fetchWithErrorHandling<CashCarryOrderResponse>(
    "BUY_ORDER_RETRIEVAL",
    `${
      process.env.BUY_API_URL
    }/cc-order/${market}/${language}/${orderNo}?${queryParam.toString()}${
      demoParams ? "&" + demoParams : ""
    }`,
    {
      headers: {
        Authorization: "Bearer " + kongToken,
        "Content-Type": "application/json",
      },
    },
  )
}
