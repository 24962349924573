import { getProductInformation } from "@/api/getProductInformation"
import { useAuth } from "@/hooks/useAuth"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { ProductsResponse, UProduct } from "@/types/responses/find"
import { useIsOnline } from "./useIsOnline"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { ListigItem } from "@/types/listig"
import { createProductList } from "@/utils/createProductList"
import { keepPreviousData } from "@tanstack/react-query"

export const useProduct = (productNo: string | undefined) => {
  const { oAuthToken } = useAuth()
  const { language, market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode } = session
  const { isOnline, isLoading } = useIsOnline()

  const productsQuery = useQueryWithErrorHandling<
    ProductsResponse,
    Error,
    UProduct
  >(
    ["product", productNo],
    () =>
      oAuthToken && language && businessUnitCode && productNo && !isLoading
        ? getProductInformation(
            market,
            language,
            isOnline
              ? { code: market, type: "ru" }
              : { code: businessUnitCode, type: "sto" },
            [{ id: productNo, type: "ART" }],
            oAuthToken,
          )
        : Promise.reject(new Error("Missing list or token")),
    {
      enabled: !!oAuthToken && !!productNo && !!businessUnitCode && !isLoading,
      gcTime: Infinity,
      staleTime: 60000, // 10 minutes
      retry: 3,
      select: (data) => {
        const first = data?.[0]
        if (!first || "error" in first) {
          throw new Error("No product")
        } else {
          return first
        }
      },
    },
  )

  return {
    productInfo: productsQuery.data,
    error: productsQuery.error,
    isLoading: productsQuery.isPending,
  }
}

export const useProducts = (buCode?: string, items?: ListigItem[]) => {
  const { oAuthToken } = useAuth()
  const { market, language } = useLocale()
  const { isOnline, isLoading } = useIsOnline()

  const productsQuery = useQueryWithErrorHandling<
    ProductsResponse,
    Error,
    ReturnType<typeof createProductList>
  >(
    ["products", ...(items ?? [])],
    () =>
      items && buCode && oAuthToken && !isLoading
        ? getProductInformation(
            market,
            language,
            isOnline
              ? { code: market, type: "ru" }
              : { code: buCode, type: "sto" },
            // TODO: Only call this API for SPR and ART when COMBO has added as a feature
            items,
            oAuthToken,
          )
        : Promise.reject(new Error("Missing list or token")),
    {
      enabled: !!items && !!buCode && !!oAuthToken && !isLoading,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      select: (res) => createProductList(res, items!),
      gcTime: Infinity,
      // since we allow sharing list between devices
      // we need to occassionally invalidate data
      staleTime: 10000, // ten seconds
      placeholderData: keepPreviousData,
      retry: 3,
    },
  )

  return {
    products: productsQuery.data,
    error: productsQuery.error,
    isLoading: productsQuery.isPending,
  }
}
