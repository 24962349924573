import { ListigList } from "@/types/listig"
import { isToday } from "./isToday"
import { InstoreList } from "@/hooks/useListig"

/**
 * Get the order data from this list.
 */
export function getOrderAttachment(list: Pick<ListigList, "attachments">) {
  const attachment = list.attachments?.find((s) => s.id === "CC_ORDER")
  if (attachment && "orderNo" in attachment && "orderNoSource" in attachment) {
    return {
      orderNo: attachment.orderNo.toString(),
      orderNoSource: attachment.orderNoSource,
    }
  } else return null
}

/**
 * Get the vpc data from this list.
 */
export function getListVpcCode(list: Pick<ListigList, "attachments">) {
  const attachment = list.attachments?.find((s) => s.id === "VPC_CODE")
  if (attachment && "vpcCode" in attachment) {
    return attachment.vpcCode
  } else return ""
}

/**
 * The list has an order
 */
export const hasOrder = (list?: ListigList | InstoreList): boolean =>
  !!(
    list &&
    (getOrderAttachment(list) || (list.orders && list.orders.length > 0))
  )

/**
 * The list is active if the list exists, is not expired and does not have an order
 */
export const isActive = (list?: ListigList | InstoreList): list is ListigList =>
  !!(
    list &&
    list.id &&
    isToday(list.metadata.createdAt) &&
    !getOrderAttachment(list)
  )
