import {
  Image,
  UArticle,
  UChildProduct,
  UProduct,
} from "@/types/responses/find"
import { UGroupedProduct } from "@/types/product/pip"
import { Quantified, isNotProductError } from "@/types/product/products"
import { useProducts } from "./useProducts"
import { calculatePriceTotals } from "@/utils/calculatePriceTotals"
import { getStockAndSalesLocationFromChildItems } from "@/utils/getSalesLocation"

export type ConfigurationItem = {
  no: string
  type: "ART" | "SPR"
  quantity: number
}

export type GroupedProductDetails = {
  type: UGroupedProduct["info"]["type"]
  name: string
  no: string
  description: string
  image?: string
  images?: string[]
  range?: string
}

export function useGroupedProduct(
  buCode?: string,
  items?: ConfigurationItem[],
  details?: GroupedProductDetails,
  enabled?: boolean,
): UGroupedProduct | undefined {
  const { products: productInfos } = useProducts(
    buCode,
    items?.map((e) => ({ id: e.no, type: e.type, quantity: e.quantity })),
  )

  if (!items || !details || !productInfos || !enabled) return

  return getGroupedProduct(productInfos, items, details)
}

function getGroupedProduct(
  products: UProduct[],
  configuration: ConfigurationItem[],
  details: GroupedProductDetails,
): UGroupedProduct | undefined {
  const firstItem = products[0]
  if (!firstItem) return

  const isAvailableForDelivery = products.every(
    (plannerItem) => plannerItem.stock.homeDelivery.inRange,
  )

  // Quantify the products using the configuration
  const quantifiedProducts = products.filter(isNotProductError).map((e) => ({
    ...e,
    quantity:
      configuration.find((item) => item.no === e.info.no)?.quantity || 1,
  }))
  const totalPrice = calculatePriceTotals(quantifiedProducts)

  const childItems = flattenUProducts(quantifiedProducts)
  const { salesLocations: salesLocation, stock } =
    getStockAndSalesLocationFromChildItems(childItems)

  const images = details.images
    ? details.images.map((e) => ({
        href: e,
        id: e,
        type: "OPEN_DESIGN_IMAGE",
        fileName: e,
      }))
    : [
        ...(details.image
          ? [
              {
                href: details.image,
                id: details.no,
                type: "MAIN_PRODUCT_IMAGE",
                fileName: details.image,
              },
            ]
          : []),
        ...childItems
          .map<Image | undefined>((e) =>
            e.media.mainImage
              ? {
                  ...e.media.mainImage,
                  id: e.info.no,
                  type: "MAIN_PRODUCT_IMAGE",
                }
              : undefined,
          )
          .filter(Boolean),
      ]

  return {
    info: {
      childItems,
      description: details.description,
      longDescription: details.description,
      mediumDescription: details.description,
      name: details.name,
      no: details.no,
      type: details.type,
    },
    isAvailableForDelivery,
    media: {
      images: images.filter(Boolean) as Image[],
      mainImage: {
        href: details.image ?? firstItem.media.mainImage?.href ?? "",
        fileName: details.image ?? firstItem.media.mainImage?.fileName ?? "",
      },
    },
    price: {
      currency: firstItem.price.currency,
      regular: totalPrice.regular,
      family: totalPrice.family,
      secondCurrency: firstItem.price.secondCurrency,
      secondRegular: totalPrice.secondaryPrice,
      secondFamily: totalPrice.secondFamily,
    },
    locations: salesLocation,
    range: "range" in details ? details.range : undefined,
    stock,
  }
}

/** Takes a list of ARTs and SPRs and flattens it to ARTs.
 *
 * For SPRs, the quantity of the SPR is brought down to its children.
 */
const flattenUProducts = (products: Quantified<UProduct>[]) =>
  products
    .map((product) =>
      product.info.type === "SPR"
        ? product.info.childItems
            ?.filter(isNotProductError)
            .map((childItem) => ({
              ...childItem,
              quantity: product.quantity * childItem.quantity,
            })) ?? []
        : product,
    )
    .flat()
    .filter(isNotProductError) as Quantified<UArticle | UChildProduct>[]
