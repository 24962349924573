import { ListigList } from "@/types/listig"
import {
  ListArticle,
  ListChildItem,
  ListSalesProduct,
} from "@/types/product/listProduct"
import { isUArticle } from "@/types/product/products"
import {
  PTagChildItem,
  ProductError,
  ProductsResponse,
} from "@/types/responses/find"
import { CcOrderArticle } from "@/types/responses/buy"

export function createProductList(
  productResponse: ProductsResponse,
  listItems: ListigList["items"] | CcOrderArticle[],
): (ListArticle | ListSalesProduct)[] {
  const productList: (ListArticle | ListSalesProduct)[] = productResponse
    .filter((e): e is Exclude<typeof e, ProductError> => !("error" in e))
    .map((product): ListArticle | ListSalesProduct => {
      const listItemQuantity =
        listItems.find(
          (e) => ("itemId" in e ? e.itemId : e.id) === product.info.no,
        )?.quantity ?? 1
      if (isUArticle(product)) {
        return { ...product, quantity: listItemQuantity }
      } else {
        return {
          ...product,
          quantity: listItemQuantity,
          info: {
            ...product.info,
            childItems:
              product.info.childItems
                ?.filter(
                  (e): e is ListChildItem => !("error" in e) && !!e.price,
                )
                .map((childItem) => ({
                  ...childItem,
                  quantity: listItemQuantity * childItem.quantity,
                })) ?? [],
          },
        }
      }
    })

  return productList
}

export function createProductListFromLocalSco(
  productResponse: ProductsResponse,
  localScoChildItems: PTagChildItem[],
): (ListArticle | ListSalesProduct)[] {
  const productList: (ListArticle | ListSalesProduct)[] = productResponse
    .filter((e): e is Exclude<typeof e, ProductError> => !("error" in e))
    .map((product): ListArticle | ListSalesProduct => {
      const listItemQuantity =
        localScoChildItems.find((e) => e.itemNo === product.info.no)
          ?.quantity ?? 1
      if (isUArticle(product)) {
        return { ...product, quantity: listItemQuantity }
      } else {
        return {
          ...product,
          quantity: listItemQuantity,
          info: {
            ...product.info,
            childItems:
              product.info.childItems
                ?.filter(
                  (e): e is ListChildItem => !("error" in e) && !!e.price,
                )
                .map((childItem) => ({
                  ...childItem,
                  quantity: listItemQuantity * childItem.quantity,
                })) ?? [],
          },
        }
      }
    })

  return productList
}
