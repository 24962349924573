import useChecklist from "@/hooks/useChecklist"
import {
  DepartmentArticle,
  DepartmentChildItem,
  DepartmentProduct,
  DepartmentSalesProduct,
  isDepartmentSalesProduct,
} from "@/types/product/categorizedProduct"
import { useMemo } from "react"
import AnimateHeight from "react-animate-height"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import ListSection from "../list/ListSection"
import ProductCard, { ChecklistProps } from "../list/product-card/ProductCard"
import { getProductSalesMethods } from "@/utils/getSalesLocation"

type DepartmentSectionProps =
  | {
      type: "MARKET_HALL" | "SHOWROOM"
      products: DepartmentProduct[]
    }
  | {
      type: "FLOOR"
      products: DepartmentProduct[]
      floor: string
    }

const DepartmentSection: React.FC<DepartmentSectionProps> = (props) => {
  const { t } = useTranslation()
  const {
    checklist,
    toggleProductChecked,
    isAllChildrenChecked,
    unCheckChildren,
  } = useChecklist()

  const title = useMemo(() => {
    switch (props.type) {
      case "MARKET_HALL":
        return t("Interstitial-pickup-market-hall")
      case "SHOWROOM":
        return t("Interstitial-pickup-showroom")
      case "FLOOR":
        return t("location.floor", { number: props.floor })
    }
  }, [props, t])

  return (
    <ListSection
      title={title}
      subTitle={t(
        props.products.length <= 1 ? "common.item" : "common.item_plural",
        {
          count: props.products.length,
        },
      )}
    >
      {props.products.map((product) => {
        const { isMultipleItems } = getProductSalesMethods(product)
        return (
          <div key={product.info.no} className="mt-2 first:mt-0">
            {isDepartmentSalesProduct(product) ? (
              <div id={props.type + "_" + product.info.no}>
                <ProductCard
                  key={product.info.no}
                  artNo={product.info.no}
                  title={product.info.name}
                  image={product.media.mainImage}
                  description={product.info.mediumDescription}
                  partiallyAvailableText={getPartiallyAvailableText(product, t)}
                  price={product.price}
                  offers={product.offers}
                  quantity={product.quantity}
                  currency={product.price.currency}
                  availability={product.stock.cashCarry}
                  type="SPR_PARENT"
                  checklist={{
                    isChecked: isAllChildrenChecked(
                      product.info.no,
                      product.info.childItems.length,
                    ),
                    onCheck: () => unCheckChildren(product.info.no),
                  }}
                  hasMultipleLocations={isMultipleItems}
                  product={product}
                />
                <AnimateHeight
                  duration={300}
                  height={
                    isAllChildrenChecked(
                      product.info.no,
                      product.info.childItems.length,
                    )
                      ? 0
                      : "auto"
                  }
                  disableDisplayNone
                >
                  {product.info.childItems.map((childItem) => (
                    <DepartmentArticleProductCard
                      key={childItem.info.no}
                      article={childItem}
                      isChild={true}
                      checklist={{
                        isChecked:
                          !!checklist[
                            product.info.no + "." + childItem.info.no
                          ],
                        onCheck: () =>
                          toggleProductChecked(
                            product.info.no + "." + childItem.info.no,
                            childItem.quantity,
                            childItem.price,
                            childItem.locations.find(
                              (e) => e.salesMethod === "MARKET_HALL",
                            )
                              ? "markethall"
                              : "showroom",
                          ),
                      }}
                    />
                  ))}
                </AnimateHeight>
              </div>
            ) : (
              <DepartmentArticleProductCard
                key={product.info.no}
                article={product}
                checklist={{
                  isChecked: !!checklist[product.info.no],
                  onCheck: () =>
                    toggleProductChecked(
                      product.info.no,
                      product.quantity,
                      product.price,
                      product.locations.find(
                        (e) => e.salesMethod === "MARKET_HALL",
                      )
                        ? "markethall"
                        : "showroom",
                    ),
                }}
                showImage
              />
            )}
          </div>
        )
      })}
    </ListSection>
  )
}

export default DepartmentSection

function DepartmentArticleProductCard(props: {
  article: DepartmentArticle | DepartmentChildItem
  showImage?: boolean
  checklist?: ChecklistProps
  isChild?: boolean
}) {
  return (
    <ProductCard
      key={props.article.info.no}
      title={props.article.info.name}
      artNo={props.article.info.no}
      fee={props.article.price.fees}
      image={props.article.media.mainImage}
      isChildItem={props.isChild}
      hideImage={!props.showImage}
      description={props.article.info.mediumDescription}
      offers={props.article.offers}
      price={props.article.price}
      quantity={props.article.quantity}
      energyLabel={props.article.media.energyLabelImage}
      packages={props.article.info.packaging.packages}
      currency={props.article.price.currency}
      availability={props.article.stock.cashCarry}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      type={props.article.locations[0]!.salesMethod}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      department={props.article.locations[0]!.departmentName}
      checklist={props.checklist}
      product={props.article}
    />
  )
}

function getPartiallyAvailableText(
  product: DepartmentSalesProduct,
  t: TFunction<"translation", undefined>,
) {
  return product.info.isSplit
    ? t(
        `Interstitial-ProductListCard-collect-self-serve-of-split-spr${
          product.info.hasFullServePart ? "" : "-generic"
        }`,
        {
          count: product.info.totalChildItems,
        },
      )
    : undefined
}
