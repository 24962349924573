import { AddItemsToOrderBodySchema } from "@/types/responses/buy"
import { fetchWithErrorHandling } from "@/utils/fetch"
import { isIsomFullServe } from "@/utils/fullServeRestrictions"
import { getLocale } from "@/utils/locale"

interface Props {
  orderNo: string
  orderNoSource: string
  storeNo: string
  products: AddItemsToOrderBodySchema["items"]
  market: string
  language: string
  kongToken: string
  ukid: string | undefined
  listId: string
  isCo360Enabled: boolean
}

export function editOrder({
  orderNo,
  orderNoSource,
  storeNo,
  products,
  market,
  language,
  kongToken,
  ukid,
  listId,
  isCo360Enabled,
}: Props): Promise<void> {
  const locale = getLocale(market, language)
  const reqBody: AddItemsToOrderBodySchema = {
    buCode: storeNo,
    items: products,
    locale: locale,
    workStationName: ukid?.toLowerCase() ?? "",
    clientSystem: {
      name: "upptacka",
      version: "1.0.0",
    },
    listId: listId,
  }

  const queryParam = new URLSearchParams()

  if (isIsomFullServe(market, storeNo, ukid ?? "")) {
    queryParam.append("isom", "true")
  }

  if (isCo360Enabled) {
    queryParam.append("co360", "true")
  }

  return fetchWithErrorHandling<void>(
    "BUY_ORDER_MODIFY",
    `${
      process.env.BUY_API_URL
    }/cc-order/${market}/${language}/${orderNo}/${orderNoSource}?${queryParam.toString()}`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + kongToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    },
  )
}
