import {
  Measurement,
  PackageMeasurement,
  PackageMeasurements,
} from "@/types/responses/find"
import { measureMap } from "@/config/locales"
import { Dimension } from "@/types/responses/ptag"

const orderedDimension: (keyof PackageMeasurements)[] = [
  "height",
  "width",
  "depth",
  "length",
  "weight",
  "netweight",
  "volume",
  "diameter",
]

/**
 * Sort package measurements into an ordered list
 */
const sortPackageDimensions = (
  dimensions: PackageMeasurements,
): {
  type: keyof PackageMeasurements
  val: PackageMeasurement
}[] =>
  orderedDimension
    .map((type) => ({ type, val: dimensions[type] }))
    .filter(
      (
        d,
      ): d is Omit<typeof d, "val"> & {
        val: Exclude<(typeof d)["val"], string | undefined>
      } => !!d.val && typeof d.val !== "string",
    )

const DimensionListItem = ({
  type,
  index,
  name,
  text,
}: {
  type: string
  index: number
  name: string
  text: string
}) => (
  <li
    className={`product-dimensions text-gray-900 ${
      type.toUpperCase() === "HEIGHT" && index > 0 ? "mt-10" : ""
    }`}
  >
    {name}: {text}
  </li>
)

export function PackageDimensionList({
  dimensions,
  market,
}: {
  dimensions: PackageMeasurements[]
  market: string
}) {
  const measureUnit = measureMap[market.toLowerCase()]?.unit

  return (
    <>
      {dimensions
        .map((pack, packNo) =>
          sortPackageDimensions(pack).map(({ type, val }) => (
            <DimensionListItem
              key={packNo + type}
              type={type}
              index={packNo}
              name={val.name}
              text={
                measureUnit === "metric" ? val.metric.text : val.imperial.text
              }
            />
          )),
        )
        .flat()}
    </>
  )
}

export function ProductDimensionList({
  dimensions,
}: {
  dimensions: Measurement[]
}) {
  return (
    <>
      {dimensions.map((dimension, index) => (
        <DimensionListItem
          key={index}
          type={dimension.type}
          index={index}
          name={dimension.name}
          text={dimension.text}
        />
      ))}
    </>
  )
}

export function ScoProductDimensionList({
  isMetric,
  dimensions,
}: {
  isMetric: boolean
  dimensions: Dimension[]
}) {
  return (
    <>
      {dimensions.map((dimension, index) => (
        <DimensionListItem
          key={index}
          type={dimension.itemMeasureTypeNo}
          index={index}
          name={dimension.itemMeasureTypeName}
          text={
            isMetric
              ? dimension.itemMeasureTextMetricShort
              : dimension.itemMeasureTextImperialShort
          }
        />
      ))}
    </>
  )
}
