import { UProduct } from "@/types/responses/find"
import ProductIdentifier from "@ingka/product-identifier"
import { useTranslation } from "react-i18next"
import { ProductDescriptionAccordion } from "./ProductDescriptionAccordion"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import Button from "@ingka/button"
import { useState } from "react"

interface ProductDescriptionProps {
  product: UProduct
  isDiyAccordionOpen: boolean
}

export const ProductDescription: React.FC<ProductDescriptionProps> = (
  props,
) => {
  const { t } = useTranslation()
  const [showAllProductDetails, setShowAllProductDetails] = useState(false)

  const multipleBenefitsLines =
    props.product.info.detailedInformation.benefits &&
    props.product.info.detailedInformation.benefits.length > 1

  return (
    <>
      <p className="pt-0 pb-0 text-lg font-bold text-black">
        {props.product.info.name}
      </p>

      <div className="text-sm pt-6">{t("common.article-number")}</div>
      <ProductIdentifier
        className="mt-2 mb-5"
        value={dottedIdentifier(props.product.info.no)}
      />

      <div>
        {props.product.info.detailedInformation.benefits?.map(
          (text, index) =>
            (showAllProductDetails || index < 1) && (
              <p
                key={`detailedInformation-${text}`}
                className="text-sm benefits"
              >
                {text}
              </p>
            ),
        )}
      </div>
      {multipleBenefitsLines && (
        <Button
          className="font-light capitalize underline"
          onClick={() => setShowAllProductDetails((value) => !value)}
          text={
            showAllProductDetails
              ? t("common.show-less")
              : t("common.show-more")
          }
          type="plain"
        />
      )}

      <div className="pt-6">
        <ProductDescriptionAccordion
          product={props.product}
          isDiyAccordionOpen={props.isDiyAccordionOpen}
        />
      </div>
    </>
  )
}

interface DepartmentLocationProps {
  department: string
}
export const DepartmentLocation: React.FC<DepartmentLocationProps> = (
  props,
) => {
  return (
    <div className={`flex flex-row gap-2 transition-all duration-150`}>
      <ProductIdentifier value={props.department} />
    </div>
  )
}
