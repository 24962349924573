import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { useProduct } from "@/hooks/useProducts"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import Button from "@ingka/button"
import FormField from "@ingka/form-field"
import InputField from "@ingka/input-field"
import SSRIcon from "@ingka/ssr-icon"
import arrowLeft from "@ingka/ssr-icon/paths/arrow-left"
import warningTriangle from "@ingka/ssr-icon/paths/warning-triangle"
import { SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BelowMessage } from "../common/BelowMessage"
import { sendUnsuccessfulArticleNumberEvent } from "@/analytics/events/sendUnsuccessfulArticleNumberEvent"
import { sendSuccessfulArticleNumberEvent } from "@/analytics/events/sendSuccessfulArticleNumberEvent"
import { sendOpenPipEvent } from "@/analytics/events/sendOpenPipEvent"

export const EnterProductNumber: React.FC = () => {
  // value in input field
  const [inputValue, setInputValue] = useState<string>("")
  // true if validation message should be displayed
  const [shouldValidate, setShouldValidate] = useState(false)
  // true if the user has confirmed the current input
  const [confirmed, setConfirmed] = useState(false)
  const { navigate } = useLocalNavigation()
  const { t } = useTranslation()

  const inputIsValid = !!inputValue && /^\d{8}$/.test(inputValue)

  const product = useProduct(inputIsValid ? inputValue : undefined)

  // display helper text if confirm is pressed while product number is invalid
  useEffect(() => {
    if (!inputIsValid && confirmed) setShouldValidate(true)
  }, [confirmed, inputIsValid])

  // navigate when product number is confirmed and product is fetched
  useEffect(() => {
    if (confirmed && product) {
      const productNo = product.productInfo?.info.no
      productNo && sendSuccessfulArticleNumberEvent()
      productNo && sendOpenPipEvent(productNo, "scanner")
      productNo && navigate({ path: "product", productNo })
    }
  }, [confirmed, inputIsValid, navigate, product, inputValue])

  return (
    <div style={{ width: "100dvw", height: "100dvh" }}>
      <div className="p-6 space-y-8 flex flex-col w-full h-full">
        <div onClick={() => navigate({ path: "add-product" })}>
          <SSRIcon paths={arrowLeft} />
        </div>
        <div className="space-y-2">
          <h6>{t("scanner.add-product-number-title")}</h6>
        </div>
        <FormField
          valid={inputIsValid}
          shouldValidate={shouldValidate}
          validation={{
            msg: t("scanner.add-product-number-error"),
            id: "article-number-error-id",
          }}
          fieldHelper={{
            msg: `${t("scanner.add-product-number-help")} 123.456.78`,
            id: "article-number-helper-id",
          }}
        >
          <InputField
            label={t("common.article-number")}
            value={dottedIdentifier(inputValue)}
            onChange={(e) => {
              setConfirmed(false)
              if (e.target.value.length <= 10)
                setInputValue(updateInputState(e.target.value))
            }}
            id="product-number-input"
            type="number"
          />
        </FormField>
        <div className="flex-1" />
        <Button
          type="emphasised"
          fluid
          text={t("common.continue")}
          onClick={() => {
            setConfirmed(true)
          }}
          loading={confirmed && product.isLoading}
        />
      </div>
      {inputValue && (
        <BelowMessage
          show={!!product.error && !!inputValue && confirmed && inputIsValid}
        >
          <div className="flex flex-col items-center p-10 space-y-4">
            <SSRIcon paths={warningTriangle} />
            <div className="pl-2 pr-2 flex flex-col space-y-4 text-center">
              <p
                className="text-sm"
                dangerouslySetInnerHTML={{
                  __html:
                    (inputIsValid &&
                      replaceProductNoInErrorMessage(
                        t("scanner.add-product-number-missing"),
                        inputValue
                      )) ||
                    "",
                }}
              />
            </div>
            <Button
              text={t("common.try-again-button")}
              onClick={() => {
                sendUnsuccessfulArticleNumberEvent()
                setInputValue("")
              }}
              type="primary"
              fluid
            />
          </div>
        </BelowMessage>
      )}
    </div>
  )
}

/**
 * Update input state by removing everything except digits,
 * and also removing an extra character if a dot is removed
 */
function updateInputState(inputFieldValue: string): SetStateAction<string> {
  return (currentState: string) => {
    const didRemoveDot =
      dottedIdentifier(currentState).length > currentState.length &&
      noDots(inputFieldValue) === currentState
    // if a dot was removed we need to auto delete the preceeding digit as well
    const newVal = didRemoveDot
      ? inputFieldValue.substring(0, inputFieldValue.length - 1)
      : inputFieldValue

    return noDots(newVal.replace(/\D/g, ""))
  }
}

/**
 * Find 12345678 (placeholder product number) in the string and replace it with productNo
 * @param phraseString string as provided by phrase
 * @param productNo eight digits without dots
 */
function replaceProductNoInErrorMessage(
  phraseString: string,
  productNo: string
) {
  return phraseString.replace(
    "12345678",
    `<b>${dottedIdentifier(productNo)}</b>`
  )
}

/**
 * Remove dots from string
 */
function noDots(v: string) {
  return v.replace(".", "")
}
