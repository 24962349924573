import { capitalize } from "@/config/locales"
import { UProduct, VariantCategory } from "@/types/responses/find"
import { ProductVariant } from "./ProductVariant"
import { getSortedVariants } from "@/utils/sortVariants"
import { VisualVariationTypes } from "@/config/constants"

interface ProductVariantsProps {
  variantCategory: VariantCategory | undefined
  currentProduct: UProduct
  handleVariantOnClick: (productNo: string) => void
  variantTypeNameGlobal: string
}

export const ProductVariants = ({
  variantCategory,
  currentProduct,
  handleVariantOnClick,
  variantTypeNameGlobal,
}: ProductVariantsProps) => {
  const sortedVariants =
    variantCategory?.typeNameGlobal &&
    VisualVariationTypes.includes(variantCategory.typeNameGlobal)
      ? getSortedVariants(variantCategory, currentProduct.info.no)
      : variantCategory?.variants

  return (
    variantCategory &&
    sortedVariants && (
      <div className="flex flex-1 flex-col overflow-y-scroll py-6 pt-0 ">
        <h2>{capitalize(variantCategory.typeName)}</h2>

        {sortedVariants.map((variant) => (
          <ProductVariant
            key={variant?.productNo}
            variant={variant}
            handleVariantOnClick={handleVariantOnClick}
            currentVariantValue={
              variantCategory.variants?.find(
                (v) => v.productNo === currentProduct.info.no,
              )?.value ?? ""
            }
            currentProduct={currentProduct}
            variantTypeNameGlobal={variantTypeNameGlobal}
          />
        ))}
      </div>
    )
  )
}
