import { UError } from "@/utils/errors"
import { useLocation, useParams } from "react-router-dom"
import { validate as validateUuid } from "uuid"
import { EntryPoint } from "./useVisit"
import { captureException } from "@/utils/sentry"
import {
  parseHandoverItems,
  parseSingleProductHandover,
} from "@/utils/parseHandover"

export function useShortId() {
  const { search } = useLocation()
  return new URLSearchParams(search).get("shortId")
}

const getListId = (search: string, paramListId?: string) => {
  let listId: { listId: string; mode: "path" | "search" } | null

  if (paramListId) {
    listId = { listId: paramListId, mode: "path" }
  } else {
    const searchListId = new URLSearchParams(search).get("listId")
    listId = searchListId ? { listId: searchListId, mode: "search" } : null
  }

  if (listId && !validateUuid(listId.listId)) {
    captureException(new UError("INVALID_LISTIG_LIST_ID"))
    return
  }

  return listId
}

export function useListId() {
  const { search } = useLocation()
  const { listId: paramListId } = useParams<{ listId: string }>()

  return getListId(search, paramListId)
}

export function useParseHandover(entryPoint: EntryPoint) {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  const handoverItems = queryParams.get("items")
  const storeNo = queryParams.get("storeNo")
  const source = queryParams.get("source")
  const sessionId = queryParams.get("sessionId")

  const handover = (() => {
    switch (entryPoint) {
      case "product":
        return parseSingleProductHandover(queryParams)
      case "new":
        return (
          storeNo &&
          source && {
            source,
            storeNo,
            sessionId,
          }
        )
      default:
        return (
          handoverItems &&
          storeNo &&
          source && {
            items: parseHandoverItems(handoverItems),
            source,
            storeNo,
            sessionId,
          }
        )
    }
  })()

  if (!handover) {
    throw new UError("INCOMPLETE_HANDOVER")
  }
  return handover
}
