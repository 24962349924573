import { UProduct } from "@/types/responses/find"
import { useTranslation } from "react-i18next"

interface Props {
  product: UProduct
}

export function ProductComplianceSheet({ product }: Props) {
  const { t } = useTranslation()
  const energyLabelImage = product.media.images.find(
    (i) => i.type === "ENERGY_LABEL_IMAGE",
  )?.href

  const technicalCompliance =
    product.info.detailedInformation.technical?.compliance?.map(
      (compliance) => ({
        label: compliance.heading,
        text: compliance.value,
      }),
    ) ??
    product.info.detailedInformation.technical?.information?.[0]?.parts?.map(
      (group) => ({ label: group.title, text: group.value }),
    ) ??
    []

  return (
    <div
      data-component={
        energyLabelImage ? "product-energy-sheet" : "product-water-sheet"
      }
      className="flex flex-col overflow-y-scroll pb-4 text-left"
    >
      {energyLabelImage && (
        <>
          <h3 className="w-full text-lg">
            {t("ProductInformation-energy-class")}
          </h3>
          <img
            className="energy-image my-6 w-80 rounded border border-solid border-gray-300 p-4 mx-auto"
            alt="Energy class"
            src={energyLabelImage}
            data-cy="detailed-energy-label"
          />
        </>
      )}

      <table className="block">
        <tbody className="technical-information flex flex-col">
          {technicalCompliance.map(
            (element) =>
              element.label &&
              element.text && (
                <tr className={"mt-3 flex"} key={element.label}>
                  <td
                    data-cy="compliance-table-lable"
                    className={
                      "flex-1 border-t border-solid border-gray-100 px-3 py-4 text-sm text-gray-900"
                    }
                  >
                    {element.label}
                  </td>
                  <td
                    data-cy="compliance-table-text"
                    className={
                      "flex-1 break-all border-t border-solid border-gray-100 px-3 py-4 text-sm text-gray-900"
                    }
                  >
                    {element.text}
                  </td>
                </tr>
              ),
          )}
        </tbody>
      </table>
    </div>
  )
}
