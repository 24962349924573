import { InstoreOrder } from "@/types/order"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"

interface FullServeButtonProps {
  order: InstoreOrder | undefined
  disabled?: boolean
  text: string
  loading?: boolean
  onClick: () => void
}

export const FullServeButton: React.FC<FullServeButtonProps> = (props) => {
  const { t } = useTranslation()

  return (
    <div className="py-8 px-6 flex flex-col">
      {props.order && !props.order.isPaid && (
        <p className="text-xs mb-8">
          {t("Interstitial-pay-for-full-serve-explainer")}
        </p>
      )}

      <Button
        className="flex-1"
        type="emphasised"
        text={props.text}
        onClick={props.onClick}
        disabled={props.disabled}
        loading={props.loading}
        data-cy="create-order-button"
      />
    </div>
  )
}
