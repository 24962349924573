import { useTranslation } from "react-i18next"
import OrderStatus from "./OrderStatus"
import { PipProduct } from "@/types/product/pip"
import { UChildProduct } from "@/types/responses/find"

interface Props {
  products: (PipProduct | UChildProduct)[]
}

export function FullServeOnboarding({ products }: Props) {
  const { t } = useTranslation()
  const images: string[] = products.map(
    (product) => product.media.mainImage?.href ?? "",
  )

  const imagesToShow = images.length > 4 ? images.slice(0, 3) : images
  const multipleProducts = images.length > 1

  return (
    <div className="flex flex-col">
      <div className="mt-6">
        <OrderStatus showStatus={false} />
      </div>
      <div
        className={`grid ${
          multipleProducts ? "grid-cols-2" : "grid-cols-1"
        } gap-6 mb-10 self-center`}
      >
        {imagesToShow.map((image) => (
          <img
            key={image}
            className="overflow-hidden"
            alt="Product image"
            src={image}
            width={multipleProducts ? "120" : "180"}
            height={multipleProducts ? "120" : "180"}
          />
        ))}
        {images.length > 4 && (
          <p className="p-11 text-2xl text-center text-gray-250">
            +{images.length - 3}
          </p>
        )}
      </div>

      <p className="text-2xl font-bold m-0 text-gray-600">
        {t("mobile.collect-after-payment", { count: multipleProducts ? 1 : 2 })}
      </p>
      <p className="text-base m-0 mt-3">
        {t("mobile.order-collect-after-payment", {
          count: multipleProducts ? 1 : 2,
        })}
      </p>
    </div>
  )
}
