import { Image, Offers, Prices } from "@/types/responses/find"
import { ProductPrice } from "./ProductPrice"
import { ProductAvailableForDelivery } from "../list/product-card/ProductAvailableForDelivery"
import documentListIcon from "@ingka/ssr-icon/paths/document-pencil"
import shoppingBagAdd from "@ingka/ssr-icon/paths/shopping-bag-add"
import { ProductLocation } from "../pip/ProductLocation"
import { FullServeOnboardingModal } from "../modals/FullServeOnboardingModal"
import { useEffect, useState } from "react"
import { useIsOnline } from "@/hooks/useIsOnline"
import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import useVisit from "@/hooks/useVisit"
import { useListig } from "@/hooks/useListig"
import { useTranslation } from "react-i18next"
import { useNotification } from "@/contexts/NotificationContext"
import { useCanModifyList } from "@/hooks/useCanModifyList"
import { ButtonTooltip } from "../ButtonTooltip"
import Button from "@ingka/button"
import SSRIcon from "@ingka/ssr-icon"
import { sendPipAddItemEvent } from "@/analytics/events/sendPipAddItemEvent"
import { ListigItem } from "@/types/listig"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import { t } from "i18next"
import { GridPipImages } from "../pip/GridPipImages"
import { PriceTotals, calculatePriceTotals } from "@/utils/calculatePriceTotals"
import ListView, { ListViewItem } from "@ingka/list-view"
import { sendPipButtonTapEvent } from "@/analytics/events/sendPipButtonTapEvent"
import StockInformation from "../list/product-card/StockInformation"
import { PipProduct, UGroupedProduct } from "@/types/product/pip"
import { Quantified, isNotProductError } from "@/types/product/products"
import { useInterLocalSco } from "@/hooks/useInterLocalSco"
import { ScoProductDetailsModal } from "../modals/ScoProductDetailsModal"

interface LocalScoProps {
  groupedProduct: UGroupedProduct
}

const getOfferType = (subPriceType?: string): Offers["offerType"] => {
  switch (subPriceType) {
    case "new-lower-price":
      return "nlp"
    case "time-restricted-offer":
      return "tro"
    case "family":
      return "family"
    default:
      return "none"
  }
}

const getDiscountReason = (subPriceType?: string) => {
  switch (subPriceType) {
    case "new-lower-price":
      return "NewLowerPrice"
    case "time-restricted-offer":
      return "TimeRestrictedOffer"
    default:
      return undefined
  }
}

export const LocalSco: React.FC<LocalScoProps> = (props) => {
  const { isOnline } = useIsOnline()
  const [showProductDetails, setShowProductDetails] = useState(false)
  const { interLocalScoInfo: productInfo } = useInterLocalSco(
    props.groupedProduct.info.no,
  )

  const interRegularPrice = productInfo?.price.find(
    (price) => price.priceType === "regular-sales-unit-price",
  )
  const interFamilyPrice = productInfo?.price.find(
    (price) => price.priceType === "ikea-family-sales-unit-price",
  )
  const reason = getDiscountReason(interRegularPrice?.subPriceType)

  const combinedPrices = {
    ...props.groupedProduct.price,
    regular: {
      ...props.groupedProduct.price.regular,
      ...(interRegularPrice?.previousPriceIncludingTax && {
        previousPriceInclTax: +interRegularPrice.previousPriceIncludingTax,
        reason,
        ...(reason !== "NewLowerPrice" && {
          validFrom: interRegularPrice.validFrom,
          validTo: interRegularPrice.validTo,
        }),
      }),
    },
  }

  const offers = {
    isDiscontinued: false,
    isLastChance: false,
    isBreathTakingItem: false,
    offerType: interFamilyPrice
      ? "family"
      : getOfferType(interRegularPrice?.subPriceType),
  }

  const [showFullserveOnboarding, setShowFullserveOnboarding] = useState(false)
  const { navigate } = useLocalNavigation()
  const { visited } = useVisit()
  const { list } = useListig()

  const quantifiedProducts: Quantified<PipProduct>[] | undefined =
    props.groupedProduct.info.childItems
      ?.filter(isNotProductError)
      .filter((item) => !!item.price)
      .map((e) => ({
        ...e,
        price: e.price as Prices,
      }))

  const totalPrice = calculatePriceTotals(quantifiedProducts)
  const productImages = quantifiedProducts
    ?.filter((product) => product.media.mainImage)
    .map((product) => {
      const image: Image = {
        id: product.info.no,
        href: product.media.mainImage?.href ?? "",
        alt: product.media.mainImage?.alt ?? "",
        fileName: product.media.mainImage?.fileName ?? "",
      }
      return image
    }) as Image[]

  const onClose = () =>
    navigate({
      path: visited.welcome || list?.items.length ? "list" : "welcome",
    })

  return (
    quantifiedProducts && (
      <>
        <div className="flex flex-row">
          <div className="overflow-x-scroll flex flex-row m-6">
            <GridPipImages images={productImages} />
          </div>
        </div>

        <div className="w-full p-8 flex flex-col justify-between">
          <div>
            <div className="flex flex-col justify-between">
              <div className="pb-2">
                <ProductPrice
                  productName={
                    productInfo?.validProductName ??
                    props.groupedProduct.info.mediumDescription ??
                    ""
                  }
                  productDescription={
                    productInfo?.productTypeLocal[0]?.productTypeName ??
                    t("common.article-number") +
                      ": " +
                      dottedIdentifier(props.groupedProduct.info.no)
                  }
                  offers={offers}
                  prices={combinedPrices}
                />
              </div>
              {isOnline ? (
                <div className="pt-1">
                  <ProductAvailableForDelivery
                    isAvailableForDelivery={
                      props.groupedProduct.stock.homeDelivery.inRange
                    }
                  />
                </div>
              ) : (
                <StockInformation
                  product={props.groupedProduct}
                  quantity={props.groupedProduct.stock.cashCarry.quantity ?? 0}
                  probability={
                    props.groupedProduct.stock.cashCarry.probability?.thisDay ??
                    "OUT_OF_STOCK"
                  }
                />
              )}
            </div>

            <hr className="mb-2 mt-8 border-gray-200" />

            {productInfo && (
              <ListView id="product-data" className="mb-6">
                <ListViewItem
                  inset
                  id="product-details"
                  title={t("ProductDescriptionAccordion-title-product-details")}
                  onClick={() => {
                    sendPipButtonTapEvent(productInfo.itemNo, "product_details")
                    setShowProductDetails(true)
                  }}
                  control="navigational"
                  controlIcon="chevronRight"
                />
              </ListView>
            )}
            <ProductLocation
              product={props.groupedProduct}
              onHelpClick={() => setShowFullserveOnboarding(true)}
            />
          </div>
          <div className="h-4" />
          <LocalScoFooter
            localScoData={props.groupedProduct}
            products={quantifiedProducts}
            totalPrice={totalPrice}
            onClose={onClose}
          />
        </div>
        {productInfo && (
          <ScoProductDetailsModal
            childItems={props.groupedProduct.info.childItems.filter(
              isNotProductError,
            )}
            product={productInfo}
            showing={showProductDetails}
            onClose={() => setShowProductDetails(false)}
          />
        )}
        <FullServeOnboardingModal
          products={props.groupedProduct.info.childItems.filter(
            isNotProductError,
          )}
          onClose={() => {
            setShowFullserveOnboarding(false)
          }}
          showing={showFullserveOnboarding}
        />
      </>
    )
  )
}

const LocalScoFooter: React.FC<{
  localScoData: UGroupedProduct
  products: Quantified<PipProduct>[] | undefined
  totalPrice: PriceTotals
  onClose: () => void
}> = ({ localScoData, products, totalPrice, onClose }) => {
  const { addProducts } = useListig()
  const { visited, setVisitedPage } = useVisit()
  const { t } = useTranslation()
  const { isOnline } = useIsOnline()
  const [isAdding, setIsAdding] = useState(false)
  const canModifyList = useCanModifyList()
  const notificationContext = useNotification()

  // report and restore if add product failed
  useEffect(() => {
    if (addProducts.error) {
      setIsAdding(false)
    }
  }, [addProducts.error])

  const productsToAdd = products?.map((product) => {
    const item: ListigItem = {
      id: product.info.no,
      quantity: product.quantity,
      type: product.info.type,
    }
    return item
  })

  return (
    productsToAdd &&
    canModifyList && (
      <div className="sticky bottom-0 w-full bg-white pb-6 pt-2">
        <ButtonTooltip
          showToolTip={visited.entryPoint === "product" && !visited.product}
          closeToolTip={() => setVisitedPage("product")}
          title={t("mobile.onboarding-product-title")}
          body={t("mobile.onboarding-product-body")}
          confirmText={t("common.got-it")}
        >
          <Button
            onClick={() => {
              addProducts.add(
                productsToAdd,

                {
                  onSuccess: () => {
                    sendPipAddItemEvent(localScoData.info.no, 1, totalPrice)
                    setIsAdding(false)
                    setVisitedPage("product")
                    onClose()
                    notificationContext?.show(
                      t("ShoppingList-product-added", {
                        product: localScoData.info.no.toUpperCase(),
                      }),
                    )
                  },
                },
              )
              setIsAdding(true)
            }}
            type="emphasised"
            fluid
            loading={isAdding}
          >
            {isOnline ? (
              <SSRIcon paths={shoppingBagAdd} />
            ) : (
              <SSRIcon paths={documentListIcon} />
            )}
            <span className="w-2" />
            <span>{t("common.add-to-shopping-list")}</span>
          </Button>
        </ButtonTooltip>
      </div>
    )
  )
}
