import {
  PermDiscountedPrice,
  Price,
  Prices,
  SprProductInfo,
  TimeDiscountedPrice,
  UArticle,
  UChildProduct,
  USalesProduct,
} from "../responses/find"
import {
  InStoreArticle,
  InStoreChildItem,
  InStoreSalesProduct,
  OnlineArticle,
  OnlineChildItem,
} from "./categorizedProduct"

export interface ListArticle extends UArticle {
  quantity: number
}

interface ListSprProductInfo extends SprProductInfo {
  childItems: ListChildItem[]
}

export interface ListSalesProduct extends USalesProduct {
  info: ListSprProductInfo
  quantity: number
}
export interface ListChildItem extends UChildProduct {
  price: Prices
  quantity: number
}

export type ListProduct = ListArticle | ListChildItem | ListSalesProduct

export function isArticle(
  product: ListProduct,
): product is ListArticle | ListChildItem {
  return product.info.type === "ART"
}
export function isChildItem(product: ListProduct): product is ListChildItem {
  return product.info.type === "ART"
}

export function isSalesProduct(
  product: ListProduct,
): product is ListSalesProduct {
  return product.info.type === "SPR"
}

export function isInStockSalesProduct(
  product: ListSalesProduct,
): product is InStoreSalesProduct {
  return product.info.type === "SPR" && product.stock.cashCarry.inRange
}

export function isInStock(
  product: InStoreArticle | OnlineArticle | InStoreChildItem | OnlineChildItem,
): product is InStoreArticle {
  return (
    product.stock.cashCarry.inRange &&
    !!product.stock.cashCarry.quantity &&
    product.stock.cashCarry.quantity >= product.quantity &&
    product.stock.cashCarry.probability?.thisDay !== "OUT_OF_STOCK" &&
    !!product.locations?.[0] &&
    (product.locations[0].salesMethod !== "FULL_SERVE" ||
      !product.disabledRange?.isOnlineOnly)
  )
}

export function isDiscountedPrice(
  price: Price,
): price is TimeDiscountedPrice | PermDiscountedPrice {
  return "reason" in price && !!price.reason
}
