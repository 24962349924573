import { sendKepsEvent } from "../keps"

export function sendOrderDetailsOpenEvent(orderNo: string | undefined) {
  sendKepsEvent({
    event_location: "list",
    event_name: "order",
    event_detail: "order_details_opened",
    event_payload: JSON.stringify({ order_number: orderNo }),
  })
}
