import Modal, {
  ModalBody,
  ModalHeader,
  ModalFooter,
  Sheets,
} from "@ingka/modal"
import { FullServeOnboarding } from "@/components/full-serve/FullServeOnboarding"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"
import { sendFullServeOnboardingEvent } from "@/analytics/events/sendFullServeOnboardingEvent"
import { PipProduct } from "@/types/product/pip"
import { UChildProduct } from "@/types/responses/find"

type FullServeOnboardingModalProps = {
  products: (PipProduct | UChildProduct)[]
  onClose: () => void
  showing: boolean
}

export const FullServeOnboardingModal: React.FC<
  FullServeOnboardingModalProps
> = ({ products, onClose, showing }) => {
  const { t } = useTranslation()

  return (
    <Modal
      visible={showing}
      handleCloseBtn={() => {
        sendFullServeOnboardingEvent("cross_clicked")
        onClose()
      }}
    >
      <Sheets
        size="large"
        header={<ModalHeader closeBtnClick={onClose} />}
        footer={
          <ModalFooter className="px-6 pt-0">
            <Button
              type="primary"
              text={t("mobile.fs-onboarding-button")}
              onClick={() => {
                sendFullServeOnboardingEvent("button_clicked")
                onClose()
              }}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <FullServeOnboarding products={products} />
        </ModalBody>
      </Sheets>
    </Modal>
  )
}
