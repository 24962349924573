import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { TFunction } from "i18next"

type FullServeStatus =
  | "WAITING_FOR_PAYMENT"
  | "PREPARING_ORDER"
  | "READY_FOR_PICKUP"

interface FullServeStatusTextProps {
  status?: FullServeStatus
}

const FullServeStatusText: React.FC<FullServeStatusTextProps> = ({
  status,
}) => {
  const { t } = useTranslation()

  return (
    <div data-cy="fullserve-status-text" className="flex">
      <p
        className={classNames(`rounded p-1 text-sm font-bold `, {
          "text-blue-brand bg-blue-brand-opacity/[.08]":
            status !== "READY_FOR_PICKUP",
          "text-semantic-positive bg-semantic-positive-opacity/[.08]":
            status === "READY_FOR_PICKUP",
        })}
      >
        {getOrderStatusText(t, status)}
      </p>
    </div>
  )
}

export default FullServeStatusText

function getOrderStatusText(
  t: TFunction<"translation", undefined>,
  status?: FullServeStatus,
) {
  switch (status) {
    case "WAITING_FOR_PAYMENT":
      return t("Interstitial-full-serve-waiting-for-payment-loading")
    case "PREPARING_ORDER":
      return t("Interstitial-OrderStatus-preparing")
    case "READY_FOR_PICKUP":
      return t("Interstitial-OrderStatus-pick-up")
    default:
      return t("mobile.reserved")
  }
}
