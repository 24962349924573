import { sendHandoverToOnlineEvent } from "@/analytics/events/sendHandoverToOnlineEvent"
import { OnlineProduct } from "@/types/product/categorizedProduct"
import { isArticle } from "@/types/product/listProduct"

type SmpHandoverItem = { id: string; quantity: number; itemType: "ART" | "SPR" }

/**
 * @param market country code
 * @param language language code
 * @param products list of products to handover
 */
export function onlineHandover(
  market: string,
  language: string,
  storeNo: string | undefined,
  ukid: string | undefined,
  sessionId: string | undefined,
  products: OnlineProduct[]
) {
  let url: string
  if (market === "CN") {
    const productsToHandover: SmpHandoverItem[] = products.flatMap(
      (product) => {
        if (isArticle(product) || !product.info.isSplit) {
          return {
            id: product.info.no,
            quantity: product.quantity,
            itemType: "ART",
          }
        }

        // If all the child items are OnlineProducts, then we can handover the parent product
        if (product.info.totalChildItems === product.info.childItems.length) {
          return {
            id: product.info.no,
            quantity: product.quantity,
            itemType: "SPR",
          }
        }

        return product.info.childItems.map((item) => ({
          id: item.info.no,
          quantity: item.quantity,
          itemType: "ART",
        }))
      }
    )

    url = smpMultipleHandoverUrl(productsToHandover)
  } else {
    const params = webHandoverSearchParams(storeNo, ukid, sessionId, products)
    url =
      encodeURI(
        process.env.IKEA_WEB_ENDPOINT +
          `/${market.toLocaleLowerCase()}/${language}/shoppingcart/`
      ) + `?${params}`
  }
  sendHandoverToOnlineEvent()
  window.open(url)
}

/** Generate URL for WeChat Shoppable Mini Program (SMP) for shopping list handover */
const smpMultipleHandoverUrl = (products: SmpHandoverItem[]): string => {
  if (
    !process.env.SMP_HANDOVER_IKEAAPP ||
    !process.env.SMP_MULTIPLE_HANDOVER_QR
  ) {
    throw new Error("Missing environment variables for multiple SMP handover")
  }
  const productsToHandover = products
    .map((product) => {
      if (product.itemType === "ART") {
        return `${product.id}-${product.quantity}`
      } else {
        return `s${product.id}-${product.quantity}`
      }
    })
    .join(",")

  return `${process.env.SMP_MULTIPLE_HANDOVER_QR}?products=${encodeURIComponent(
    productsToHandover
  )}`
}

/**
 * Search params to provide to web when doing handover to web
 */
const webHandoverSearchParams = (
  storeNo: string | undefined,
  kioskId: string | undefined,
  sessionId: string | undefined,
  products?: OnlineProduct[]
) => {
  const params = new URLSearchParams()
  if (storeNo) params.append("local_store", storeNo)
  params.append("store_solution", "upptacka_mobile")
  params.append("event_category", "store_handover")
  if (kioskId) params.append("kiosk_id", kioskId)
  if (sessionId) params.append("session_id", sessionId)
  if (products && products.length) {
    const items = products
      .map((p) =>
        isArticle(p) || !p.info.isSplit
          ? `${p.info.no}:${p.quantity}`
          : p.info.childItems.map((item) => `${item.info.no}:${item.quantity}`)
      )
      .join(",")

    params.append("items", items)
  }

  return params
}
