import { HIDE_HANDOVER_TO_ONLINE } from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import {
  OnlineProduct,
  OnlineSalesProduct,
} from "@/types/product/categorizedProduct"
import { isArticle } from "@/types/product/listProduct"
import Button from "@ingka/button"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import ListSection from "../list/ListSection"
import ProductCard from "../list/product-card/ProductCard"
import { useIsOnline } from "@/hooks/useIsOnline"
import { onlineHandover } from "@/utils/webHandover"
import { useListig } from "@/hooks/useListig"

interface OnlineSectionProps {
  products: OnlineProduct[]
}

const OnlineSection: React.FC<OnlineSectionProps> = (props) => {
  const { t } = useTranslation()
  const { market, language } = useLocale()
  const { isOnline } = useIsOnline()
  const { session } = useListig()

  const onlineProductsCount = getOnlineProductsCount(props.products)

  return (
    <ListSection
      title={t("Interstitial-pickup-online")}
      subTitle={t(
        props.products.length <= 1 ? "common.item" : "common.item_plural",
        {
          count: props.products.length,
        },
      )}
    >
      <div className="flex justify-between py-8 px-6 bg-white rounded-t-md">
        <p className="text-base">{t("instore.order-ikea-com-info")}</p>
      </div>
      {props.products.map((product) => (
        <div key={product.info.no} className="mt-0.5">
          {isArticle(product) || !product.info.isSplit ? (
            <ProductCard
              key={product.info.no}
              title={product.info.name}
              image={product.media.mainImage}
              offers={product.offers}
              description={product.info.mediumDescription}
              artNo={product.info.no}
              price={product.price}
              quantity={product.quantity}
              currency={product.price.currency}
              isAvailableForDelivery={product.stock.homeDelivery.inRange}
              energyLabel={product.media.energyLabelImage}
              type="ONLINE"
              isChildItem={false}
              product={product}
            />
          ) : (
            product.info.childItems.map((item) => (
              <ProductCard
                key={item.info.no}
                title={item.info.name}
                image={item.media.mainImage}
                offers={item.offers}
                description={item.info.mediumDescription}
                partiallyAvailableWarning={getPartiallyAvailableText(
                  product,
                  t,
                )}
                artNo={item.info.no}
                price={item.price}
                quantity={item.quantity}
                currency={item.price.currency}
                isAvailableForDelivery={product.stock.homeDelivery.inRange}
                energyLabel={product.media.energyLabelImage}
                type="ONLINE"
                isChildItem={true}
                product={product}
              />
            ))
          )}
        </div>
      ))}
      {market &&
        language &&
        !isOnline &&
        !HIDE_HANDOVER_TO_ONLINE.includes(market) && (
          <div className="flex py-8 px-6 bg-white mt-0.5 rounded-b-md">
            <Button
              data-cy="order-online-button"
              className="flex-1"
              type="secondary"
              text={t("common.order-x-items-online", {
                count: onlineProductsCount,
              })}
              onClick={() =>
                onlineHandover(
                  market,
                  language,
                  session.businessUnitCode,
                  session.source?.type === "kiosk"
                    ? session.source.ukid
                    : undefined,
                  session.listId,
                  props.products,
                )
              }
            />
          </div>
        )}
    </ListSection>
  )
}

export default OnlineSection

function getPartiallyAvailableText(
  product: OnlineSalesProduct,
  t: TFunction<"translation", undefined>,
) {
  return product.info.isSplit
    ? t("Interstitial-ProductListCard-collect-full-serve-of-split-spr")
    : undefined
}

function getOnlineProductsCount(products: OnlineProduct[]): number {
  return products.reduce((acc, curr) => {
    if (isArticle(curr) || !curr.info.isSplit) {
      return acc + 1
    } else {
      return acc + curr.info.childItems.length
    }
  }, 0)
}
