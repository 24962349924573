import { useLocale } from "@/hooks/useLocale"
import { getOneTrustConsents } from "@/utils/getOneTrustConsents"
import { useCallback, useEffect, useState } from "react"

/** ms between retries on load */
const INIT_INTERVAL = 50

export const useCookieConsent = () => {
  const { market } = useLocale()
  const [, setState] = useState(0)
  /** trigger a rerender */
  const forceRerender = useCallback(() => setState((v) => v + 1), [])

  const addCallback = useCallback(() => {
    if (window.Optanon) {
      // force a rerender when cookie consent is changed
      window.Optanon.OnConsentChanged(forceRerender)
    } else {
      // if cookie script hasn't been attached, try againg later
      setTimeout(addCallback, INIT_INTERVAL)
    }
  }, [forceRerender])

  useEffect(() => {
    addCallback()
  }, [addCallback])

  const cookieGroups = getOneTrustConsents(market)

  return {
    isNecessaryEnabled: cookieGroups[1].hasConsent,
    isPerformanceEnabled: cookieGroups[2].hasConsent,
    isFunctionalEnabled: cookieGroups[3].hasConsent,
    isTargetingEnabled: cookieGroups[4].hasConsent,
  }
}
