import { useLocale } from "@/hooks/useLocale"
import {
  PackageInformation,
  UArticle,
  UChildProduct,
} from "@/types/responses/find"
import Accordion, { AccordionItem } from "@ingka/accordion"
import { useTranslation } from "react-i18next"
import { ProductChildItem } from "./ProductChildItem"
import { PackageDimensionList, ScoProductDimensionList } from "./DimensionList"
import { sendPipButtonTapEvent } from "@/analytics/events/sendPipButtonTapEvent"
import { Dimension, InterPTagItem } from "@/types/responses/ptag"
import { isMetric } from "@/utils/locale"

const securityTypeHeaderNo = "00134"

type Props = {
  product: InterPTagItem
  childItems: UChildProduct[]
}

export function ScoProductDescriptionAccordion({ product, childItems }: Props) {
  const { t } = useTranslation()
  const { market } = useLocale()

  const materials = product.customerMaterialLocal
  const goodToKnows = product.goodToKnowLocal?.filter(
    (item) => item.goodToKnowHeaderNo !== securityTypeHeaderNo,
  )
  const warningTexts = product.goodToKnowLocal?.filter(
    (item) => item.goodToKnowHeaderNo === securityTypeHeaderNo,
  )

  return (
    <div className="product-description-accordion">
      <Accordion collapsible={false}>
        {product.measureLocal && (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.itemNo, "measurements")
            }
            key="Product Dimensions"
            id="Product Dimensions"
            title={t("ProductDescriptionAccordion-title-product-dimensions")}
          >
            <RenderProductDimensions
              productDimensions={product.measureLocal}
              isMetric={isMetric(market)}
            />
          </AccordionItem>
        )}

        {goodToKnows && (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.itemNo, "good_to_know")
            }
            key="Good to know"
            id="Good to know"
            title={t("mobile.good-to-know.title")}
          >
            <RenderGoodToKnows goodToKnows={goodToKnows} />
          </AccordionItem>
        )}

        {materials && (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.itemNo, "material")
            }
            key="Materials & Care"
            id="Materials & Care"
            title={t("ProductDescriptionAccordion-title-materials-care")}
          >
            <RenderMaterialInformation materials={materials} />
          </AccordionItem>
        )}

        {!!warningTexts && warningTexts.length > 0 && (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.itemNo, "security")
            }
            key="Warnings"
            id="Warnings"
            title={t("ProductDescriptionAccordion-title-warnings")}
          >
            <RenderGoodToKnows goodToKnows={warningTexts} />
          </AccordionItem>
        )}

        {childItems && childItems.length > 1 && (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.itemNo, "package_info")
            }
            key="Package Information"
            id="Package Information"
            title={t("ProductDescriptionAccordion-title-package-info")}
          >
            <RenderSPRPackageInformation sprPackageInformation={childItems} />
          </AccordionItem>
        )}

        {childItems &&
          childItems.length === 1 &&
          childItems[0]?.info.packaging && (
            <AccordionItem
              onHeadingClicked={() =>
                sendPipButtonTapEvent(product.itemNo, "package_info")
              }
              key="Package Dimensions"
              id="Package Dimensions"
              title={t("ProductDescriptionAccordion-title-package-dimensions")}
            >
              <RenderPackageDimensions
                packageDimensions={childItems[0]?.info.packaging}
              />
            </AccordionItem>
          )}
      </Accordion>
    </div>
  )
}

function RenderMaterialInformation({
  materials,
}: {
  materials: {
    materialPartText: string
    materialText: string
    sortNo: string
    registerDate: string
    updateDate: string
  }[]
}) {
  const { t } = useTranslation()

  return (
    <div className="product-materials-text text-sm text-text-and-icon-1">
      <span className="text-base font-bold">{t("mobile.material.title")}</span>
      {materials?.map((detail, index) => (
        <div className="mb-2 mt-2" key={detail.materialText + "" + index}>
          <div className="text-text-and-icon-1 font-bold">
            {detail.materialPartText}
          </div>
          <span className="text-text-and-icon-4">{detail.materialText}</span>
        </div>
      ))}
    </div>
  )
}

function RenderPackageDimensions({
  packageDimensions,
}: {
  packageDimensions: PackageInformation
}) {
  const { market } = useLocale()

  return (
    <ul>
      <PackageDimensionList
        dimensions={packageDimensions.packages}
        market={market}
      />
    </ul>
  )
}

function RenderProductDimensions({
  isMetric,
  productDimensions,
}: {
  productDimensions: Dimension[]
  isMetric: boolean
}) {
  return (
    <ul>
      <ScoProductDimensionList
        dimensions={productDimensions}
        isMetric={isMetric}
      />
    </ul>
  )
}

function RenderGoodToKnows({
  goodToKnows,
}: {
  goodToKnows:
    | {
        goodToKnowNo: string
        goodToKnowText: string
        goodToKnowHeaderNo: string
        goodToKnowHeaderText: string
        goodToKnowTypeNo: string
        goodToKnowTypeName: string
        sortNo: string
        registerDate: string
        updateDate: string
      }[]
    | undefined
}) {
  return (
    <>
      {goodToKnows?.map((item, index) => (
        <p key={item.goodToKnowNo + index} className="text-sm">
          {item.goodToKnowText}
        </p>
      ))}
    </>
  )
}

function RenderSPRPackageInformation({
  sprPackageInformation,
}: {
  sprPackageInformation: (UChildProduct | UArticle)[]
}) {
  return (
    <ul>
      {sprPackageInformation.map((childItem) => (
        <ProductChildItem
          key={childItem.info.no}
          productName={childItem.info.name}
          productDescription={childItem.info.type}
          itemNo={childItem.info.no}
          packagesDimensions={childItem.info.packaging.packages}
        />
      ))}
    </ul>
  )
}
