import Status from "@ingka/status"
import { useTranslation } from "react-i18next"

interface ProductAvailableForDeliveryProps {
  isAvailableForDelivery: boolean
}

export function ProductAvailableForDelivery({
  isAvailableForDelivery,
}: ProductAvailableForDeliveryProps) {
  const { t } = useTranslation()

  return (
    <Status
      small
      statusColor={isAvailableForDelivery ? "green" : "red"}
      label={
        isAvailableForDelivery
          ? t("ProductAvailableForDelivery")
          : t("ProductNotAvailableForDelivery")
      }
    />
  )
}
