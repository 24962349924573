import Button from "@ingka/button"
import { SVGProps } from "react"

export const IconButtonWithBadge: React.FC<{
  badge?: number
  iconPath: (prefix?: string) => SVGProps<SVGElement>[]
  onIconClick?: () => void
}> = (props) => (
  <div className="relative">
    {props.badge ? (
      <svg
        data-testid="badge-container"
        className="absolute h-[18px] w-[18px] right-0 top-0 z-50"
      >
        <circle cx="9" cy="9" r="9" fill="#0058A3" />
        <text
          textAnchor="middle"
          alignmentBaseline="central"
          x="50%"
          y="50%"
          fill="white"
          fontSize="12px"
        >
          {props.badge}
        </text>
      </svg>
    ) : null}
    <Button
      iconOnly
      ssrIcon={props.iconPath}
      type="tertiary"
      small
      onClick={props.onIconClick}
    />
  </div>
)
