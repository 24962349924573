import { usePickUpNumber } from "@/hooks/usePickUpNumber"
import PickupNumber from "./PickupNumber"
import FullServeStatusText from "./FullServeStatusText"
import SSRIcon from "@ingka/ssr-icon"
import arrowRight from "@ingka/ssr-icon/paths/arrow-right"
import { getFullServeStatus } from "@/utils/getFullServeStatus"
import classNames from "classnames"
import OrderCompleted from "./OrderCompleted"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { OrderView } from "./OrderView"
import { motion } from "framer-motion"
import { BaseImage } from "@/types/responses/find"
import Loading from "@/components/common/Loading"
import { useLoadingSteps } from "@/hooks/useLoadingSteps"
import checkIcon from "@ingka/ssr-icon/paths/checkmark"
import { sendOrderDetailsCloseEvent } from "@/analytics/events/sendOrderDetailsCloseEvent"
import { sendOrderDetailsOpenEvent } from "@/analytics/events/sendOrderDetailsOpenEvent"
import { FullScreenModal } from "../modals/FullscreenModal"
import { ListigOrder } from "@/types/listig"
import { useCcOrder } from "@/hooks/useCcOrder"
import { InstoreOrder } from "@/types/order"

const MAX_THUMBNAILS = 4

const Thumbnail: React.FC<{ image?: BaseImage; text?: string }> = ({
  image,
  text,
}) => {
  const diameter = 72
  const imgSize = Math.round(Math.sqrt(2) * (diameter / 2))

  return (
    <div
      className={`flex justify-center items-center w-[${diameter}px] h-[${diameter}px] rounded-full overflow-hidden mr-3 last:mr-0 border-[1px] border-solid border-gray-200`}
    >
      {image && (
        <img
          style={{
            width: imgSize + "px",
            height: imgSize + "px",
          }}
          src={image.href}
          alt={image.alt}
        />
      )}
      {text && <div className="text-black font-normal text-sm">{text}</div>}
    </div>
  )
}

interface OrderCardProps {
  listigOrder: ListigOrder
  isOrderCreationLoading: boolean
  onCancelOrder: (order: InstoreOrder) => void
}

export const OrderCard: React.FC<OrderCardProps> = ({
  listigOrder,
  isOrderCreationLoading,
  onCancelOrder,
}) => {
  const { order } = useCcOrder(listigOrder)
  const { t } = useTranslation()
  const { pickupNumber } = usePickUpNumber(order?.orderNo)
  const fullServeStatus = getFullServeStatus(
    !!order?.isPaid,
    order?.pickingStatus,
  )
  const [showOrderView, setShowOrderView] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [showOrderCreated, setShowOrderCreated] = useState<boolean>(false)

  const loadingSteps = [
    {
      completionStep: !loading,
      loadingText: t("mobile.placing-order"),
    },
    {
      completionStep: !!pickupNumber,
      loadingText: t("common.loading"),
    },
  ]
  const { steps, completed } = useLoadingSteps(loadingSteps, true)

  const onClose = () => {
    sendOrderDetailsCloseEvent(order?.orderNo)
    setShowOrderView(false)
  }

  // Show loading view for the first 2 seconds after an order has been created, then show order created confirmation for 1 second
  useEffect(() => {
    if (isOrderCreationLoading) {
      setTimeout(() => {
        setLoading(false)
        setShowOrderCreated(true)

        setTimeout(() => setShowOrderCreated(false), 1000)
      }, 2000)
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Latest added products should be displayed first
  const reversedProducts = [...(order?.items ?? [])].reverse()

  const thumbnailsToShow =
    reversedProducts.length > MAX_THUMBNAILS
      ? MAX_THUMBNAILS - 1
      : MAX_THUMBNAILS
  const thumbnailsToHide = reversedProducts.length - thumbnailsToShow

  const productsToShow = reversedProducts.slice(0, thumbnailsToShow)

  return (
    <div>
      {order && (
        <FullScreenModal showing={showOrderView} onClose={onClose}>
          <OrderView
            order={order}
            onClose={onClose}
            onCancelOrder={() => {
              onCancelOrder(order)
              setShowOrderView(false)
            }}
          />
        </FullScreenModal>
      )}

      <div
        data-cy="order-card"
        className="rounded bg-white"
        onClick={() => {
          sendOrderDetailsOpenEvent(order?.orderNo)
          setShowOrderView(true)
        }}
      >
        <div className="flex flex-col items-start p-6 space-y-4">
          <div className="flex flex-wrap">
            {productsToShow.map((product) => (
              <Thumbnail
                key={product.info.no}
                image={product.media.mainImage}
              />
            ))}
            {thumbnailsToHide > 0 && (
              <Thumbnail text={`+${thumbnailsToHide}`} />
            )}
          </div>

          <div className="flex flex-col space-y-2 w-full">
            {order?.pickingStatus === "COMPLETED" ? (
              <div className="h-[50px] flex items-end">
                <OrderCompleted orderCreationDate={order.orderCreationDate} />
              </div>
            ) : completed ? (
              showOrderCreated ? (
                <motion.div
                  animate={{ opacity: "100%" }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="flex flex-col items-center mb-10">
                    <SSRIcon
                      paths={checkIcon}
                      className="h-14 w-14 text-blue-brand"
                    />
                    <p className="text-base">{t("mobile.order-created")}</p>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  animate={{ opacity: "100%" }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <PickupNumber
                    pickupNumber={pickupNumber}
                    pickingStatus={order?.pickingStatus}
                    location="left"
                    showOnlyNumber
                  />
                  <FullServeStatusText status={fullServeStatus} />
                </motion.div>
              )
            ) : (
              <Loading type="step" steps={steps} className="mb-10" />
            )}
          </div>
        </div>

        {completed && !showOrderCreated && (
          <motion.div
            className={classNames("flex justify-between items-center p-6", {
              "rounded-b bg-blue-brand text-white": !order?.isPaid,
              "border-t-[1px] border-gray-200 text-black": order?.isPaid,
            })}
            animate={{ opacity: "100%" }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
          >
            <div className="space-x-2">
              <span className="font-bold">
                {order?.isPaid
                  ? t("mobile.your-order")
                  : t("Interstitial-pay-for-order")}
              </span>
              <span>#{order?.orderNo}</span>
            </div>
            <SSRIcon paths={arrowRight} />
          </motion.div>
        )}
      </div>
    </div>
  )
}
