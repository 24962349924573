import { useTranslation } from "react-i18next"
import {
  FullServeProduct,
  FullServeSalesProduct,
  isFullServeSalesProduct,
} from "@/types/product/categorizedProduct"
import { isInStockSalesProduct } from "@/types/product/listProduct"
import ProductCard from "../list/product-card/ProductCard"
import { TFunction } from "i18next"

interface FullServeProductsProps {
  products: FullServeProduct[]
  isOrderCreated: boolean
}

const FullServeProducts: React.FC<FullServeProductsProps> = (props) => {
  const { t } = useTranslation()

  return (
    <>
      {props.products.map((product) => (
        <div key={product.info.no} className="my-0.5">
          {isFullServeSalesProduct(product) &&
          (product.info.isSplit || !isInStockSalesProduct(product)) ? (
            <div id={"FULL_SERVE_" + product.info.no} className="">
              <ProductCard
                title={
                  props.isOrderCreated
                    ? product.quantity.toString() + " X " + product.info.name
                    : product.info.name
                }
                image={product.media.mainImage}
                description={product.info.mediumDescription}
                offers={product.offers}
                price={product.price}
                quantity={product.quantity}
                currency={product.price.currency}
                availability={product.stock.cashCarry}
                artNo={product.info.no}
                type={"SPR_PARENT"}
                salesMethod="FULL_SERVE"
                hidePrice={false}
                partiallyAvailableWarning={getPartiallyAvailableText(
                  product,
                  t,
                )}
                isOrdered={props.isOrderCreated}
                product={product}
                energyLabel={product.media.energyLabelImage}
              />
            </div>
          ) : isFullServeSalesProduct(product) && props.isOrderCreated ? (
            <div key={product.info.no} id={"FULL_SERVE_" + product.info.no}>
              <ProductCard
                title={
                  props.isOrderCreated
                    ? product.quantity.toString() + " X " + product.info.name
                    : product.info.name
                }
                image={product.media.mainImage}
                description={product.info.mediumDescription}
                offers={product.offers}
                price={product.price}
                quantity={product.quantity}
                currency={product.price.currency}
                availability={product.stock.cashCarry}
                artNo={product.info.no}
                type={"SPR_PARENT"}
                salesMethod="FULL_SERVE"
                isOrdered={props.isOrderCreated}
                product={product}
                energyLabel={product.media.energyLabelImage}
              />
            </div>
          ) : (
            <ProductCard
              key={product.info.no}
              artNo={product.info.no}
              title={
                props.isOrderCreated
                  ? product.quantity.toString() + " X " + product.info.name
                  : product.info.name
              }
              offers={product.offers}
              price={product.price}
              quantity={product.quantity}
              currency={product.price.currency}
              availability={product.stock.cashCarry}
              image={product.media.mainImage}
              description={product.info.mediumDescription}
              type="FULL_SERVE"
              salesMethod="FULL_SERVE"
              isOrdered={props.isOrderCreated}
              product={product}
              energyLabel={product.media.energyLabelImage}
            />
          )}
        </div>
      ))}
    </>
  )
}

export default FullServeProducts

function getPartiallyAvailableText(
  product: FullServeSalesProduct,
  t: TFunction<"translation", undefined>,
) {
  return product.info.isSplit
    ? t("Interstitial-ProductListCard-collect-full-serve-of-split-spr")
    : undefined
}
