import { dateWithoutTime } from "./isToday"

type ActiveList = {
  listId: string
  time: number
}

const activeListKey = "active_list"

/**
 * Save the current active list in local storage.
 */
export function setActiveList(listId: string, createdAt?: string) {
  const activeList = getLocalStorageActiveList()
  if (activeList.listId === listId) return

  localStorage.setItem(
    activeListKey,
    JSON.stringify({ listId: listId, time: dateWithoutTime(createdAt) }),
  )
}

/**
 * Fetch the current active list from local storage if the list is created today.
 */
export function getActiveList(): string | undefined {
  const activeList = getLocalStorageActiveList()
  const createdToday = activeList.time === dateWithoutTime()

  return createdToday ? activeList.listId : undefined
}

/**
 * Check if list with listId exists in local storage and is NOT created today.
 */
export function isExpired(listId: string): boolean {
  const activeList = getLocalStorageActiveList()
  const notCreatedToday = activeList.time !== dateWithoutTime()

  return activeList.listId === listId && notCreatedToday
}

const getLocalStorageActiveList = (): ActiveList => {
  const stringData = localStorage.getItem(activeListKey)
  return (stringData && JSON.parse(stringData)) || {}
}
