import { useLocale } from "@/hooks/useLocale"
import { isNotProductError, isUSalesProduct } from "@/types/product/products"
import {
  DetailedInformation,
  Measurement,
  PackageInformation,
  TechnicalCompliance,
  TechnicalInformationPart,
  UArticle,
  UChildProduct,
  UProduct,
} from "@/types/responses/find"
import Accordion, { AccordionItem } from "@ingka/accordion"
import { useTranslation } from "react-i18next"
import { ProductChildItem } from "./ProductChildItem"
import { PackageDimensionList, ProductDimensionList } from "./DimensionList"
import {
  DO_NOT_DIY_MARKETS,
  ENABLED_WOOD_INFO_MARKETS,
} from "@/config/constants"
import { sendPipButtonTapEvent } from "@/analytics/events/sendPipButtonTapEvent"

type Props = {
  product: UProduct
  isDiyAccordionOpen: boolean
}

export function ProductDescriptionAccordion({
  product,
  isDiyAccordionOpen,
}: Props) {
  const { t } = useTranslation()
  const { market } = useLocale()

  const careInstructions = product.info.detailedInformation.careInstructions
  const materials = product.info.detailedInformation.materials
  const complianceInfo = product.info.detailedInformation.complianceInformation
  const goodToKnows = product.info.detailedInformation.goodToKnows
  const productDimensions = product.info.measurements
  const packageDimensions = isUSalesProduct(product)
    ? undefined
    : product.info.packaging
  const sprPackageInformation = isUSalesProduct(product)
    ? product.info.childItems?.filter(isNotProductError)
    : undefined
  const technicalInformation =
    product.info.detailedInformation.technical?.information?.[0]?.parts

  const technicalCompliance =
    product.info.detailedInformation.technical?.compliance?.[0]

  const noDiy = product.media.images.find(
    (image) => image.type === "DO_NOT_DIY_SYMBOL",
  )

  const warningTexts = product.info.detailedInformation.goodToKnows
    ?.filter((i) => i.type === "Warning")
    .map((warning) => warning.text)
    .filter(
      (warning: string | undefined): warning is string => warning !== undefined,
    )

  const measurementImage = product.media.images.find(
    (img) => img.type === "MEASUREMENT_ILLUSTRATION",
  )?.href

  return (
    <div className="product-description-accordion">
      <Accordion collapsible={false}>
        {productDimensions ? (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.info.no, "measurements")
            }
            key="Product Dimensions"
            id="Product Dimensions"
            title={t("ProductDescriptionAccordion-title-product-dimensions")}
          >
            <RenderProductDimensions productDimensions={productDimensions} />
            {measurementImage ? (
              <img alt={product.info.name} src={measurementImage} />
            ) : null}
          </AccordionItem>
        ) : (
          <></>
        )}

        {goodToKnows ? (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.info.no, "good_to_know")
            }
            key="Good to know"
            id="Good to know"
            title={t("mobile.good-to-know.title")}
          >
            <RenderGoodToKnows goodToKnows={goodToKnows} />
          </AccordionItem>
        ) : (
          <></>
        )}

        {materials ? (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.info.no, "material")
            }
            key="Materials & Care"
            id="Materials & Care"
            data-cy="materials-and-care"
            title={t("ProductDescriptionAccordion-title-materials-care")}
          >
            <RenderMaterialInformation materials={materials} />
            {careInstructions && (
              <RenderCareInstructions careInstructions={careInstructions} />
            )}
            {ENABLED_WOOD_INFO_MARKETS.includes(market) &&
              complianceInfo?.[0]?.type === "SOLID_WOOD_DECLARATION" &&
              complianceInfo[0].sections.length > 0 && (
                <WoodInformation
                  complianceInfo={complianceInfo[0].sections[0]?.values}
                />
              )}
          </AccordionItem>
        ) : (
          <></>
        )}
        {!!warningTexts && warningTexts.length > 0 ? (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.info.no, "security")
            }
            key="Warnings"
            id="Warnings"
            title={t("ProductDescriptionAccordion-title-warnings")}
          >
            <RenderWarningTexts warningTexts={warningTexts} />
          </AccordionItem>
        ) : (
          <></>
        )}

        {technicalCompliance || technicalInformation ? (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.info.no, "technical")
            }
            key="Technical Information"
            id="Technical Information"
            data-cy="technical-information"
            title={t("ProductDescriptionAccordion-title-technical-info")}
            open={isDiyAccordionOpen}
          >
            {technicalInformation && (
              <RenderTechnicalInformation
                technicalInformation={technicalInformation}
              />
            )}

            {technicalCompliance && (
              <>
                <RenderTechnicalCompliance
                  techicalCompliance={technicalCompliance}
                />

                {noDiy && DO_NOT_DIY_MARKETS.includes(market.toUpperCase()) && (
                  <>
                    <hr className="border-gray-200 my-8" />
                    <div className="flex justify-center items-center">
                      <img
                        key={noDiy.id}
                        alt={noDiy.alt}
                        src={noDiy.href}
                        data-cy="no-diy-image"
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </AccordionItem>
        ) : (
          <></>
        )}

        {sprPackageInformation && sprPackageInformation.length > 0 ? (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.info.no, "package_info")
            }
            key="Package Information"
            id="Package Information"
            title={t("ProductDescriptionAccordion-title-package-info")}
          >
            <RenderSPRPackageInformation
              sprPackageInformation={sprPackageInformation}
            />
          </AccordionItem>
        ) : (
          <></>
        )}
        {packageDimensions ? (
          <AccordionItem
            onHeadingClicked={() =>
              sendPipButtonTapEvent(product.info.no, "package_info")
            }
            key="Package Dimensions"
            id="Package Dimensions"
            title={t("ProductDescriptionAccordion-title-package-dimensions")}
          >
            <RenderPackageDimensions packageDimensions={packageDimensions} />
          </AccordionItem>
        ) : (
          <></>
        )}
      </Accordion>
    </div>
  )
}

interface RenderTechnicalInformationProps {
  technicalInformation: TechnicalInformationPart[]
}

function RenderTechnicalInformation({
  technicalInformation,
}: RenderTechnicalInformationProps) {
  const technicalTexts = technicalInformation.map((element) => (
    <tr key={element.title}>
      <td className="w-2/5 px-4 py-2 text-gray-900">{element.title}</td>
      <td className="w-2/5 px-4 py-2 text-gray-900">{element.value}</td>
    </tr>
  ))

  if (technicalTexts) {
    return (
      <table>
        <tbody className="technical-information">{technicalTexts}</tbody>
      </table>
    )
  }
}

interface RenderTechnicalComplianceProps {
  techicalCompliance: TechnicalCompliance
}

function RenderTechnicalCompliance({
  techicalCompliance,
}: RenderTechnicalComplianceProps) {
  return (
    <table>
      <tbody className="technical-information">
        <tr key={techicalCompliance.heading}>
          <td
            className="w-2/5 px-4 py-2 text-gray-900"
            data-cy="compliance-heading"
          >
            {techicalCompliance.heading}
          </td>
          <td
            className="w-2/5 px-4 py-2 text-gray-900"
            data-cy="compliance-value"
          >
            {techicalCompliance.value}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

function RenderMaterialInformation({
  materials,
}: {
  materials: NonNullable<DetailedInformation["materials"]>
}) {
  const { t } = useTranslation()

  return (
    <div className="product-materials-text text-sm text-text-and-icon-1">
      <span className="text-base font-bold">{t("mobile.material.title")}</span>
      {materials[0]?.partMaterials?.map((detail) => (
        <div className="my-2" key={detail.materialText}>
          <div className="text-text-and-icon-1 font-bold">
            {detail.partText}
          </div>
          <span className="text-text-and-icon-4">{detail.materialText}</span>
        </div>
      ))}
    </div>
  )
}

function RenderWarningTexts({ warningTexts }: { warningTexts: string[] }) {
  return (
    <>
      {warningTexts.map((warning) => (
        <p key={warning} className="text-sm">
          {warning}
        </p>
      ))}
    </>
  )
}

function WoodInformation({ complianceInfo }: { complianceInfo?: string[] }) {
  const { t } = useTranslation()
  const { language } = useLocale()
  let woodDeclarationUrl: string

  // It's a legal requirement in Switzerland to give access to customers to read about the wood declarations
  if (language === "fr") {
    woodDeclarationUrl = "http://www.declaration-du-bois.ch"
  } else if (language === "it") {
    woodDeclarationUrl = "http://www.dichiarazione-del-legno.ch"
  } else {
    woodDeclarationUrl = "http://www.holzdeklaration.ch"
  }

  return (
    <>
      <h4 className="mt-8 text-sm" data-cy="wood-declaration-header">
        {t("ProductDescriptionAccordion-wood-declaration")}
      </h4>
      <p className="mt-4 mb-4 text-sm" data-cy="wood-declaration-text">
        {t("ProductDescriptionAccordion-wood-declaration-link")}
      </p>
      <a
        href={encodeURI(woodDeclarationUrl)}
        target="_blank"
        rel="noreferrer noopener"
        className="underline"
        data-cy="wood-declaration-read-more"
      >
        {t("common.read-more")}
      </a>
      <RenderComplianceInfo complianceInfo={complianceInfo} />
    </>
  )
}

function RenderComplianceInfo({
  complianceInfo,
}: {
  complianceInfo?: string[]
}) {
  const { t } = useTranslation()

  return (
    <div className="mt-6 capitalize">
      {complianceInfo?.map((info) => {
        const values = info.split(" : ")
        const title = values[0]?.replace(/;/g, " ")
        const description = values[1]?.toLowerCase()

        return (
          <>
            <p key={title} className="m-0 text-sm">
              {title}
            </p>
            <p key={description} className="mb-4 text-gray-400 text-sm">
              {`${t("common.origin")}: ${description}`}
            </p>
          </>
        )
      })}
    </div>
  )
}

function RenderCareInstructions({
  careInstructions,
}: {
  careInstructions: NonNullable<DetailedInformation["careInstructions"]>
}) {
  const { t } = useTranslation()
  const header = careInstructions[0]?.careInstructionHeader

  return (
    <div className="mt-8 text-xs">
      <span className="text-base font-bold">{t("mobile.care.title")}</span>
      {careInstructions.map(
        (careText, index) =>
          careText.careInstructionTexts && (
            <p key={index}>
              {header && (
                <span key={index} className="font-bold text-sm">
                  {" " + careText.careInstructionHeader + ": "}
                  <br />
                </span>
              )}
              {careText.careInstructionTexts.map((text, i) => (
                <span key={i} className="text-sm">
                  {text.careInstructionText}{" "}
                </span>
              ))}
            </p>
          ),
      )}
    </div>
  )
}

function RenderPackageDimensions({
  packageDimensions,
}: {
  packageDimensions: PackageInformation
}) {
  const { market } = useLocale()

  return (
    <ul>
      <PackageDimensionList
        dimensions={packageDimensions.packages}
        market={market}
      />
    </ul>
  )
}

function RenderProductDimensions({
  productDimensions,
}: {
  productDimensions: Measurement[]
}) {
  return (
    <ul>
      <ProductDimensionList dimensions={productDimensions} />
    </ul>
  )
}

function RenderGoodToKnows({
  goodToKnows,
}: {
  goodToKnows:
    | {
        text?: string
        type?: string
      }[]
    | undefined
}) {
  return (
    <>
      {goodToKnows?.map((item) => (
        <p key={item.text} className="text-sm">
          {item.text}
        </p>
      ))}
    </>
  )
}

function RenderSPRPackageInformation({
  sprPackageInformation,
}: {
  sprPackageInformation: (UChildProduct | UArticle)[]
}) {
  return (
    <ul>
      {sprPackageInformation.map((childItem) => (
        <ProductChildItem
          key={childItem.info.no}
          productName={childItem.info.name}
          productDescription={childItem.info.type}
          itemNo={childItem.info.no}
          packagesDimensions={childItem.info.packaging.packages}
        />
      ))}
    </ul>
  )
}
