import { APP_VERSION, isEnvironment, SENTRY_DSN } from "@/config/constants"
import { ApiError } from "@/types/apiError"
import { CaptureConsole, ExtraErrorData } from "@sentry/integrations"
import * as Sentry from "@sentry/react"
import { Primitive } from "@sentry/types"
import { BrowserTracing } from "@sentry/browser"

const isSentryConfigured = !!SENTRY_DSN

let isInitialized = false

export function initialize() {
  if (!isEnvironment("LOCALHOST") && isSentryConfigured && !isInitialized) {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: APP_VERSION,
      environment: process.env.ENV,
      autoSessionTracking: true,
      beforeSend: (event, hint) => {
        // add http response code as tag if applicable
        if (hint.originalException instanceof ApiError) {
          if (!event.tags)
            event.tags = { http_status: hint.originalException.statusCode }
          else event.tags.http_status = hint.originalException.statusCode
        }
        return event
      },
      integrations: [
        // Capture console.error as Sentry events
        new CaptureConsole({
          levels: ["error"],
        }),
        new BrowserTracing(
          history
            ? {
                routingInstrumentation:
                  Sentry.reactRouterV5Instrumentation(history),
              }
            : undefined,
        ),
        new ExtraErrorData({
          // Limit of how deep the object serializer should go. Anything deeper than limit will
          // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
          // a primitive value. Defaults to 3.
          depth: 5,
        }),
        new Sentry.Integrations.Breadcrumbs(),
      ],
      // needed to log ExtraErrorData
      normalizeDepth: 6,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.1,
    })
    isInitialized = true
  }
}

export function setUser(user: Sentry.User) {
  if (isInitialized) {
    Sentry.setUser(user)
  }
}

export function setContext(context: string, data: { [k: string]: unknown }) {
  if (isInitialized) {
    Sentry.setContext(context, data)
  }
}

export function setTags(tags: Record<string, Primitive>) {
  if (isInitialized) {
    Sentry.setTags(tags)
  }
}

export function captureException(
  error: Error,
  severity: Sentry.SeverityLevel = "error",
) {
  if (isInitialized) {
    Sentry.captureException(error, { level: severity })
  }
}

export function captureWarning(error: Error) {
  captureException(error, "warning")
}
