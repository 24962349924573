import { ONLINE_STORE } from "@/config/constants"
import { useStoreInformation } from "./useStoreInformation"

/**
 * @returns true if online mode
 */
export function useIsOnline(): {
  isOnline?: boolean
  isLoading: boolean
} {
  const { storeInfo } = useStoreInformation()

  if (ONLINE_STORE) return { isOnline: true, isLoading: false }
  if (!storeInfo) return { isLoading: true }

  return {
    isOnline: "parentStore" in storeInfo && !!storeInfo.parentStore,
    isLoading: false,
  }
}
