import { READY_FOR_PICKUP_STATUSES } from "@/config/constants"
import { CcOrderPickingStatus } from "@/types/responses/buy"
import { LoadingBall } from "@ingka/loading"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

interface Props {
  pickupNumber: string | undefined
  pickingStatus: CcOrderPickingStatus | undefined
  location?: "left" | "center" | "right"
  showOnlyNumber?: boolean
}

const PickupNumber: React.FC<Props> = ({
  pickupNumber,
  pickingStatus,
  location = "center",
  showOnlyNumber,
}) => {
  const { t } = useTranslation()

  const isReadyForPickup =
    pickingStatus && READY_FOR_PICKUP_STATUSES.includes(pickingStatus)

  return (
    <div
      data-cy="pickup-number"
      className={`flex w-full flex-col ${
        location === "center"
          ? "items-center"
          : location === "right"
            ? "items-end"
            : "items-start"
      }`}
    >
      {!showOnlyNumber && (
        <p className="text-sm text-black pb-3">
          {t("OrderNumber-pick-up-number")}
        </p>
      )}
      <h2
        className={classNames("text-4xl", {
          "text-blue-brand": !isReadyForPickup,
          "text-semantic-positive": isReadyForPickup,
        })}
      >
        {pickupNumber ?? <LoadingBall />}
      </h2>
    </div>
  )
}

export default PickupNumber
