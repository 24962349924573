import {
  MULTIPLE_ORDERS_ENABLED,
  MULTIPLE_ORDERS_LOCATIONS,
} from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { isEnabled } from "@/utils/isEnabled"

/**
 * @returns true if multiple orders has been enabled for market/store/kiosk
 */
export function useMultipleOrders() {
  const { market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode, source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined

  return isEnabled({
    market,
    businessUnitCode,
    ukid,
    ENABLED: MULTIPLE_ORDERS_ENABLED,
    ENABLED_LOCATIONS: MULTIPLE_ORDERS_LOCATIONS,
  })
}
