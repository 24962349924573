import { getActiveList, setActiveList } from "@/utils/localStorage"
import { useAuth } from "./useAuth"
import { useListId } from "./useHandoverQuery"
import { useListigQuery } from "./useListigQuery"
import { useLocalNavigation } from "./useLocalNavigation"
import { useListig } from "./useListig"
import { isActive } from "@/utils/listig"
import useVisit from "./useVisit"
import { sendStartSessionEvent } from "@/analytics/events/sendStartSessionEvent"

export const useMergeLists = () => {
  const { navigate } = useLocalNavigation()
  const { setVisitCounter } = useVisit()
  const { oAuthToken } = useAuth()
  const { list: activeList, isLoading, addProducts } = useListig()

  /** List from the url */
  const listIdFromUrl = useListId()?.listId
  const listFromUrl = useListigQuery(listIdFromUrl, oAuthToken)

  /** List from local storage */
  const listIdFromLocalStorage = getActiveList()
  const listFromLocalStorage = useListigQuery(
    listIdFromLocalStorage,
    oAuthToken,
  )

  return {
    mergeLists: () => {
      /**
       * If the lists are loading or if the list from the url and the
       * list from local storage have the same id we do nothing.
       */
      if (
        isLoading ||
        !listIdFromUrl ||
        listIdFromUrl === listIdFromLocalStorage
      )
        return

      sendStartSessionEvent(
        activeList?.metadata.createdBy.system ?? "unknown",
        "list_handover",
        setVisitCounter,
        activeList?.metadata.createdBy.user,
      )

      if (activeList?.id === listIdFromLocalStorage) {
        /**
         * If the list from the url is active we move the products to the list in local storage.
         */
        if (isActive(listFromUrl.list)) {
          addProducts.add(listFromUrl.list?.items ?? [])
        }

        /**
         * Redirect to the active list.
         */
        navigate({
          path: "list",
          listId: activeList?.id,
        })
      }

      /**
       * If the list from local storage is not active and the list form the url is active,
       * we set the list from the url as the new active list in local storage.
       */
      if (!isActive(listFromLocalStorage.list) && listFromUrl.list) {
        setActiveList(listFromUrl.list.id, listFromUrl.list.metadata.createdAt)
      }
    },
  }
}
