import { usePickUpNumber } from "@/hooks/usePickUpNumber"
import { useListig } from "@/hooks/useListig"
import { useStoreInformation } from "@/hooks/useStoreInformation"
import useWindowSize from "@/hooks/useWindowSize"
import { FullServeProduct } from "@/types/product/categorizedProduct"
import Barcode from "react-barcode"
import { useTranslation } from "react-i18next"
import ExternalWarehouseCard from "./ExternalWarehouseCard"
import FullServeProducts from "./FullServeProducts"
import OrderCompleted from "./OrderCompleted"
import PickupNumber from "./PickupNumber"
import OrderStatus from "./OrderStatus"
import { useEffect, useState } from "react"
import { FullServeSurvey } from "./FullServeSurvey"
import useVisit from "@/hooks/useVisit"
import { sendFullServeSurveyEvent } from "@/analytics/events/sendFullServeSurveyEvent"
import { AnimatePresence, motion } from "framer-motion"
import FullServeStatusText from "./FullServeStatusText"
import { getFullServeStatus } from "@/utils/getFullServeStatus"
import { InstoreOrder } from "@/types/order"

const BARCODE_LENGTH = 16

interface FullServeViewProps {
  products: FullServeProduct[]
  order: InstoreOrder
}

export const FullServeView: React.FC<FullServeViewProps> = ({
  order,
  products,
}) => {
  const { t } = useTranslation()
  const { session } = useListig()
  const { pickupNumber } = usePickUpNumber(order.orderNo)
  const { width: windowWidth } = useWindowSize()
  const scaleFactor = (Math.min(windowWidth, 415) * 0.8) / 290
  const { storeInfo } = useStoreInformation()
  const { visited, setVisitedPage } = useVisit()
  const fullServeStatus = getFullServeStatus(order.isPaid, order.pickingStatus)

  /** Fetch external warehouse */
  const externalWarehouse =
    storeInfo &&
    "externalPickupPoint" in storeInfo &&
    storeInfo.externalPickupPoint

  const [showPartialOrderView, setShowPartialOrderView] = useState(false)

  /** If order has pickingStatus it means we can show detailed information about the order status.
   *  If the pickingStatus is not COMPLETED that means the order hasn't been picked up and we can show
   *  detailed information about the current order progress.
   */
  const isNotPickedUp = order.pickingStatus !== "COMPLETED"

  /** If order has been paid for, order amounts to pay should be 0 */
  const isPaidForAndIsntPickedUp = order.isPaid && isNotPickedUp

  useEffect(() => {
    if (order.isPartial) {
      setTimeout(() => {
        setShowPartialOrderView(true)
      }, 60000)
    }

    if (showPartialOrderView && !order?.isPartial) {
      setShowPartialOrderView(false)
    }
  }, [order, showPartialOrderView, setShowPartialOrderView])

  return (
    <div className="flex flex-col divide-y divide-gray-200 bg-white rounded-md">
      <div className="w-full">
        {isNotPickedUp && (
          <div className="mt-6">
            <OrderStatus
              isWaitingForPayment={!order.isPaid}
              pickingStatus={order.pickingStatus}
              showStatus={!showPartialOrderView}
            />
          </div>
        )}
        {isPaidForAndIsntPickedUp && (
          <div className="mt-4 space-y-5 pb-10">
            <PickupNumber
              pickupNumber={pickupNumber}
              pickingStatus={order.pickingStatus}
            />
            {fullServeStatus && (
              <div className="flex justify-center">
                <FullServeStatusText status={fullServeStatus} />
              </div>
            )}
          </div>
        )}
      </div>

      {(!order.isPaid || showPartialOrderView) && (
        <div className="flex flex-col mt-4 mb-6 border-none px-6">
          <p className=" text-base text-black mb-10">
            {t("Interstitial-OrderStatus-payment")}
          </p>
          <div
            data-cy="order-barcode-view"
            className="flex flex-col self-center"
            style={{ transform: `scaleX(${scaleFactor})` }}
          >
            <Barcode
              value={order.orderNo.padStart(BARCODE_LENGTH, "0")}
              width={2}
              height={48}
              // "ITF" is currently not supported by the wrapping library
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              format={"ITF" as any}
              displayValue={false}
              margin={0}
            />
          </div>
          <p className="text-sm m-0 mt-3">
            {t("Interstitial-BarcodeModal-code-label") + " "}
            <span className="font-mono">{order.orderNo}</span>
          </p>
          <p className="text-sm mt-10">{t("mobile.scan-barcode-to-pay")}</p>
        </div>
      )}

      {isPaidForAndIsntPickedUp && (
        <>
          <div
            className="flex flex-col self-center border-none px-6"
            style={{ transform: `scaleX(${scaleFactor})` }}
          >
            <Barcode
              value={order.orderNo.padStart(BARCODE_LENGTH, "0")}
              width={2}
              height={48}
              // "ITF" is currently not supported by the wrapping library
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              format={"ITF" as any}
              displayValue={false}
              margin={0}
            />
          </div>
          <p className="text-sm m-0 mt-3 pb-10 border-none ml-6">
            {t("Interstitial-BarcodeModal-code-label") + " "}
            <span className="font-mono">{order.orderNo}</span>
          </p>
          <p className="text-sm border-none mb-6 mx-6">
            {t("mobile.when-ready-show-barcode")}
          </p>
          <div className="border-none">
            {externalWarehouse && storeInfo && (
              <ExternalWarehouseCard
                externalWarehouse={externalWarehouse}
                origin={{
                  lat: storeInfo.lat,
                  long: storeInfo.lng,
                }}
              />
            )}
          </div>
        </>
      )}

      {showPartialOrderView && (
        <div className="flex flex-col border-none m-6">
          <p className="w-full border-none text-base text-gray-600">
            {t("mobile.collect-pickup-point")}
          </p>
          <p className="w-full text-sm border-none">
            {t("mobile.when-ready-show-barcode")}
          </p>
          <div className="w-full mt-10 mb-6">
            <PickupNumber
              pickupNumber={pickupNumber}
              pickingStatus={order.pickingStatus ?? undefined}
              location="left"
            />
          </div>
          <div className="border-none">
            {externalWarehouse && storeInfo && (
              <ExternalWarehouseCard
                externalWarehouse={externalWarehouse}
                origin={{
                  lat: storeInfo.lat,
                  long: storeInfo.lng,
                }}
              />
            )}
          </div>
        </div>
      )}

      {session.businessUnitCode && order.pickingStatus === "COMPLETED" && (
        <div className="m-6 border-none">
          <AnimatePresence>
            {!visited.fullServeSurvey && (
              <motion.div
                style={{ overflow: "hidden" }}
                initial={{ height: "auto" }}
                animate={{ height: "auto" }}
                transition={{ duration: 0.5, delay: 1 }}
                exit={{ height: 0 }}
                key={"container"}
              >
                <FullServeSurvey
                  onComplete={(rating) => {
                    setVisitedPage("fullServeSurvey")
                    sendFullServeSurveyEvent(rating)
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <OrderCompleted
            orderNumber={order.orderNo}
            orderCreationDate={order.orderCreationDate}
          />
        </div>
      )}

      <div>
        <FullServeProducts products={products} isOrderCreated={!!order} />
      </div>
    </div>
  )
}
