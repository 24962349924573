import Modal, { ModalBody, ModalHeader, Sheets } from "@ingka/modal"
import { ScoProductDescription } from "../pip/ScoProductDescription"
import { UChildProduct } from "@/types/responses/find"
import { InterPTagItem } from "@/types/responses/ptag"

type ScoProductDetailsModalProps = {
  childItems: UChildProduct[]
  product: InterPTagItem
  onClose: () => void
  showing: boolean
}

export const ScoProductDetailsModal: React.FC<ScoProductDetailsModalProps> = ({
  product,
  childItems,
  onClose,
  showing,
}) => {
  return product ? (
    <Modal visible={showing} handleCloseBtn={onClose}>
      <Sheets
        size="large"
        header={<ModalHeader closeBtnClick={onClose} />}
        footer={<div></div>}
      >
        <ModalBody>
          <ScoProductDescription product={product} childItems={childItems} />
        </ModalBody>
      </Sheets>
    </Modal>
  ) : (
    <></>
  )
}
