import { COOKIE_INFO_MARKETS } from "@/config/constants"
import { getOneTrustMarketId } from "@/config/onetrust"
import { useShortId } from "@/hooks/useHandoverQuery"
import { useLocale } from "@/hooks/useLocale"
import handover from "@ingka/ssr-icon/paths/mobile-handover"
import { useTranslation } from "react-i18next"
import Button from "@ingka/button"

export default function SectionFooter(props: {
  className?: string
  onOptionsClick: () => void
}) {
  const { t } = useTranslation()
  const { market } = useLocale()
  const hasOneTrustEnabled = !!market && !!getOneTrustMarketId(market)
  const shortId = useShortId()

  return (
    <div className="text-xs p-6 flex flex-wrap gap-2 justify-between bg-gray-100">
      <div className="flex flex-col gap-6">
        <span className="text-gray-700">
          © Inter IKEA Systems B.V. 1999-2024
        </span>

        {hasOneTrustEnabled && (
          <div>
            <div
              className="text-gray-900 underline "
              onClick={() => window.Optanon?.ToggleInfoDisplay()}
            >
              {t("common.cookie-settings")}
            </div>
            {COOKIE_INFO_MARKETS.includes(market) && (
              <div>
                <div
                  className="text-gray-900 underline mt-1"
                  onClick={() => window.open(t("common.cookie-imprint-url"))}
                >
                  {t("common.cookie-imprint")}
                </div>
                <div
                  className="text-gray-900 underline mt-1"
                  onClick={() => window.open(t("common.cookie-policy-url"))}
                >
                  {t("common.cookie-policy")}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {shortId && (
        <div className="transform" onClick={props.onOptionsClick}>
          <Button
            ssrIcon={handover}
            type="plain"
            iconOnly
            xsmallIconOnly
            tabIndex={0}
          />
        </div>
      )}
    </div>
  )
}
