import { createOrder } from "@/api/createFullServeOrder"
import { useAuth } from "@/hooks/useAuth"
import { SessionState, useListig } from "@/hooks/useListig"
import { Locale, useLocale } from "@/hooks/useLocale"
import {
  dismissOrderCreatedNotification,
  setIsOrderCreationLoading,
  showOrderCreatedNotification,
  useCcOrderSnapshot,
} from "@/stores/ccOrder"
import { ApiError } from "@/types/apiError"
import {
  FullServeProduct,
  isFullServeProduct,
} from "@/types/product/categorizedProduct"
import {
  CashCarryOrderCreatedPartialResponse,
  CashCarryOrderResponse,
  CreateCashCarryOrderBody,
} from "@/types/responses/buy"
import { categorizeProducts } from "@/utils/categorizeProducts"
import { OrderConflictError, OrderForbiddenError } from "@/utils/errors"
import {
  formatCancelCcOrderItems,
  formatCcOrderItems,
  formatEditCcOrderItems,
} from "@/utils/formatCcOrderItems"
import {
  FullServeRestriction,
  getFullServeRestriction,
} from "@/utils/fullServeRestrictions"
import { getListVpcCode, getOrderAttachment } from "@/utils/listig"
import { useEffect } from "react"
import { useCcOrderPolling } from "./useCcOrderPolling"
import { sendOrderPickingStatusEvent } from "@/analytics/events/sendOrderPickingStatusEvent"
import localStorage from "localstorage-slim"
import useDemoMode from "./useDemoMode"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useQueryClient } from "@tanstack/react-query"
import { useCo360 } from "./useCo360"
import { calculatePriceTotals } from "@/utils/calculatePriceTotals"
import { useMultipleOrders } from "./useMultipleOrders"
import { InstoreOrder } from "@/types/order"
import { ListigOrder } from "@/types/listig"
import { useProducts } from "./useProducts"
import { cancelOrder } from "@/api/cancelFullServeOrder"
import { editOrder } from "@/api/editFullServeOrder"
import { ListArticle, ListSalesProduct } from "@/types/product/listProduct"

const orderPickingStatusTimeToLive = 60 * 60 * 8

export function useCcOrder(listigOrder?: ListigOrder) {
  const { isDemoMode } = useDemoMode()
  const { oAuthToken } = useAuth()
  const locale = useLocale()
  const { orderCreatedNotificationVisible, isOrderCreationLoading } =
    useCcOrderSnapshot()
  const listig = useListig()
  const session = listig.session
  const fullServeProducts = categorizeProducts(
    listig.list?.items ?? [],
  ).FULL_SERVE
  const queryClient = useQueryClient()

  const totalPrice = calculatePriceTotals(fullServeProducts)

  /** null if no restrictions, undefined if undecided */
  const orderRestriction: FullServeRestriction | "UNKNOWN_STORE" | null =
    session.businessUnitCode
      ? getFullServeRestriction(
          fullServeProducts,
          locale.market,
          session.businessUnitCode,
        ) ?? null
      : "UNKNOWN_STORE"

  const orderNo =
    listigOrder?.orderNo ??
    (listig.list && getOrderAttachment(listig.list)?.orderNo)
  const isOrderCreated = !!orderNo
  const isCo360Enabled = useCo360()
  const isMultipleOrdersEnabled = useMultipleOrders()

  const vpcCode = listig.list && getListVpcCode(listig.list)
  const drawings: CreateCashCarryOrderBody["drawings"] = vpcCode
    ? [{ drawingId: vpcCode }]
    : undefined

  const mutation = useMutationWithErrorHandling(
    mutationFunction(
      orderRestriction,
      isOrderCreated,
      oAuthToken,
      session,
      fullServeProducts,
      locale,
      isCo360Enabled,
      isMultipleOrdersEnabled,
      isDemoMode,
      drawings,
    ),
    {
      onSuccess: (data) => {
        showOrderCreatedNotification()
        // invalidate queries to refetch list and order
        queryClient.invalidateQueries({
          queryKey: ["listig", listig.list?.id?.toString()],
        })
        queryClient.invalidateQueries({
          queryKey: ["listigOrder", data.orderNo],
        })
        queryClient.invalidateQueries({
          queryKey: ["order status", data.orderNo],
        })
      },
      retryDelay: 2000,
      retry: (failureCount, error) =>
        // we are not allowed to retry on 500 range or if 409 (order already exist for list)
        !isHttpServerError(error) && !isHttpConflict(error) && failureCount < 3,
    },
    (error) => {
      if (isHttpConflict(error)) {
        return "info"
      } else if (error instanceof OrderForbiddenError) {
        return "warning"
      } else {
        return "error"
      }
    },
  )

  const cancelOrderMutation = useMutationWithErrorHandling(
    (currentOrder: InstoreOrder) => {
      if (
        !oAuthToken ||
        !currentOrder ||
        !session.source ||
        !session.businessUnitCode ||
        !session.listId
      ) {
        return Promise.reject(new Error("Missing fields"))
      }
      return cancelOrder({
        orderNo: currentOrder.orderNo,
        orderNoSource: currentOrder.orderNoSource,
        storeNo: isDemoMode ? "demo" : session.businessUnitCode ?? "",
        products: formatCancelCcOrderItems(currentOrder.items ?? []),
        language: locale.language,
        market: locale.market,
        ukid: session.source.type === "kiosk" ? session.source.ukid : undefined,
        kongToken: oAuthToken,
        listId: session.listId,
        isMultipleOrdersEnabled: true,
      })
    },
    {
      onSuccess: (_, currentOrder) => {
        // invalidate queries to refetch list and order
        queryClient.invalidateQueries({
          queryKey: ["listig", listig.list?.id?.toString()],
        })
        queryClient.invalidateQueries({
          queryKey: ["listigOrder", currentOrder.orderNo],
        })
      },
      retryDelay: 2000,
      retry: (failureCount) => failureCount < 3,
    },
  )

  const editOrderMutation = useMutationWithErrorHandling(
    ({
      order: currentOrder,
      items,
    }: {
      order: InstoreOrder
      items: (ListSalesProduct | ListArticle)[]
    }) => {
      if (
        !oAuthToken ||
        !currentOrder ||
        !session.source ||
        !session.businessUnitCode ||
        !session.listId
      ) {
        return Promise.reject(new Error("Missing fields"))
      }
      return editOrder({
        orderNo: currentOrder.orderNo,
        orderNoSource: currentOrder.orderNoSource,
        storeNo: isDemoMode ? "demo" : session.businessUnitCode ?? "",
        products: formatEditCcOrderItems(items ?? []),
        language: locale.language,
        market: locale.market,
        ukid: session.source.type === "kiosk" ? session.source.ukid : undefined,
        kongToken: oAuthToken,
        listId: session.listId,
        isCo360Enabled,
      })
    },
    {
      onSuccess: (_, { order: currentOrder }) => {
        // invalidate queries to refetch list and order
        queryClient.invalidateQueries({
          queryKey: ["listig", listig.list?.id?.toString()],
        })
        queryClient.invalidateQueries({
          queryKey: ["listigOrder", currentOrder.orderNo],
        })
        queryClient.invalidateQueries({
          queryKey: ["order status", currentOrder.orderNo],
        })
      },
      retryDelay: 2000,
      retry: (failureCount) => failureCount < 3,
    },
  )

  useEffect(() => {
    setIsOrderCreationLoading(mutation.isPending)
  }, [mutation.isPending])

  const { order, isLoading: isOrderLoading } = useCcOrderPolling(listigOrder)

  useEffect(() => {
    if (!order || !("pickingService" in order)) return
    const pickingStatusCacheKey = order.orderNo + "-pickingstatus"
    const pickingStatus = localStorage.get(pickingStatusCacheKey)
    const newPickingStatus = order?.pickingService?.status

    if (newPickingStatus && newPickingStatus !== pickingStatus) {
      sendOrderPickingStatusEvent(
        order.orderNo,
        order.orderNoSource,
        newPickingStatus,
      )
      localStorage.set(pickingStatusCacheKey, newPickingStatus, {
        ttl: orderPickingStatusTimeToLive,
        encrypt: false,
      })
    }
  }, [order])

  const refetchListig = listig.refetch
  useEffect(() => {
    if (isHttpConflict(mutation.error)) {
      refetchListig()
    }
  }, [mutation.error, refetchListig])

  const items = useProducts(
    session.businessUnitCode,
    order && "orderStatus" in order
      ? order.articles.map((art) => ({
          id: art.itemId,
          type: art.itemType as "ART" | "SPR",
          quantity: art.quantity,
        }))
      : [],
  ).products?.filter(isFullServeProduct)

  const instoreOrder: InstoreOrder | undefined = order
    ? "orderStatus" in order
      ? {
          orderNo: order.orderNo,
          orderNoSource: order.orderNoSource,
          pickingStatus: order.pickingService?.status,
          isPaid: order.isPaid,
          orderStatus: order.orderStatus,
          totalPrice: {
            exclTax: order.amounts.total.netAmount,
            inclTax: order.amounts.total.grossAmount,
            tax: order.amounts.total.vatAmount || 0,
          },
          currency: order.amounts.total.currencyCode,
          orderCreationDate: order.orderCreationDate,
          items,
          isPartial: false,
        }
      : {
          orderNo: order.orderNo,
          orderNoSource: order.orderNoSource,
          pickingStatus: undefined,
          isPaid: false,
          orderStatus: "CREATED",
          totalPrice: {
            exclTax: totalPrice.family?.exclTax || totalPrice.regular.exclTax,
            inclTax: totalPrice.family?.inclTax || totalPrice.regular.inclTax,
            tax: totalPrice.family?.tax || totalPrice.regular.tax || 0,
          },
          currency: totalPrice.currency,
          isPartial: true,
        }
    : undefined

  return {
    isOrderCreationLoading: mutation.isPending || isOrderCreationLoading,
    // if we get a partial response from create, then polling won't start
    // directly so we check for order manually
    isOrderLoading: isOrderLoading || (mutation.data && !order),
    /** this value will only be available from
     *  the hook instance that created the order */
    orderCreationError: isHttpConflict(mutation.error)
      ? undefined
      : mutation.error,
    orderNotification: {
      isShowing: orderCreatedNotificationVisible,
      dismiss: dismissOrderCreatedNotification,
    },
    createCcOrder: (
      options: {
        onSuccess?: (
          data: CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse,
        ) => unknown
      },
      notification?: { contactMethodType: string; contactMethodData: string },
    ) => mutation.mutate(notification, options),
    cancelCcOrder: (order: InstoreOrder) =>
      cancelOrderMutation.mutate(order, undefined),
    editCcOrder: ({
      order,
      items,
    }: {
      order: InstoreOrder
      items: (ListSalesProduct | ListArticle)[]
    }) => editOrderMutation.mutate({ order, items }, undefined),
    order: instoreOrder,
    /** null if no restrictions, undefined if undecided */
    orderRestriction,
  }
}

/** Fetch function used in mutation */
function mutationFunction(
  orderRestriction: string | null,
  isOrderCreated: boolean,
  oAuthToken: string | undefined,
  session: SessionState,
  fullServeProducts: FullServeProduct[],
  locale: Locale,
  isCo360Enabled: boolean,
  isMultipleOrdersEnabled: boolean,
  isDemoMode = false,
  drawings: CreateCashCarryOrderBody["drawings"],
) {
  return (notification?: {
    contactMethodType: string
    contactMethodData: string
  }) =>
    orderRestriction || isOrderCreated
      ? Promise.reject(
          new OrderForbiddenError(
            orderRestriction
              ? "orderRestriction: " + orderRestriction
              : "orderCreated",
          ),
        )
      : !oAuthToken ||
          !session.businessUnitCode ||
          !session.listId ||
          !session.source
        ? Promise.reject(new Error("Missing fields"))
        : createOrder({
            storeNo: isDemoMode ? "demo" : session.businessUnitCode,
            products: formatCcOrderItems(fullServeProducts),
            language: locale.language,
            market: locale.market,
            ukid:
              session.source.type === "kiosk" ? session.source.ukid : undefined,
            kongToken: oAuthToken,
            listId: session.listId,
            isCo360Enabled,
            isMultipleOrdersEnabled,
            drawings: drawings,
            notification,
          })
}

function isHttpConflict(error: Error | null) {
  return error && error instanceof OrderConflictError
}

function isHttpServerError(error: unknown) {
  return error && error instanceof ApiError && error.statusCode >= 500
}
