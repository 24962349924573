import ProductIdentifier from "@ingka/product-identifier"
import { useTranslation } from "react-i18next"
import { dottedIdentifier } from "@/utils/dottedIdentifier"
import Button from "@ingka/button"
import { useState } from "react"
import { InterPTagItem } from "@/types/responses/ptag"
import { ScoProductDescriptionAccordion } from "./ScoProductDescriptionAccordion"
import { UChildProduct } from "@/types/responses/find"

interface ScoProductDescriptionProps {
  product: InterPTagItem
  childItems: UChildProduct[]
}

export const ScoProductDescription: React.FC<ScoProductDescriptionProps> = (
  props,
) => {
  const { t } = useTranslation()
  const [showAllProductDetails, setShowAllProductDetails] = useState(false)

  const multipleBenefitsLines =
    props.product.customerBenefitLocal &&
    props.product.customerBenefitLocal.length > 1

  return (
    <>
      <p className="pt-0 pb-0 text-lg font-bold text-black">
        {props.product.validProductNameProductType}
      </p>

      <div className="text-sm pt-6">{t("common.article-number")}</div>
      <ProductIdentifier
        className="mt-2 mb-5"
        value={dottedIdentifier(props.product.itemNo)}
      />

      <div>
        {props.product.customerBenefitLocal?.map(
          (benefit, index) =>
            (showAllProductDetails || index < 1) && (
              <p
                key={`detailedInformation-${benefit.customerBenefitText}`}
                className="text-sm benefits"
              >
                {benefit.customerBenefitText}
              </p>
            ),
        )}
      </div>
      {multipleBenefitsLines && (
        <Button
          className="font-light capitalize underline"
          onClick={() => setShowAllProductDetails((value) => !value)}
          text={
            showAllProductDetails
              ? t("common.show-less")
              : t("common.show-more")
          }
          type="plain"
        />
      )}

      <div className="pt-6">
        <ScoProductDescriptionAccordion
          product={props.product}
          childItems={props.childItems}
        />
      </div>
    </>
  )
}
