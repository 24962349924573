import { getOrder } from "@/api/retrieveFullServeOrder"
import { useAuth } from "@/hooks/useAuth"
import { useLocale } from "@/hooks/useLocale"
import {
  CashCarryOrderCreatedPartialResponse,
  CashCarryOrderResponse,
} from "@/types/responses/buy"
import { getOrderAttachment } from "@/utils/listig"
import { useEffect, useMemo, useState } from "react"
import { useListig } from "./useListig"
import useDemoMode from "./useDemoMode"
import { OrderOutdatedError } from "@/utils/errors"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { useCo360 } from "./useCo360"
import { ListigOrder } from "@/types/listig"

const EIGHT_HOURS = 28800000

export function useCcOrderPolling(listigOrder?: ListigOrder): {
  order?: CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse
  isOrderOutdated: boolean
  isLoading: boolean
} {
  const { market, language } = useLocale()
  const { oAuthToken } = useAuth()
  const { list } = useListig()
  const isCo360Enabled = useCo360()

  const [isOrderOutdated, setIsOrderOutdated] = useState<boolean>(false)
  const [orderCreatedAt, setOrderCreatedAt] = useState<number | undefined>(
    undefined,
  )

  // We set the endPollingTime to below options, in the following presedence, and adds EIGHT_HOURS to it:
  //  - lastUpdateAt from the list
  //  - Date.now()
  const endPollingTime = useMemo(() => {
    const referenceTime =
      orderCreatedAt ??
      (list?.metadata.lastUpdateAt
        ? new Date(list.metadata.lastUpdateAt).getTime()
        : Date.now())
    return referenceTime + EIGHT_HOURS
  }, [list?.metadata.lastUpdateAt, orderCreatedAt])

  const [isCompleted, setIsCompleted] = useState(false)
  const { getDemoParams } = useDemoMode()

  const orderAttachment = (list && getOrderAttachment(list)) ?? undefined
  const orderNo = listigOrder?.orderNo ?? orderAttachment?.orderNo
  const orderNoSource =
    listigOrder?.orderNoSource ?? orderAttachment?.orderNoSource

  const hasAllQueryParameters =
    !!orderNo && !!orderNoSource && !!language && !!market && !!oAuthToken

  const shouldFetch = !!orderNo

  const { data, isPending } = useQueryWithErrorHandling<
    CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse
  >(
    ["order status", orderNo],
    () =>
      // test endPollingTime inside the fetch function since it should be
      // tested just before the next fetch and not after the last one
      endPollingTime < Date.now()
        ? Promise.reject(new OrderOutdatedError()).finally(() =>
            setIsOrderOutdated(true),
          )
        : hasAllQueryParameters
          ? getOrder({
              orderNo,
              orderNoSource,
              language,
              market,
              kongToken: oAuthToken,
              demoParams: getDemoParams(),
              isCo360Enabled,
            })
          : Promise.reject(new Error("Missing fields")),
    {
      enabled: shouldFetch && !isCompleted && hasAllQueryParameters,
      refetchInterval: 10000,
      staleTime: 10000,
      gcTime: 60000,
      initialData: orderAttachment,
      retry: 3,
    },
    "warning",
  )

  useEffect(() => {
    data &&
      "orderCreationDate" in data &&
      setOrderCreatedAt(new Date(data.orderCreationDate).getTime())
  }, [data])

  useEffect(() => {
    data && setIsCompleted(orderIsCompleted(data))
  }, [data])

  return {
    order: data,
    isOrderOutdated: isOrderOutdated,
    // isLoading should be false until we start fetching
    isLoading: isPending && shouldFetch,
  }
}

function orderIsCompleted(
  order: CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse,
) {
  if (!("orderStatus" in order)) return false

  return order.pickingService?.status
    ? order.pickingService.status === "COMPLETED"
    : order.orderStatus === "COMPLETED"
}
