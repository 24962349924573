import { UArticle, UProduct, USalesProduct } from "@/types/responses/find"

export function isFullServeArticle(product: UArticle) {
  return (
    "locations" in product &&
    product.locations?.keys() &&
    product.locations[0]?.salesMethod === "FULL_SERVE"
  )
}

export function isFullServeSalesProduct(product: USalesProduct) {
  return product.info.childItems?.some((item) =>
    isFullServeArticle(item as UArticle)
  )
}

export function isFullServeProduct(product: UProduct) {
  const isArt = product.info.type === "ART"
  const isFullServeArt = isArt && isFullServeArticle(product as UArticle)

  if (isArt) return isFullServeArt

  return (
    product.info.type === "SPR" &&
    isFullServeSalesProduct(product as USalesProduct)
  )
}
