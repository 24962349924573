import { FullServeProduct } from "@/types/product/categorizedProduct"
import { ListArticle, ListSalesProduct } from "@/types/product/listProduct"
import {
  AddItemsToOrderBodySchema,
  CancelCashCarryOrderBody,
  CreateCashCarryOrderBody,
} from "@/types/responses/buy"

export function formatCcOrderItems(
  products: FullServeProduct[],
): CreateCashCarryOrderBody["items"] {
  let formattedItems: CreateCashCarryOrderBody["items"] = []

  products.forEach((product) => {
    if (product.info.type === "SPR" && product.info.childItems) {
      formattedItems = formattedItems.concat(
        formatCcOrderItems(
          product.info.childItems.map((e) => ({
            ...e,
            quantity: e.quantity,
          })),
        ),
      )
    } else {
      formattedItems.push({
        itemId: product.info.no,
        type: product.info.type,
        quantity: product.quantity || 0,
        measurement: "PIECES",
      })
    }
  })
  return formattedItems
}

export function formatCancelCcOrderItems(
  products: FullServeProduct[],
): CancelCashCarryOrderBody["items"] {
  let formattedItems: CancelCashCarryOrderBody["items"] = []

  products.forEach((product) => {
    if (product.info.type === "SPR" && product.info.childItems) {
      formattedItems = formattedItems.concat(
        formatCancelCcOrderItems(
          product.info.childItems.map((e) => ({
            ...e,
            quantity: e.quantity,
          })),
        ),
      )
    } else {
      formattedItems.push({
        id: product.info.no,
        type: product.info.type,
        quantity: product.quantity ?? 0,
      })
    }
  })
  return formattedItems
}

export function formatEditCcOrderItems(
  products: (ListSalesProduct | ListArticle)[],
): AddItemsToOrderBodySchema["items"] {
  let formattedItems: AddItemsToOrderBodySchema["items"] = []

  products.forEach((product) => {
    if (product.info.type === "SPR" && product.info.childItems) {
      formattedItems = formattedItems.concat(
        formatEditCcOrderItems(
          product.info.childItems.map((e) => ({
            ...e,
            quantity: e.quantity,
          })),
        ),
      )
    } else {
      formattedItems.push({
        itemId: product.info.no,
        type: product.info.type,
        quantity: product.quantity || 0,
        measurement: "PIECES",
      })
    }
  })
  return formattedItems
}
