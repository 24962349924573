import {
  DEMO_ARTICLE,
  DEMO_END_DATE,
  DEMO_START_DATE,
  DEMO_STORE,
} from "@/config/constants"
import { useListig } from "./useListig"

const storageKey = "demo"

type DemoPickingStatus = "CREATED" | "STARTED" | "PICKING" | "PICKED"

export default function useDemoMode() {
  const { list } = useListig()
  const now = new Date().getTime()
  const start = DEMO_START_DATE.getTime()
  const end = DEMO_END_DATE.getTime()

  const isTimeRange = start < now && now < end

  const isDemoMode =
    list?.businessUnit?.code === DEMO_STORE &&
    list?.items.length === 1 &&
    list?.items[0]?.info?.no === DEMO_ARTICLE &&
    isTimeRange

  const getStatus = () => {
    return localStorage.getItem(storageKey) ?? "CREATED"
  }

  const setStatus = (status: DemoPickingStatus) => {
    localStorage.setItem(storageKey, status)
  }

  const getDemoParams = () => {
    if (!isDemoMode) return
    const demoParams = new URLSearchParams()
    demoParams.append("demoStatus", getStatus())
    return demoParams
  }

  const pretendToPay = () => {
    setStatus("STARTED")

    const interval = setInterval(function () {
      if (getStatus() === "PICKED") {
        clearInterval(interval)
      } else {
        setStatus(getStatus() === "PICKING" ? "PICKED" : "PICKING")
      }
    }, 20000)
  }

  return {
    isDemoMode,
    getDemoParams,
    pretendToPay,
  }
}
