import { useEffect, useState } from "react"
import { useListig } from "./useListig"
import { sendItemQuantityChangedEvent } from "@/analytics/events/sendItemQuantityChangedEvent"

/**
 * Keeps state related to modifying quantity.
 * Update listig when quantity changes.
 * @param itemNo of product
 * @param quantity according to listig
 * @param location location (list or pip)
 */
export const useSelectQuantity = (
  itemNo: string,
  quantity: number,
  location: string,
) => {
  const {
    updateProduct: { update },
  } = useListig()

  // new quantity selected in the picker
  const [selectedQuantity, setSelectedQuantity] = useState<{
    quantity: number
    triggerUpdate: boolean
  }>({ quantity, triggerUpdate: false })
  // update selection if props change
  useEffect(() => {
    setSelectedQuantity({ quantity, triggerUpdate: false })
  }, [quantity])

  useEffect(() => {
    if (
      quantity === selectedQuantity.quantity ||
      !selectedQuantity.triggerUpdate
    ) {
      return
    }
    update(
      { itemNo, quantity: selectedQuantity.quantity },
      {
        // reset selected quantity to props if update fails
        onError: () => setSelectedQuantity({ quantity, triggerUpdate: false }),
      },
    )
    sendItemQuantityChangedEvent(itemNo, selectedQuantity.quantity, location)
  }, [itemNo, quantity, update, selectedQuantity, location])

  return {
    /** quantity selected that has been selected */
    selectedQuantity: selectedQuantity.quantity,

    /** update selected quantity */
    setSelectedQuantity: (q: number) =>
      setSelectedQuantity({ quantity: q, triggerUpdate: true }),
  }
}
