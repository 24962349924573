import {
  CashCarryOrderCreatedPartialResponse,
  CashCarryOrderResponse,
  CreateCashCarryOrderBody,
} from "@/types/responses/buy"
import { OrderConflictError, UError } from "@/utils/errors"
import { isIsomFullServe } from "@/utils/fullServeRestrictions"
import { getLocale } from "@/utils/locale"
import { captureWarning } from "@/utils/sentry"
import { getOrder } from "./retrieveFullServeOrder"
import { fetchWithErrorHandling } from "@/utils/fetch"

interface CreateOrderParams {
  storeNo: string
  products: CreateCashCarryOrderBody["items"]
  market: string
  language: string
  kongToken: string
  ukid: string | undefined
  listId: string
  isCo360Enabled: boolean
  isMultipleOrdersEnabled: boolean
  drawings?: CreateCashCarryOrderBody["drawings"]
  notification?: { contactMethodType: string; contactMethodData: string }
}

export async function createOrder({
  storeNo,
  products,
  market,
  language,
  kongToken,
  ukid,
  listId,
  isCo360Enabled,
  isMultipleOrdersEnabled,
  drawings,
  notification,
}: CreateOrderParams): Promise<
  CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse
> {
  const locale = getLocale(market, language)
  const isomFullserve = isIsomFullServe(market, storeNo, ukid ?? "")

  const reqBody: CreateCashCarryOrderBody = {
    buCode: storeNo,
    items: products,
    locale,
    notifications: notification ? [notification] : undefined,
    workStationName: ukid?.toLowerCase() || "",
    clientSystem: {
      name: "upptacka",
      version: "1.0.0",
    },
    listId: listId,
    drawings: drawings,
  }

  const headers: Record<string, string> = {
    Authorization: "Bearer " + kongToken,
    "Content-Type": "application/json",
  }

  const queryParam = new URLSearchParams()

  if (isomFullserve) {
    queryParam.append("isom", "true")
  }

  if (isCo360Enabled) {
    queryParam.append("co360", "true")
  }

  if (isMultipleOrdersEnabled) {
    queryParam.append("multipleOrders", "true")
  }

  const createdOrderResponse = await fetchWithErrorHandling<
    CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse
  >(
    "BUY_ORDER_CREATION",
    `${
      process.env.BUY_API_URL
    }/cc-order/${market}/${language}?${queryParam.toString()}`,
    {
      method: "POST",
      headers: headers,
      body: JSON.stringify(reqBody),
    },
    (response) => {
      if (response.status === 409) {
        throw new OrderConflictError()
      }
    },
  )

  return "orderStatus" in createdOrderResponse
    ? createdOrderResponse
    : getOrder({
        ...createdOrderResponse,
        market,
        language,
        kongToken,
        isCo360Enabled,
      }).catch(() => {
        captureWarning(new UError("FULL_SERVE_ORDER_RETRIEVAL_FAILED"))
        return createdOrderResponse
      })
}
