import {
  useMutationWithErrorHandling,
  useQueryWithErrorHandling,
} from "@/hooks/useReactQuery"
import { fetchWithErrorHandling } from "@/utils/fetch"
import { useCallback } from "react"
import { useUpptackaToken } from "./useAuth"
import { ApiError } from "@/types/apiError"

export const useKortisInfoQuery = (kortisId: string | undefined) =>
  useQueryWithErrorHandling<{ redirectUrl: string }>(
    ["kortis info", kortisId],
    () =>
      kortisId
        ? fetchWithErrorHandling(
            "KORTIS",
            `${process.env.KORTIS_API_URL}/info/${kortisId}`,
          )
        : Promise.reject(new Error("Missing id")),
    { enabled: !!kortisId },
  ).data

interface CreateShortIdRequest {
  url: string
}

interface CreateShortIdResponse {
  shortId: string
  shortUrl: string
  redirectUrl: string
  created: number
  visits: number
  lastVisit: null
}

export const useKortisId = () => {
  const upptackaToken = useUpptackaToken()
  const mutation = useMutationWithErrorHandling<
    CreateShortIdResponse,
    ApiError,
    CreateShortIdRequest
  >((input: CreateShortIdRequest) =>
    fetchWithErrorHandling<CreateShortIdResponse>(
      "KORTIS",
      process.env.KORTIS_API_URL + `/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + upptackaToken,
        },
        body: JSON.stringify({
          url: encodeURI(input.url),
          inclShortId: true,
        }),
      },
    ),
  )

  const mutate = mutation.mutateAsync
  const createKortisId = useCallback(
    (url: string) => mutate({ url }).catch(() => undefined),
    [mutate],
  )

  return { createKortisId }
}
