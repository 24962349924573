import type {
  DepartmentSalesLocation,
  FoodItemSalesLocation,
  FullServeSalesLocation,
  ProductError,
  ProductInfo,
  SalesLocation,
  SelfServeSalesLocation,
  SprProductInfo,
  UArticle,
  UChildProduct,
  UProduct,
  USalesProduct,
} from "@/types/responses/find"
import { PipProduct, UGroupedProduct } from "./pip"
import { UniqueKey } from "../uniqueKey"

export const isUArticle = (
  product: UProduct | UGroupedProduct,
): product is UArticle => product.info.type === "ART"

export const isUSalesProduct = (
  product: PipProduct | UProduct | UChildProduct,
): product is USalesProduct => isSprProductInformation(product.info)

const isSprProductInformation = (
  productInfo: ProductInfo,
): productInfo is SprProductInfo => productInfo.type === "SPR"

/**
 * Type guard to remove ProductError from any object type.
 * @param product
 * @returns product
 */
export const isNotProductError = <
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object,
  S extends T | ProductError | undefined,
>(
  product: S,
): product is Exclude<S, ProductError> => {
  const property: keyof ProductError = "error"

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Waiting for fix in https://github.com/microsoft/TypeScript/issues/51501
  return !(product && property in product && product.error)
}

/** Type with quantity property */
export type Quantified<T> = T & { quantity: number }

export const isUProduct = (
  product: PipProduct | UChildProduct,
): product is UProduct => {
  const property: keyof UProduct = "info"

  if (product.info.type === "ART" || product.info.type === "SPR")
    return property in product

  return false
}

export const getAisleAndBin = (locations: SalesLocation[] | undefined) => {
  if (!locations) return

  const location = locations.find(isSelfServeSalesLocation)

  return location && { aisle: location.aisle, bin: location.bin }
}

export const isFullServeSalesLocation = (
  location: SalesLocation | undefined,
): location is FullServeSalesLocation => location?.salesMethod === "FULL_SERVE"

export const isSelfServeSalesLocation = (
  location: SalesLocation | undefined,
): location is SelfServeSalesLocation => location?.salesMethod === "SELF_SERVE"

export const isFoodItemSalesLocation = (
  location: SalesLocation | undefined,
): location is FoodItemSalesLocation =>
  location?.salesMethod === "SWEDISH_FOOD_MARKET"

export const isDepartmentSalesLocation = (
  location: SalesLocation,
): location is DepartmentSalesLocation => {
  const property: UniqueKey<DepartmentSalesLocation, SalesLocation> =
    "departmentName"

  return property in location
}
