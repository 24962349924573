import { useQueryClient } from "@tanstack/react-query"
import { useListig } from "./useListig"
import {
  CashCarryOrderCreatedPartialResponse,
  CashCarryOrderResponse,
} from "@/types/responses/buy"
import { InstoreOrder } from "@/types/order"

export const useInstoreOrders = () => {
  const { list } = useListig()
  const queryClient = useQueryClient()

  const allOrders: InstoreOrder[] = []
  list?.orders?.forEach((listOrder) => {
    const order = queryClient.getQueryData([
      "order status",
      listOrder.orderNo,
    ]) as CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse
    if (!order) {
      return
    }

    const instoreOrder: InstoreOrder =
      "orderStatus" in order
        ? {
            orderNo: order.orderNo,
            orderNoSource: order.orderNoSource,
            pickingStatus: order.pickingService?.status,
            isPaid: order.isPaid,
            orderStatus: order.orderStatus,
            totalPrice: {
              exclTax: order.amounts.total.netAmount,
              inclTax: order.amounts.total.grossAmount,
              tax: order.amounts.total.vatAmount || 0,
            },
            currency: order.amounts.total.currencyCode,
            orderCreationDate: order.orderCreationDate,
            isPartial: false,
          }
        : {
            orderNo: order.orderNo,
            orderNoSource: order.orderNoSource,
            pickingStatus: undefined,
            isPaid: false,
            orderStatus: "CREATED",
            isPartial: true,
          }
    allOrders.push(instoreOrder)
  })

  return {
    orders: allOrders,
    activeUnpaidOrder: allOrders.find((order) => !order.isPaid),
  }
}
