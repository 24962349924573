import {
  DISABLED_SCANNER_MARKETS,
  ENABLED_SCANNER_LOCATIONS,
  SCANNER_ENABLED,
} from "@/config/constants"
import { useCcOrder } from "@/hooks/useCcOrder"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { isEnabled } from "@/utils/isEnabled"

/**
 * @returns true if products can be added to the list
 */
export function useCanAddProducts() {
  const { order, isOrderCreationLoading } = useCcOrder()
  const { market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode, source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined

  const scannerEnabled = isEnabled({
    market,
    businessUnitCode,
    ukid,
    ENABLED: SCANNER_ENABLED,
    ENABLED_LOCATIONS: ENABLED_SCANNER_LOCATIONS,
  })

  return (
    !order &&
    !isOrderCreationLoading &&
    !DISABLED_SCANNER_MARKETS.includes(market) &&
    scannerEnabled
  )
}
