import { useCcOrder } from "@/hooks/useCcOrder"

/**
 * @returns true if the list can be modified
 */
export function useCanModifyList() {
  const { order, isOrderCreationLoading, isOrderLoading } = useCcOrder()
  const orderAlreadyCreated = order !== undefined

  return !isOrderLoading && !isOrderCreationLoading && !orderAlreadyCreated
}
