import { UpptackaErrorId } from "@/types/errors"
import { getErrorSeverity } from "@/utils/errors"
import { initialize } from "@/utils/sentry"
import { ErrorView } from "@/views/ErrorView"
import * as Sentry from "@sentry/react"
import React, { ReactElement, useEffect } from "react"

export const SentryWrapper: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  useEffect(() => {
    initialize()
  }, [])

  return (
    <Sentry.Profiler>
      <Sentry.ErrorBoundary
        fallback={({ error }) => <ErrorView error={error} />}
        beforeCapture={(scope, error) => {
          scope.setLevel(getErrorSeverity(error?.name as UpptackaErrorId))
        }}
      >
        {children}
      </Sentry.ErrorBoundary>
    </Sentry.Profiler>
  )
}
