import { sendOpenListEvent } from "@/analytics/events/sendOpenListEvent"
import { sendItemDeletedUndoEvent } from "@/analytics/events/sendItemDeletedUndoEvent"
import { sendOpenPipEvent } from "@/analytics/events/sendOpenPipEvent"
import { sendPipAddItemEvent } from "@/analytics/events/sendPipAddItemEvent"
import { ButtonTooltip } from "@/components/ButtonTooltip"
import { ProductPrice } from "@/components/common/ProductPrice"
import { ProductAvailableForDelivery } from "@/components/list/product-card/ProductAvailableForDelivery"
import StockInformation from "@/components/list/product-card/StockInformation"
import { ProductDetailsModal } from "@/components/modals/ProductDetailsModal"
import { ProductVariantsModal } from "@/components/modals/ProductVariantsModal"
import {
  DO_NOT_DIY_MARKETS,
  MAX_QUANTITY,
  MAX_QUANTITY_FULLSERVE,
  VisualVariationTypes,
} from "@/config/constants"
import { capitalize } from "@/config/locales"
import { useNotification } from "@/contexts/NotificationContext"
import { useIsOnline } from "@/hooks/useIsOnline"
import { useListig } from "@/hooks/useListig"
import { useLocalNavigation } from "@/hooks/useLocalNavigation"
import { useProduct } from "@/hooks/useProducts"
import { useSelectQuantity } from "@/hooks/useSelectQuantity"
import useVisit from "@/hooks/useVisit"
import { ListigList } from "@/types/listig"
import { UProduct } from "@/types/responses/find"
import { isFullServeProduct } from "@/utils/isFullServe"
import Button from "@ingka/button"
import ListView, { ListViewItem } from "@ingka/list-view"
import QuantityStepper from "@ingka/quantity-stepper"
import SSRIcon from "@ingka/ssr-icon"
import documentListIcon from "@ingka/ssr-icon/paths/document-pencil"
import shoppingBagAdd from "@ingka/ssr-icon/paths/shopping-bag-add"
import trashCan from "@ingka/ssr-icon/paths/trash-can"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router"
import { useVariants } from "@/hooks/useVariants"
import { ProductLocation } from "@/components/pip/ProductLocation"
import { useCanModifyList } from "@/hooks/useCanModifyList"
import { VisualVariants } from "@/components/pip/VisualVariants"
import { FeeText } from "@/components/pip/FeeText"
import { useLocale } from "@/hooks/useLocale"
import { getLocale } from "@/utils/locale"
import { SlideUpModal } from "@/components/modals/SlideUpModal"
import { ProductComplianceSheet } from "@/components/pip/ProductComplianceSheet"
import { ProductEnergyFee } from "@/components/pip/ProductEnergyFee"
import { useStoreInformation } from "@/hooks/useStoreInformation"
import AppHeader from "@/components/list/AppHeader"
import { FullServeOnboardingModal } from "@/components/modals/FullServeOnboardingModal"
import { sendPipButtonTapEvent } from "@/analytics/events/sendPipButtonTapEvent"
import { sendPipExploreImagesEvent } from "@/analytics/events/sendPipExploreImagesEvent"
import { sendPipScrollLocationEvent } from "@/analytics/events/sendPipScrollLocationEvent"
import { WaterLabel } from "@/components/pip/WaterLabel"
import informationCircle from "@ingka/ssr-icon/paths/information-circle"
import { RepairabilityLabel } from "@/components/pip/RepairabilityLabel"
import { useLocalSco } from "@/hooks/useLocalSco"
import { LocalSco } from "@/components/common/LocalSco"

export const Product: React.FC = () => {
  const { productNo } = useParams<{ productNo: string }>()
  const { Id: referrerId } = useParams()
  const { productInfo } = useProduct(productNo)
  const { localScoInfo } = useLocalSco(productNo)
  const { list } = useListig()
  const { navigate } = useLocalNavigation()
  const { t } = useTranslation()
  const { isOnline } = useIsOnline()
  const { visited } = useVisit()
  const { market, language } = useLocale()
  const { storeInfo } = useStoreInformation()

  const [imageSwipeEventSent, setImageSwipeEventSent] = useState(false)
  const [isDiyAccordionOpen, setIsDiyAccordionOpen] = useState(false)
  const [scrollLocationEventSent, setScrollLocationEventSent] = useState(false)
  const [showFullserveOnboarding, setShowFullserveOnboarding] = useState(false)
  const [showProductDetails, setShowProductDetails] = useState(false)
  const [showProductVariants, setShowProductVariants] = useState(false)
  const [showVisualVariants, setShowVisualVariants] = useState(false)
  const [variantCategory, setVariantCategory] = useState<string>()
  const [showProductEnergyModal, setShowProductEnergyModal] = useState(false)
  const [showWaterLabelModal, setShowWaterLabelModal] = useState(false)
  const [showProductEnergyFeeModal, setShowProductEnergyFeeModal] =
    useState(false)
  const [showRepairabilityModal, setShowRepairabilityModal] = useState(false)

  const fee = productInfo?.price.fees?.[0]
  const technicalCompliance =
    productInfo?.info.detailedInformation.technical?.compliance?.[0]

  const onClose = () =>
    navigate({
      path: visited.welcome || list?.items.length ? "list" : "welcome",
    })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (
      !referrerId ||
      referrerId === "scanner" ||
      referrerId === "list" ||
      !productInfo
    )
      return
    sendOpenPipEvent(productInfo.info.no, referrerId)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: variantCategories } = useVariants(
    productInfo?.info.no,
    productInfo?.gpr?.no,
  )

  const visualVariants = variantCategories?.find((variant) =>
    VisualVariationTypes.includes(variant.typeNameGlobal),
  )

  const nonVisualVariants = variantCategories?.filter(
    (variant) => !VisualVariationTypes.includes(variant.typeNameGlobal),
  )

  function handleVariantOnClick(productId: string) {
    sendPipButtonTapEvent(productInfo?.info.no, "variants")
    setShowProductVariants(false)
    setShowVisualVariants(false)
    navigate({ path: "product", productNo: productId })
  }

  function handleImageSwipe() {
    if (imageSwipeEventSent) return
    sendPipExploreImagesEvent(productInfo?.info.no)
    setImageSwipeEventSent(true)
  }

  function handleScroll() {
    if (scrollLocationEventSent) return
    sendPipScrollLocationEvent(productInfo?.info.no)
    setScrollLocationEventSent(true)
    window.removeEventListener("scroll", handleScroll)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const waterSenseLabel = productInfo?.media.images.find(
    (i) => i.type === "WATER_SENSE_LABEL_IMAGE",
  )

  const baseUnifiedWaterLabel = productInfo?.media.images.find(
    (i) => i.type === "UNIFIED_WATER_LABEL_SYMBOL_IMAGE",
  )

  const pipImages = productInfo?.media.images.filter(
    (e) => !e.type?.includes("ENERGY") && !e.type?.includes("WATER"),
  )

  const repairabilitySymbol = productInfo?.media.images.find(
    (i) => i.type === "REPAIRABILITY_INDEX_SYMBOL",
  )

  const repairabilityDocument = productInfo?.media.images.find(
    (i) => i.type === "REPAIRABILITY_INDEX_DOCUMENT",
  )

  return (
    <div className="flex flex-col w-full">
      <AppHeader
        title={storeInfo?.displayName ?? "IKEA"}
        onListClick={() => {
          sendOpenListEvent()
          onClose()
        }}
      />
      {productInfo ? (
        <>
          <div
            className="overflow-x-scroll flex flex-row object-contain"
            onScrollCapture={handleImageSwipe}
            data-cy="scrollable-pip-images"
          >
            {pipImages?.map((image) => {
              if (/_IMAGE$/.exec(image.type ?? ""))
                return (
                  <img
                    key={image.id}
                    src={image.href}
                    className="object-contain h-[280px] w-[280px]"
                  />
                )
              else if (/_VIDEO$/.exec(image.type ?? "")) {
                return (
                  <video
                    key={image.id}
                    src={image.href}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="object-contain h-[280px] w-[280px]"
                  />
                )
              } else return null
            })}
          </div>
          {visualVariants && (
            <VisualVariants
              variantCategory={visualVariants}
              currentProduct={productInfo}
              handleVariantOnClick={handleVariantOnClick}
              onMoreClick={() => setShowVisualVariants(true)}
            />
          )}
          <div className="w-full p-8 flex flex-col justify-between">
            <div>
              <div className="flex flex-col justify-between">
                <div className="pb-2">
                  <ProductPrice
                    productName={productInfo.info.name}
                    productDescription={productInfo.info.longDescription}
                    prices={productInfo.price}
                    offers={productInfo.offers}
                    productEnergy={
                      productInfo.media.energyLabelImage && {
                        energyLabel: productInfo.media.energyLabelImage,
                        onEnergyLabelClick: () =>
                          setShowProductEnergyModal(true),
                      }
                    }
                    waterSense={
                      waterSenseLabel && {
                        waterSense: waterSenseLabel,
                        onWaterSenseLabelClick: () =>
                          setShowWaterLabelModal(true),
                      }
                    }
                    unifiedWater={
                      baseUnifiedWaterLabel && {
                        unifiedWater: baseUnifiedWaterLabel,
                        onWaterSenseLabelClick: () =>
                          setShowWaterLabelModal(true),
                      }
                    }
                  />
                  {fee && (
                    <FeeText
                      fee={fee}
                      locale={getLocale(market, language)}
                      onClick={() => setShowProductEnergyFeeModal(true)}
                    />
                  )}
                  {repairabilitySymbol && repairabilityDocument && (
                    <img
                      className="pt-2 h-16"
                      data-cy="repairability-symbol"
                      alt={repairabilitySymbol.alt}
                      src={repairabilitySymbol.href}
                      onClick={() => setShowRepairabilityModal(true)}
                    />
                  )}
                </div>
                {DO_NOT_DIY_MARKETS.includes(market.toUpperCase()) &&
                  technicalCompliance && (
                    <div className="flex gap-2 mb-4 items-center">
                      <SSRIcon
                        data-cy="technical-compliance-pip"
                        paths={informationCircle}
                        onClick={() => {
                          setShowProductDetails(true)
                          setIsDiyAccordionOpen(true)
                        }}
                      />
                      <p className="text-sm">{technicalCompliance.value}</p>
                    </div>
                  )}
                {isOnline ? (
                  <div className="pt-1">
                    <ProductAvailableForDelivery
                      isAvailableForDelivery={
                        productInfo.stock.homeDelivery.inRange
                      }
                    />
                  </div>
                ) : (
                  <StockInformation
                    product={productInfo}
                    quantity={productInfo.stock.cashCarry.quantity ?? 0}
                    probability={
                      productInfo.stock.cashCarry.probability?.thisDay ??
                      "OUT_OF_STOCK"
                    }
                  />
                )}
              </div>

              <hr className="mb-0 mt-8 border-gray-200" />

              <ListView id="product-data" className="mb-6">
                <>
                  {nonVisualVariants?.map((category, index) => (
                    <ListViewItem
                      inset
                      key={index.toString() + category}
                      id={category.typeName}
                      title={capitalize(category.typeName)}
                      description={
                        category.variants?.find(
                          (variant) =>
                            variant.productNo === productInfo.info.no,
                        )?.value ?? ""
                      }
                      onClick={() => {
                        sendPipButtonTapEvent(productInfo?.info.no, "variants")
                        setVariantCategory(category.typeName)
                        setShowProductVariants(true)
                      }}
                      control="navigational"
                      controlIcon="chevronRight"
                    />
                  ))}
                  <ListViewItem
                    inset
                    id="product-details"
                    data-cy="product-details"
                    title={t(
                      "ProductDescriptionAccordion-title-product-details",
                    )}
                    onClick={() => {
                      sendPipButtonTapEvent(
                        productInfo?.info.no,
                        "product_details",
                      )
                      setShowProductDetails(true)
                    }}
                    control="navigational"
                    controlIcon="chevronRight"
                  />
                </>
              </ListView>
              <ProductLocation
                product={productInfo}
                onHelpClick={() => setShowFullserveOnboarding(true)}
              />
            </div>
            <div className="h-4" />
            <ProductFooter
              productNo={productNo}
              productInfo={productInfo}
              onClose={onClose}
            />
          </div>

          {productNo && (
            <ProductDetailsModal
              productNo={productNo}
              showing={showProductDetails}
              onClose={() => {
                setShowProductDetails(false)
                setIsDiyAccordionOpen(false)
              }}
              isDiyAccordionOpen={isDiyAccordionOpen}
            />
          )}
          {productNo && variantCategory && (
            <ProductVariantsModal
              productNo={productNo}
              variantCategory={nonVisualVariants?.find(
                (v) => v.typeName === variantCategory,
              )}
              showing={showProductVariants}
              onClose={() => setShowProductVariants(false)}
              handleVariantOnClick={handleVariantOnClick}
            />
          )}
          {productNo && visualVariants && (
            <ProductVariantsModal
              productNo={productNo}
              variantCategory={visualVariants}
              showing={showVisualVariants}
              onClose={() => setShowVisualVariants(false)}
              handleVariantOnClick={handleVariantOnClick}
            />
          )}

          <SlideUpModal
            showing={showWaterLabelModal}
            onClose={() => setShowWaterLabelModal(false)}
          >
            <WaterLabel product={productInfo} />
            <ProductComplianceSheet product={productInfo} />
          </SlideUpModal>

          <SlideUpModal
            showing={showProductEnergyModal}
            onClose={() => setShowProductEnergyModal(false)}
          >
            <ProductComplianceSheet product={productInfo} />
          </SlideUpModal>

          {fee && (
            <SlideUpModal
              showing={showProductEnergyFeeModal}
              onClose={() => setShowProductEnergyFeeModal(false)}
            >
              <ProductEnergyFee
                fee={fee}
                price={
                  (productInfo.price.family ?? productInfo.price.regular)
                    .inclTax
                }
                currency={productInfo.price.currency}
              />
            </SlideUpModal>
          )}
          {repairabilitySymbol && repairabilityDocument && (
            <SlideUpModal
              showing={showRepairabilityModal}
              onClose={() => setShowRepairabilityModal(false)}
            >
              <RepairabilityLabel
                repairabilityDocument={repairabilityDocument}
              />
            </SlideUpModal>
          )}
          <FullServeOnboardingModal
            products={[productInfo]}
            onClose={() => {
              setShowFullserveOnboarding(false)
            }}
            showing={showFullserveOnboarding}
          />
        </>
      ) : localScoInfo ? (
        <LocalSco groupedProduct={localScoInfo} />
      ) : null}
    </div>
  )
}

export const ProductFooter: React.FC<{
  productNo?: string
  productInfo: UProduct
  onClose: () => void
}> = ({ productNo, productInfo, onClose }) => {
  const { list, addProducts, deleteProduct } = useListig()
  const { visited, setVisitedPage } = useVisit()
  const { t } = useTranslation()
  const { isOnline } = useIsOnline()
  const [isAdding, setIsAdding] = useState(false)
  const location = useLocation()
  const canModifyList = useCanModifyList()
  const initialQuantityString = new URLSearchParams(location.search).get(
    "quantity",
  )
  const initialQuantity =
    (initialQuantityString && parseInt(initialQuantityString)) || 1
  const notificationContext = useNotification()
  const itemInList = list?.items.find((item) => item.info.no === productNo)
  const { selectedQuantity, setSelectedQuantity } = useSelectQuantity(
    productNo ?? "",
    itemInList ? itemInList.quantity : initialQuantity,
    "pip",
  )

  // report and restore if add product failed
  useEffect(() => {
    if (addProducts.error) {
      setIsAdding(false)
    }
  }, [addProducts.error])

  const handleRemove = () => {
    if (productNo) {
      deleteProduct.delete(
        { productId: productNo },
        {
          onSuccess: () => {
            const notificationMessage = t("ShoppingList-product-removed", {
              product: productInfo?.info.name.toUpperCase(),
            })

            notificationContext?.show(
              notificationMessage,
              () => handleUndoRemove,
            )
          },
        },
      )
    }
  }

  const handleUndoRemove = () => {
    if (productNo) {
      const undoProduct: ListigList["items"] = [
        {
          id: productNo,
          type: productInfo?.info.type === "SPR" ? "SPR" : "ART",
          quantity: selectedQuantity,
        },
      ]
      addProducts.add(undoProduct)
      notificationContext?.hide()
      sendItemDeletedUndoEvent(productNo)
    }
  }

  return (
    canModifyList && (
      <div className="sticky bottom-0 w-full bg-white pb-6 pt-2">
        {itemInList ? (
          <div className="flex flex-row w-full">
            <Button
              className="mr-3"
              ssrIcon={trashCan}
              iconOnly
              onClick={handleRemove}
            />
            <div className="w-full">
              <QuantityStepper
                readOnly={true}
                fluid
                value={selectedQuantity}
                onIncrease={() => setSelectedQuantity(selectedQuantity + 1)}
                onDecrease={() => setSelectedQuantity(selectedQuantity - 1)}
                minVal={1}
                maxVal={
                  isFullServeProduct(productInfo)
                    ? MAX_QUANTITY_FULLSERVE
                    : MAX_QUANTITY
                }
                ariaDescribedBy={t("aria.quantity-stepper")}
                ariaDescribedById={"product-view-quantity-stepper"}
              />
            </div>
          </div>
        ) : (
          <ButtonTooltip
            showToolTip={visited.entryPoint === "product" && !visited.product}
            closeToolTip={() => setVisitedPage("product")}
            title={t("mobile.onboarding-product-title")}
            body={t("mobile.onboarding-product-body")}
            confirmText={t("common.got-it")}
          >
            <Button
              onClick={() => {
                addProducts.add(
                  [
                    {
                      id: productInfo.info.no,
                      quantity: selectedQuantity,
                      type: productInfo.info.type,
                    },
                  ],
                  {
                    onSuccess: () => {
                      sendPipAddItemEvent(
                        productInfo.info.no,
                        selectedQuantity,
                        productInfo.price,
                      )
                      setIsAdding(false)
                      setVisitedPage("product")
                      onClose()
                      notificationContext?.show(
                        t("ShoppingList-product-added", {
                          product: productInfo.info.name.toUpperCase(),
                        }),
                      )
                    },
                  },
                )
                setIsAdding(true)
              }}
              type="emphasised"
              fluid
              loading={isAdding}
            >
              {isOnline ? (
                <SSRIcon paths={shoppingBagAdd} />
              ) : (
                <SSRIcon paths={documentListIcon} />
              )}
              <span className="w-2" />
              <span>{t("common.add-to-shopping-list")}</span>
            </Button>
          </ButtonTooltip>
        )}
      </div>
    )
  )
}
