import Button from "@ingka/button"
import { ModalFooter } from "@ingka/modal"
import { useTranslation } from "react-i18next"
import OrderStatus from "./OrderStatus"
import { SlideUpModal } from "../modals/SlideUpModal"
import InputField from "@ingka/input-field"
import FormField from "@ingka/form-field"
import { Select, Option } from "@ingka/select"
import { useEffect, useState } from "react"
import { validateEmail, validatePhoneNumber } from "@/utils/validateContactData"

/**
 * Prompt to confirm full serve order and lock list
 */
export const OrderConfirmationPrompt: React.FC<{
  show: boolean
  onContinue: (notification?: {
    contactMethodType: string
    contactMethodData: string
  }) => void
  onCancel: () => void
  isCaptureEmailSMS: boolean
}> = ({ show, onContinue, onCancel, isCaptureEmailSMS }) => {
  const { t } = useTranslation()

  const [selectedOption, setSelectedOption] = useState("SMS")
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value === "SMS" ? "SMS" : "Email")
    setContactDataValue("")
    setShouldValidate(false)
  }

  const [contactDataValue, setContactDataValue] = useState("")
  const [isValid, setIsValid] = useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const smsText = t("common.sms-warning")
  const emailText = t("common.email-warning")

  useEffect(() => {
    const validCheck =
      selectedOption === "SMS"
        ? validatePhoneNumber(contactDataValue)
        : validateEmail(contactDataValue)
    setIsValid(validCheck)
  }, [contactDataValue, selectedOption, isValid])

  return (
    <SlideUpModal onClose={onCancel} showing={show}>
      <h2 className="text-2xl mb-12" data-cy="modal-header">
        {t("mobile.ready-to-order")}
      </h2>
      <div className="mt-6">
        <OrderStatus />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (isValid) {
            onContinue({
              contactMethodType: selectedOption,
              contactMethodData: contactDataValue,
            })
          } else {
            setShouldValidate(true)
          }
        }}
      >
        {isCaptureEmailSMS && (
          <>
            <p className="text-sm pb-4">
              {t("mobile.add-contact-information")}
            </p>
            <p className="text-sm mb-2">{t("mobile.preferred-contact")}</p>
            <div className="flex flex-row gap-1 mb-10">
              <Select
                hintText=""
                id="contact-selector"
                onChange={handleChange}
                value={selectedOption === "SMS" ? "SMS" : "Email"}
                className="flex-col w-1/3"
                data-cy="contact-selector"
              >
                <Option name="SMS" value="SMS" />
                <Option name="Email" value="Email" />
              </Select>
              <FormField
                className="w-2/3"
                valid={isValid}
                shouldValidate={shouldValidate}
                validation={{
                  msg: selectedOption === "SMS" ? smsText : emailText,
                  id: selectedOption === "SMS" ? "SMS" : "Email",
                  type: "error",
                }}
                data-cy="form-field"
              >
                {selectedOption === "SMS" ? (
                  <InputField
                    id="SMS"
                    type="number"
                    value={contactDataValue}
                    onChange={(e) => setContactDataValue(e.target.value)}
                    inputMode="numeric"
                    data-cy="input-field-sms"
                  />
                ) : (
                  <InputField
                    id="Email"
                    type="text"
                    value={contactDataValue}
                    onChange={(e) => setContactDataValue(e.target.value)}
                    inputMode="email"
                    data-cy="input-field-email"
                  />
                )}
              </FormField>
            </div>
          </>
        )}
        <ModalFooter className="px-0">
          <Button text={t("mobile.not-yet")} onClick={onCancel} />
          <Button
            data-cy="confirm-order-button"
            type="emphasised"
            text={t("mobile.place-order")}
            htmlType={isCaptureEmailSMS ? "submit" : "button"}
            onClick={() => !isCaptureEmailSMS && onContinue()}
          />
        </ModalFooter>
      </form>
    </SlideUpModal>
  )
}
